import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { browserHistory } from 'react-router';

import { api as API } from 'mixins/api';
import AppActions from 'actions/app';

import { SecondaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import {
  Paragraph,
  CollectionItemHeader,
} from '@dosomegood/platform/dist/components/typography/Content';
import Link from '@dosomegood/platform/dist/components/buttons/Link';

const Container = styled.div`
  padding: ${(props) => props.theme.margins.xs};
`;

const Footer = styled.footer`
  text-align: right;
  padding-top: ${(props) => props.theme.margins.xs};
  & > * {
    margin-left: ${(props) => props.theme.margins.xs};
  }
`;

class StartTrialModal extends PureComponent {
  handleSubmit = () => {
    const { context, location = {} } = this.props.options || {};
    API.makeRequest(`/${context.type}/${context.slug}/activate-trial`, 'POST', {}, (err, res) => {
      if ((res && res.body && res.body.error) || err || res.status !== 200) {
        return alert((err && err.message) || (res.body || {}).error || res.text);
      }
      browserHistory.replace({
        ...location,
        query: { ...(location.query || {}), welcomeToTrial: null },
      });

      API.makeRequest('/user', 'GET', this.state, (err, result) => {
        if (!err && result.ok) {
          AppActions.updateUser(result.body);
        }
      });

      alert(
        'Welcome to your 30 day trial of our Premium Membership!\nAfter your trial expires, we will restore your original feature set.\nHave fun!'
      );
    });
  };

  render() {
    const { onCancel } = this.props;
    const {
      options: { context },
    } = this.props;

    return (
      <Container>
        <SecondaryHeader>Are you ready to start your trial?</SecondaryHeader>
        <Paragraph>
          You'll have 30 days to try out all of our{' '}
          <Link to={`/${context.type}/${context.slug}/manage/account`}>Premium Membership</Link>{' '}
          features.
        </Paragraph>

        <Footer>
          <Buttons.Alternate onClick={onCancel}>No Thanks</Buttons.Alternate>
          <Buttons.Primary onClick={this.handleSubmit}>Activate Trial</Buttons.Primary>
        </Footer>
      </Container>
    );
  }
}

export default StartTrialModal;
