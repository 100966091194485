import React, { PureComponent } from 'react';
import styled from 'styled-components';

import AppActions from '../../actions/app';

import Panel from '@dosomegood/platform/dist/components/layout/Panel';
import { SuspenseSSR } from '../ui/SuspenseSSR';

const Container = styled.div`
  z-index: 9997;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => `${props.theme.margins.xs} 2px`};
  background-color: ${(props) =>
    props.theme.brandColors.hsla(props.theme.brandColors.secondary, 0, 0, 0.8)};

  overscroll-behavior: contain;

  & > section {
    width: 700px;
    margin: auto;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      & {
        margin: 0;
      }
    }
    ${(props) => props.theme.media.tablet`width: 500px;`};
    ${(props) => props.theme.media.phone`width: 100%; padding: 0;`};
  }
`;

export default class Modal extends PureComponent {
  state = {
    step: 0,
  };

  componentDidMount() {
    const orig = document.body.className;
    document.body.className = orig + (orig ? ' ' : '') + 'modal-open';
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.body.className = document.body.className.replace(/ ?modal-open/, '');
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  closeModal = (cancel) => {
    if (cancel) {
      // Close all when cancelling
      AppActions.setModal(false);
    } else {
      AppActions.popModal();
    }
    if (cancel && typeof this.props.onCancel === 'function') {
      this.props.onCancel(this.state);
    }
  };

  nextOrClose = () => {
    const nextStep = this.state.step + 1;
    if (!Array.isArray(this.props.components) || nextStep >= this.props.components.length)
      return this.closeModal();
    this.setState({ step: nextStep });
  };

  handleKeyPress = (event) => {
    event.keyCode === 27 ? this.closeModal(true) : false;
  };

  render() {
    const { step } = this.state;
    const { components: _components } = this.props;

    // Make sure components is an array regardless of input
    const components = _components && Array.isArray(_components) ? _components : [_components];

    // Keep all modals in the DOM, but only show the active one -- that way modal state isn't lost while in sub-modals
    const rendered =
      !!components &&
      components.map((cc, idx) => {
        const {
          uniqueId,
          component: CurrentComponent,
          options: currentOptions,
          ...otherOptions
        } = cc;

        const dispStyle = {
          display: idx === step ? 'block' : 'none',
        };

        return (
          <Panel key={`wrapper${uniqueId}`} compact style={dispStyle}>
            <SuspenseSSR>
              <CurrentComponent
                key={`modal${uniqueId}`}
                options={currentOptions}
                onComplete={this.nextOrClose}
                onCancel={this.closeModal}
                onSkip={this.nextOrClose}
                {...otherOptions}
              />
            </SuspenseSSR>
          </Panel>
        );
      });

    return <Container aria-hidden={!rendered}>{rendered}</Container>;
  }
}
