import React from 'react';
import styled, { css } from 'styled-components';

import { useStore } from '../utils/alt';

import FormStore from '../stores/form';
import ErrorBoundary from './ErrorBoundary';
import { SuspenseSSR } from './ui/SuspenseSSR';
import FocusedFooter from './FocusedFooter';

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: auto;
  ${(props) =>
    props.$backgroundColor
      ? props.$blend
        ? css`
            background: url(${props.theme.backdrops[props.$backdrop]}) no-repeat center center fixed;
            background-color: ${props.$backgroundColor};
            background-blend-mode: multiply;
          `
        : css`
            background-color: ${props.$backgroundColor};
          `
      : css`
          background: url(${props.theme.backdrops[props.$backdrop]}) no-repeat center center fixed;
        `};
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: ${(props) => props.theme.margins.sm} 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.$primaryFont &&
    css`
      & * {
        font-family: '${props.$primaryFont}' !important;
      }
    `};

  ${(props) =>
    props.$backgroundColor &&
    css`
      & #welcome-info {
        background: ${props.theme.brandColors.lightestBackground};
        ${props.theme.borders.standardInvisible};
        margin-right: ${props.theme.margins.sm};
        ${props.theme.media.phone`
    margin-right: 0;
    margin-bottom: ${props.theme.margins.xs};
    `};
        ${props.theme.media.tablet`
    margin-right: 0;
    margin-bottom: ${props.theme.margins.xs};
    `};
      }
    `};
`;

const ContentWrapper = styled.div`
  max-width: ${(props) => props.theme.screenSizes.desktop}px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

function FocusedExperience({ children, backdrop = 'allIn', ...props }) {
  const [{ form }] = useStore(FormStore);

  return (
    <Container
      $backdrop={backdrop}
      $backgroundColor={form?.themeBackgroundColor}
      $blend={form?.themeUseBackground}
      $primaryFont={form?.themeFontPrimary}
    >
      <ErrorBoundary>
        <SuspenseSSR>
          <ContentWrapper>
            {React.Children.map(children, (child) => React.cloneElement(child, props))}
            <FocusedFooter {...props} />
          </ContentWrapper>
        </SuspenseSSR>
      </ErrorBoundary>
    </Container>
  );
}

export default FocusedExperience;
