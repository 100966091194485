import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { smoothScrolling } from '../../mixins/smoothScrolling';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import Link from '@dosomegood/platform/dist/components/buttons/Link';

export default function ScrollToTopArrow() {
  const { t } = useTranslation(['common']);
  const [visible, setVisible] = useState(false);

  const handleScroll = useCallback(() => {
    const nextVisible = window.scrollY >= 1000;
    if (nextVisible !== visible) {
      setVisible(nextVisible);
    }
  }, [visible]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleScrollToTop = useCallback(() => {
    smoothScrolling.toTop();
  }, []);

  return (
    <Link
      onClick={handleScrollToTop}
      className={classNames('scroll-to-top-trigger clickable', {
        'is-visible': visible,
      })}
    >
      <Icon name="chevron-up" /> {t('common:actions.top')}
    </Link>
  );
}
