import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import connectToStores from 'alt-utils/lib/connectToStores';
import { withTranslation } from 'react-i18next';

import NotificationStore from '../../../stores/notification';

import Link from '@dosomegood/platform/dist/components/buttons/Link';
import _UnreadIndicator from '@dosomegood/platform/dist/components/ui/UnreadIndicator';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';

const UnreadIndicator = styled(_UnreadIndicator)`
  position: absolute;
  top: -5px;
  right: -5px;
`;
const InnerUnreadIndicator = styled(_UnreadIndicator)`
  position: absolute;
  top: -5px;
  right: 5px;
`;

@connectToStores
class GroupedAffiliations extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static getStores() {
    return [NotificationStore];
  }

  static getPropsFromStores() {
    return { ns: NotificationStore.getState() };
  }

  state = {
    isOpen: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleDropdown = (event) => {
    event.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClickOutside = (event) => {
    const node = this._container;
    if (!node.contains(event.target)) {
      if (this.state.isOpen) this.setState({ isOpen: false });
    }
  };

  render() {
    const { t, ns: notificationStore } = this.props;

    const affiliates = this.props.affiliations.map((affiliation, idx) => {
      const findUnread = (notificationStore.unreadManagement || []).filter(
        (u) => u.type === affiliation.type && u.slug === affiliation.slug,
      );
      const unreadCount = findUnread && findUnread.length ? findUnread[0].count : 0;

      // Generate subitems pr affiliation:
      const sublinks = affiliation.items.map((item, sidx) => {
        const indicator = item.type === 'management' && (
          <InnerUnreadIndicator>{unreadCount}</InnerUnreadIndicator>
        );
        const isActive = !!item.route && this.context.router.isActive(item.route);

        const value = (
          <>
            <Icon name={item.icon} inverse={isActive} /> {item.title} {indicator}
          </>
        );

        const action = (e) => {
          this.handleClickOutside(e);
          item.onClick && item.onClick(e);
        };

        return (
          <li
            key={sidx}
            className={classNames('not-rounded', { active: isActive })}
            style={{ position: 'relative' }}
          >
            {item.disabled ? (
              <div className="text-muted">
                {value} <small className="pull-right">(in development)</small>
              </div>
            ) : (
              <Link
                to={item.route}
                onClick={action}
                onKeyPress={(e) =>
                  e.key === ' ' || e.key === 'Space' || e.key === 'Enter' ? action(e) : false
                }
              >
                {value}
              </Link>
            )}
          </li>
        );
      });

      // Complete list of all affiliations & associated links
      return (
        <div key={idx} style={{ backgroundColor: 'white' }}>
          <div className={classNames('notification-heading truncate', { square: idx !== 0 })}>
            <Icon name={affiliation.icon} inverse /> {affiliation.title}
          </div>

          <ul className="dropdown-menu-items">{sublinks}</ul>
        </div>
      );
    });

    const dropdownMenu = (
      <div className="dropdown-menu notification-menu" id="manage-dropdown">
        <div className="inner-scrollable">{affiliates}</div>
      </div>
    );

    return (
      <li
        id="nav-manage"
        ref={(r) => (this._container = r)}
        onClick={this.toggleDropdown}
        onKeyPress={(e) =>
          e.key === ' ' || e.key === 'Space' || e.key === 'Enter' ? this.toggleDropdown(e) : false
        }
        className={classNames('btn-group clickable', { open: this.state.isOpen })}
        role="button"
        tabIndex={0}
        aria-label="Manage"
        aria-controls="manage-dropdown"
        aria-expanded={this.state.isOpen}
      >
        <UnreadIndicator>{notificationStore.unreadManagementCount}</UnreadIndicator>
        <Icon name="briefcase" /> {t('common:nav.manage')}
        <i className="fa fa-caret-down ml-md" />
        {dropdownMenu}
      </li>
    );
  }
}

export default withTranslation(['common'])(GroupedAffiliations);
