import React from 'react';
import LocaleDropdown from './header/secondary-menu/LocaleDropdown';
import styled from 'styled-components';

const Footer = styled.footer`
  padding: ${(props) => props.theme.margins.sm};
  min-height: 150px;
`;

const FocusedFooter = ({ user, location }) => {
  return (
    <Footer>
      <LocaleDropdown user={user} location={location} showLabel footer />
    </Footer>
  );
};

export default FocusedFooter;
