import config from '../../../config';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import AppActions from '../../actions/app';
import AppStore from '../../stores/app';
import InviteStore from '../../stores/invite';
import BusinessStore from '../../stores/business';
import OrganizationStore from '../../stores/organization';
import AppStatusActions from '../../actions/appStatus';

import AccountSection from '@dosomegood/platform/dist/components/content/onboarding/AccountSection';
import WelcomeInfoSection from '@dosomegood/platform/dist/components/content/onboarding/WelcomeInfoSection';
import DoubleWide from '@dosomegood/platform/dist/components/content/onboarding/DoubleWide';
import { api as API } from '../../mixins/api';
import LocalStorage from '../../mixins/LocalStorage';
import Auth from '../../utils/Auth';
import {
  getNextLocation,
  getOnboardingType,
  getOnboardingDirect,
  getOnboardingReferral,
} from '../../utils/LocationState';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import Buttons from '@dosomegood/platform/dist/components/buttons';

import { buildReturnPath } from '@dosomegood/platform/dist/utils/URLFormatters';
import { handleSocialConnect, getSocialError } from 'utils/SocialConnect';
import { withTranslation } from 'react-i18next';

class Login extends PureComponent {
  state = {
    requirePassword: false,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { t } = this.props;
    AppActions.changePageTitle(t('common:nav.login'));
    // Hide loader status, since no XHR run on this page to do it automatically, see VolinspireAPI
    setTimeout(() => AppStatusActions.setIsXhrActive(false), 30);
  }

  handleLogin = ({ email, password }, handleServerResponse) => {
    const { invite } = InviteStore.getState();
    const { location } = this.props;
    if (email && !password && !this.state.requirePassword) {
      const next = getNextLocation(invite, location, 'login');
      const returnPath = buildReturnPath(next.pathname, next.query);
      API.makeRequest(`/sso/tenants?email=${encodeURIComponent(email)}`, 'GET', {}, (err, res) => {
        if (err || !res || !res.body) {
          this.setState({ requirePassword: true });
        } else {
          let t = res.body.tenants || [];
          if (!t.length || res.body.usePasswordLogin) {
            return this.setState({ requirePassword: true });
          }
          const sso = t[0];
          LocalStorage.setItem('registration.method', 'email');
          LocalStorage.setItem('registration.email', email);
          // Wait a sec to save localStorage and display loading indicators (if any)
          setTimeout(
            () =>
              (window.location = `${config.auth.host}/redirect/${
                sso.type
              }?email=${encodeURIComponent(email)}&returnPath=${returnPath}`),
            10,
          );
        }
      });
    } else {
      Auth.login(email, password, (loggedIn, accessToken) => {
        if (loggedIn) {
          LocalStorage.setItem('registration.method', 'email');
          LocalStorage.setItem('registration.email', email);
          // Setting these values for instant login effect without reload
          API.activeAccessToken = accessToken;
          API.accessToken = accessToken;
          // Fetch the user profile since we're not loading the next page from server
          API.makeRequest(`/user`, 'GET', {}, (_, res) => {
            AppActions.login(res?.body);
            // Provide enough time for stores to update
            setTimeout(() => {
              this.props.router.push(getNextLocation(invite, location, 'login'));
            }, 10);
          });
        } else {
          handleServerResponse(() => true)(new Error('Incorrect email or password'));
        }
      });
    }
  };

  render() {
    const { user } = AppStore.getState();
    const { location, t } = this.props;
    const { requirePassword } = this.state;
    const { error } = location.query;
    const { invite } = InviteStore.getState();
    const { business } = BusinessStore.getState();
    const { organization } = OrganizationStore.getState();

    const context =
      business && business.slug
        ? { ...business, type: 'business' }
        : { ...organization, type: 'organization' };

    const type = getOnboardingType(invite, location);
    const direct = getOnboardingDirect(location, context);
    const referral = getOnboardingReferral(location);
    const socialError = getSocialError(error);

    const lastLogin = {
      method: LocalStorage.getItem('registration.method'),
      email: LocalStorage.getItem('registration.email'),
    };

    return (
      <DoubleWide>
        {user ? (
          <div style={{ textAlign: 'center' }}>
            <Paragraph>
              {t('common:alerts.alreadyLoggedInAsName', {
                name: `${user.firstName} ${user.lastName}`,
              })}
            </Paragraph>
            <Buttons.Primary to={`/dashboard`}>{t('common:actions.goToNewsFeed')}</Buttons.Primary>
            <br />
            <Buttons.Alternate to={`/logout`}>{t('commonPlat:actions.logout')}</Buttons.Alternate>
          </div>
        ) : (
          <>
            <WelcomeInfoSection type={type} invite={invite} direct={direct} referral={referral} />
            <AccountSection
              socialError={socialError}
              token={location.query.token}
              state={location.state}
              create={false}
              lastLogin={lastLogin}
              nextLocation={getNextLocation(invite, location, 'login')}
              onEmailLogin={this.handleLogin}
              onSocialConnect={handleSocialConnect}
              onBack={() => this.setState({ requirePassword: false })}
              hidePasswordField={!requirePassword}
              allowDisableEmailField
              showApple
            />
          </>
        )}
      </DoubleWide>
    );
  }
}

export default withTranslation(['common', 'commonPlat'])(Login);
