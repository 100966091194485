import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

import MobileMenuItems from './mobileMenuItems';
import MobileMenuGroupedItems from './mobileMenuGroupedItems';

import Menu from '../../../mixins/menu';
import { analytics as Analytics } from '../../../mixins/analytics';

import AppActions from '../../../actions/app';

class MobileMenu extends PureComponent {
  state = {
    showOverflow: false,
  };

  componentDidMount() {
    // iOS/mobile specific menu related fixes
    if (typeof window === 'object') {
      this._removeIOSRubberEffect('.is-scrollable');
    }
  }

  toggleMobileMenu = (event) => {
    event.preventDefault();
    AppActions.toggleMobileMenu();
    Analytics.trackEvent('mobile', {
      action: 'toggle-mobile-menu',
    });
  };

  onHandleOverflowLink = (e) => {
    // e && e.preventDefault();
    this.setState({ showOverflow: false });
  };

  handleToggleOverflow = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.setState({ showOverflow: !this.state.showOverflow }, () => {
      AppActions.toggleMobileMenu();
    });
  };

  _removeIOSRubberEffect = (targetSelector) => {
    const element = document.querySelector(targetSelector);

    element.addEventListener('touchstart', () => {
      const top = element.scrollTop,
        totalScroll = element.scrollHeight,
        currentScroll = top + element.offsetHeight;
      if (top === 0) {
        element.scrollTop = 1;
      } else if (currentScroll === totalScroll) {
        element.scrollTop = top - 1;
      }
    });
  };

  render() {
    const menu = Menu.buildMenu(this.props.user, this.props.t);
    let flatMenu = Menu.flattenMenu(menu.items);

    let overflow;
    if (flatMenu.length > 4) {
      overflow = flatMenu.slice(4, flatMenu.length);
      // overflow = overflow.concat(menu.grouped);
      flatMenu = flatMenu.slice(0, 4);
      flatMenu.push({
        icon: 'ellipses-h',
        title: 'More',
        route: '/more',
        onClick: this.handleToggleOverflow,
      });
    }

    return (
      <nav id="tabbar" className="is-scrollable hidden-sm hidden-md hidden-lg available-menu">
        <MobileMenuItems options={flatMenu} onSelect={this.onHandleOverflowLink} />
        <MobileMenuGroupedItems
          options={overflow}
          manage={menu.grouped}
          hideOverflow={this.onHandleOverflowLink}
          className={this.state.showOverflow ? 'show' : ''}
        />
      </nav>
    );
  }
}

export default withTranslation(['common'])(MobileMenu);
