import React, { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';

const Svg = styled.svg``;
const Circle = styled.circle`
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;
const Text = styled.text`
  ${(props) => props.theme.fonts.primary};
  font-size: ${(props) => props.theme.fonts.sizes.md};
  text-anchor: middle;
  alignment-baseline: middle;
`;

class ProgressRing extends PureComponent {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;

    this.state = { progress: 0 };
  }

  componentDidMount() {
    const { delay = 250 } = this.props;
    this._timer = setTimeout(() => this.setState({ progress: this.props.progress }), delay);
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.progress !== this.props.progress) this.setState({ progress: nextProps.progress });
  }

  render() {
    const { radius, stroke, label, theme, color } = this.props;
    const { progress } = this.state;

    const strokeDashoffset = this.circumference - (progress / 100) * this.circumference;
    const strokeDashoffsetBackground = this.circumference - 1 * this.circumference;

    return (
      <Svg height={radius * 2} width={radius * 2}>
        <Circle
          stroke={theme.brandColors.darkerBackground}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={this.circumference + ' ' + this.circumference}
          style={{ strokeDashoffset: strokeDashoffsetBackground }}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <Circle
          stroke={color || theme.brandColors.hsla(theme.brandColors.primary)}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={this.circumference + ' ' + this.circumference}
          style={{ strokeDashoffset }}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <Text x={radius} y={radius + 1}>
          {label || `${progress}%`}
        </Text>
      </Svg>
    );
  }
}

export default withTheme(ProgressRing);
