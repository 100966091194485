import config from '../../../config';
import React from 'react';

export default function UnsupportedBrowser() {
  return (
    <div className="show-to-incompatible-browsers">
      <div className="global-banner alert alert-danger alert-square">
        <div className="message">
          <h3 className="mt-none">Your browser is not supported.</h3>
          <p>
            To use {config.company.name}, we recommend using the latest version of Chrome, Firefox,
            Edge or Safari.
          </p>
          <a href="http://browsehappy.com/?locale=en" target="_blank" rel="noopener noreferrer">
            Learn more and upgrade
          </a>
          .
        </div>
      </div>
    </div>
  );
}
