import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Menu from '../../../mixins/menu';

import SubMenu from './SubMenu';
import GroupedAffiliations from './groupedAffiliations';
import Affiliation from './affiliations';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import Link from '@dosomegood/platform/dist/components/buttons/Link';

const Nav = styled.nav`
  width: 100%;
  & > ul > li > a:not(.active),
  & > ul > li .dropdown-menu a:not(.active) {
    font-weight: 400;
    color: ${(props) => props.theme.brandColors.primaryText} !important;
  }
  & > ul > li > a.active,
  & > ul > li.active > a {
    color: ${(props) => props.theme.brandColors.links} !important;
    font-weight: 600;
  }
  & > ul > li .dropdown-menu .active a {
    color: ${(props) => props.theme.brandColors.primaryTextInverse} !important;
  }
`;

function DesktopMenu({ user }, { router }) {
  const { t } = useTranslation(['common']);
  const compiledMenu = Menu.buildMenu(user, t);

  const menu = compiledMenu.items.map((item, idx) => {
    if (item.route) {
      // Single item
      return (
        <li key={item.id || idx}>
          <Link id={item.id} to={item.route} activeClassName="active">
            <Icon name={item.icon} /> {item.title}
          </Link>
        </li>
      );
    } else {
      // Grouped items
      // Any subroutes active?
      const activeRoutes = item.items.find((i) => router.isActive(i.route));
      return <SubMenu key={item.id || idx} {...item} activeRoutes={activeRoutes} />;
    }
  });

  let affiliations = null;

  // Group multiple affiliations
  if (compiledMenu.grouped.length > 1) {
    affiliations = <GroupedAffiliations affiliations={compiledMenu.grouped} />;
  } else if (compiledMenu.grouped.length <= 1) {
    affiliations = compiledMenu.grouped.map((affiliation, idx) => (
      <Affiliation key={idx} value={affiliation} />
    ));
  }

  return (
    <Nav role="navigation" className="hidden-xs">
      <ul className="primary-menu">
        {menu}
        {affiliations}
      </ul>
    </Nav>
  );
}

DesktopMenu.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default DesktopMenu;
