import config from '../config';
import io from 'socket.io-client';
import MessageActions from './actions/messages';
import MessageStore from './stores/messages';
import NotificationActions from './actions/notification';
import { api as API } from './mixins/api';

function playNotificationSound() {
  const audioElement = document.getElementById('notificationSound');
  try {
    if (audioElement?.play) audioElement.play();
  } catch (e) {
    // Silently catch error just incase user hasn't interacted with the page yet, and/or browser blocks sound
  }
}

export default class RealtimeSystem {
  socket = null;
  listenersCreated = false;
  accessToken = null;
  authenticated = false;

  // When did we start trying to connect?
  connectStart = null;

  constructor(token = null) {
    this.accessToken = token || (API.token() && API.token()[0]);
    if (this.accessToken) {
      console.info('[Realtime] Access credentials available on construction.');
      this.tryConnect();
    } else {
      console.warn(
        '[Realtime] No access credentials available on construction - not initializing.',
      );
      this.socket = null;
    }
  }

  tryConnect = () => {
    console.log('[Realtime] Connecting...');
    this.connectStart = new Date();

    // If we've never setup a socket, let's configure it for the first time
    if (!this.socket) {
      this.socket = io.connect(config.realtime.host);
    } else {
      // Re-use the existing one if we have it
      this.socket.connect();
    }

    // Catch situations where the initial construction didn't set things up since we weren't authenticated
    if (!this.listenersCreated) this.setupListeners();
  };

  /**
   * Save a newly refreshed token for use later if we reconnect and need to provide proof of who we still are
   * @param {*} token
   */
  updateToken = (token) => {
    this.accessToken = token;

    if (token) {
      console.info('[Realtime] Updated access credentials.');

      // Make sure we reconnect, if a user loses connectivity and regains
      // it we cannot auth with the old token if it's after an hour, so the
      // API client will give us a new one on refresh and we can use that.
      if (!this.authenticated) {
        console.info(
          '[Realtime] New credentials arrived and we were disconnected and need to reconnect.',
        );
        this.tryConnect();
      }
    } else {
      console.warn('[Realtime] Access credentials cleared.');
    }
  };

  /** Actively and intentionally disconnect the realtime socket */
  disconnect = () => {
    if (this.socket) {
      this.socket.disconnect();
      this.authenticated = false;
      this.connectStart = null;
    } else {
      console.warn('[Realtime] Disconnect requested, no socket to disconnect.');
    }
  };

  /** Reinitialize the connection, usually when authenticated user changes */
  reinitialize = (token) => {
    if (this.authenticated) {
      console.warn(
        '[Realtime] Reinitializing while authenticated, disconnecting old socket first.',
      );
      this.disconnect();
    } else if (this.connectStart) {
      console.warn(
        '[Realtime] Reinitializing while connection in progress, disconnecting old socket first.',
      );
      this.disconnect();
    }

    this.accessToken = token || (API.token() && API.token()[0]);
    if (this.accessToken) {
      this.tryConnect();
    } else {
      console.warn(
        '[Realtime] No access credentials available on reinitialize - not initializing.',
      );
      this.accessToken = null;
      this.authenticated = false;
    }
  };

  setupListeners = () => {
    this.listenersCreated = true;

    this.socket.on('connect', () => {
      const connectTime = new Date() - this.connectStart;
      console.info(`[Realtime] Connected. Took ${connectTime}ms.`);
      this.authenticated = false;
      this.connectStart = null;

      if (!this.accessToken) {
        console.warn(
          '[Realtime] No access credentials available on connect/reconnect - not authenticating.',
        );
        return;
      }

      // Send the auth token to the server so it doesn't boot us out
      this.socket.emit('authenticate', { token: this.accessToken });
    });

    this.socket.on('disconnect', (reason) => {
      console.warn(`[Realtime] Disconnected. (${reason})`);
      this.authenticated = false;
    });

    this.socket.on('expiredToken', () => {
      console.info('[Realtime] Access credentials have expired - disconnecting until refreshed.');
      // Disabling automatic refresh of credentials for now, as it might be causing loops and async issues
      // (Brent: Mar 13, 2024)
      /*console.log(
        'Token has expired, need to refresh before resuming realtime communications...',
      );
      API.requestRefresh((err, resume) => {
        if (err) return console.error('Could not refresh token');
        this.socket.emit('authenticate', { token: api.token()[0] });
        resume();
      });*/
    });

    this.socket.on('authenticated', () => {
      console.log('[Realtime] Authenticated.');
      this.authenticated = true;
    });

    this.socket.on('notification', (packet) => {
      MessageActions.addUnreadNotice();
      playNotificationSound();
    });

    this.socket.on('message', (packet) => {
      NotificationActions.updateUnread();
      const { threads = [] } = MessageStore.getState();

      // If we have the inbox visible and haven't scrolled we can update
      if (threads.length <= 20) {
        // Do a full inbox update for now, could inject in the future
        API.makeRequest('/user/messages', 'GET', {}, (err, res) => {
          if (err || !res.ok) {
            return console.error(
              '[Realtime] Could not fetch unread notifications on message event.',
            );
          }
          const content = res?.body || [];
          MessageActions.updateInbox(content);
          playNotificationSound();
        });
      } else {
        MessageActions.needsRefresh(true);
      }
    });

    this.socket.on('messageReply', (packet) => {
      const thread = MessageStore.getState().thread;
      const foundMessage = (thread.messages || []).find((m) => m.id === packet.message.id);

      if (!foundMessage) {
        MessageActions.addServerReply(packet);
        API.makeRequest('/user/notifications/unread', 'GET', {}, (err, res) => {
          if (err || !res.ok) {
            return console.error(
              '[Realtime] Could not fetch unread notifications on messageReply event.',
            );
          }
          const { body: unread } = res;
          if (!unread) return;
          if (unread.unreadMessages) {
            NotificationActions.updateUnread(unread.unreadMessages);
            playNotificationSound();
          }
        });
      }
    });
  };
}
