import React, { PureComponent } from 'react';
import * as Sentry from '@sentry/react';
import { withTranslation } from 'react-i18next';

import { PrimaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import Buttons from '@dosomegood/platform/dist/components/buttons';

class ErrorBoundary extends PureComponent {
  state = { hasError: false, eventId: null, errorInfo: null };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    });
  }

  render() {
    const { t, children } = this.props;

    if (this.state.hasError) {
      const networkError = t('common:errors.networkError');
      const codeError = t('common:errors.codeError');
      const error = String(this.state.hasError);
      return (
        <div style={{ padding: 11 }}>
          <PrimaryHeader>{t('common:labels.hitSnag')}</PrimaryHeader>
          <Paragraph>{error.toLowerCase().includes('chunk') ? networkError : codeError}</Paragraph>
          <Buttons.Primary onClick={() => location.reload(true)}>
            {t('common:actions.reloadPage')}
          </Buttons.Primary>{' '}
          <Buttons.Secondary
            onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
          >
            {t('common:actions.tellWhatHappened')}
          </Buttons.Secondary>
          {!error.toLowerCase().includes('chunk') && (
            <Paragraph>
              {t('common:labels.techInfo')}: ({this.state.eventId}) {error}
            </Paragraph>
          )}
        </div>
      );
    }

    return children;
  }
}

export default withTranslation(['common'])(ErrorBoundary);
