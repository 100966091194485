import React, { useRef } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import ProfileImage from '@dosomegood/platform/dist/components/ui/ProfileImage';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import {
  CollectionItemDetail,
  CollectionItemHeader,
} from '@dosomegood/platform/dist/components/typography/Content';
import Link from '@dosomegood/platform/dist/components/buttons/Link';
import DropdownView from '@dosomegood/platform/dist/components/buttons/DropdownView';

const Container = styled.button`
  display: flex;
  align-items: center;
  flex-direction: row;

  background-color: ${(props) => props.theme.brandColors.lightestBackground};
  ${(props) => props.theme.borders.inner};
  margin-left: 11px;
  padding: 2px;
  cursor: pointer;
  &:hover {
    border-color: ${(props) => props.theme.brandColors.hsla(props.theme.brandColors.tertiary)};
  }
  & > :first-child {
    margin: 0 2px;
  }
  & i {
    padding: 0 7px;
  }

  ${(props) => props.theme.media.phone`margin-right: 3px`};
`;

const Title = styled.div`
  padding: ${(props) => props.theme.margins.xs};
`;

const MenuContainer = styled.div`
  & > ul > li > a {
    font-weight: 400;
    color: ${(props) => props.theme.brandColors.primaryText} !important;
  }
`;

function UserDropDown({ id, isProxy, user, t }) {
  const dropdownRef = useRef(null);
  const handleClose = () => dropdownRef.current.toggle();

  const dropdownMenu = (
    <MenuContainer>
      <Title>
        <CollectionItemHeader>
          {isProxy ? 'Acting as: ' : ''}
          {user.firstName} {user.lastName}
        </CollectionItemHeader>
      </Title>
      <ul className="fa-ul dropdown-menu-items">
        <li>
          <Link to={`/user/profile/${user.hash}`} onClick={handleClose}>
            <i className="fa fa-user" /> {t('common:nav.myProfile')}
          </Link>
        </li>
        <li>
          <Link to="/user/settings" onClick={handleClose}>
            <i className="fa fa-gear" /> {t('common:nav.settingsAndPrivacy')}
          </Link>
        </li>
        <li>
          <Link
            href="https://support.dosomegood.ca/hc/en-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-question-circle" /> {t('common:nav.helpCenter')}
          </Link>
        </li>
        <li>
          <Link
            href="https://support.dosomegood.ca/hc/en-us/requests/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-commenting-o" /> {t('common:nav.submitFeedback')}
          </Link>
        </li>
        <li>
          <Link to="/logout" onClick={handleClose}>
            <i className="fa fa-power-off" /> {t('commonPlat:actions.logout')}
          </Link>
        </li>
      </ul>

      {(user.userType >= 1 || !!isProxy) && (
        <>
          <Title>
            <CollectionItemHeader>STAFF OPTIONS</CollectionItemHeader>
          </Title>

          {isProxy ? (
            <ul className="fa-ul dropdown-menu-items">
              <li className="text-primary">
                <Link to="/logout-of-proxy" onClick={handleClose}>
                  <i className="fa fa-power-off" /> {t('common:nav.exitProxy')}
                </Link>
              </li>
            </ul>
          ) : (
            <ul className="fa-ul dropdown-menu-items">
              <li>
                <Link to="/staff" onClick={handleClose}>
                  <Icon name="cog" /> {t('common:nav.dashboard')}
                </Link>
              </li>
              <li>
                <Link to="/dashboard/staff" onClick={handleClose}>
                  <Icon name="news" /> News Promotion
                </Link>
              </li>
              <li>
                <Link to="/staff/users" onClick={handleClose}>
                  <Icon name="group" /> {t('common:nav.userManagement')}
                </Link>
              </li>
            </ul>
          )}
        </>
      )}
    </MenuContainer>
  );

  return (
    <DropdownView
      dropdownRef={dropdownRef}
      buttonComponent={
        <Container
          id={id}
          tabIndex="0"
          role="button"
          aria-label={`Do Some Good account: ${user.firstName} ${user.lastName}`}
        >
          <ProfileImage
            type="user"
            size="xs"
            name={`${user.firstName} ${user.lastName}`}
            image={user.profilePhoto}
          />
          <CollectionItemDetail style={{ padding: '2px 0 0 4px' }}>
            <span>{user.firstName}</span>
            <span className="hidden-xs"> {user.lastName}</span>
          </CollectionItemDetail>
          <Icon name="ellipses-v" />
        </Container>
      }
      bottom
      attachRight
      fill
      fixed
    >
      {dropdownMenu}
    </DropdownView>
  );
}

export default withTranslation(['common', 'commonPlat'])(UserDropDown);
