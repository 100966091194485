import React, { PureComponent } from 'react';

import { api as API } from '../../mixins/api';
import { analytics as Analytics } from '../../mixins/analytics';

import AppActions from '../../actions/app';

import ConnectedCitySelect from '@dosomegood/platform/dist/components/inputs/connected/CitySelect';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import { FormActions } from '@dosomegood/platform/dist/components/inputs/Form';
import { SecondaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import { withTranslation } from 'react-i18next';
import { localizeCity } from '@dosomegood/platform/dist/utils/Lang';

class MiniModalVerifyLocation extends PureComponent {
  state = {
    showChangeCity: this.props.change || this.props.options?.change || false,
  };

  _saveUser = (payload) => {
    API.makeRequest('/user', 'PUT', payload, (err, result) => {
      if (!err && result.ok) {
        AppActions.updateUser(result.body);
        if (this.props.parentClose) this.props.parentClose();
        if (this.props.onUpdate) this.props.onUpdate();
        if (this.props.options?.onUpdate) this.props.options.onUpdate();
      }
    });
  };

  confirmCity = () => {
    Analytics.trackEvent('user-profile', {
      label: this.props.user.city,
      action: 'verify-city',
    });
    this._saveUser({ verifiedCity: true });
  };

  showChangeCity = () => {
    this.setState({ showChangeCity: true });
  };

  updateLocation = (location) => {
    if (location?.city) {
      this.setState({
        city: location.city,
        country: location.country,
        region: location.region,
      });
    } else {
      this.setState({ city: undefined, country: undefined, region: undefined });
    }
  };

  confirmLocation = () => {
    const payload = {
      city: this.state.city,
      country: this.state.country,
      region: this.state.region,
      verifiedCity: true,
    };
    Analytics.trackEvent('user-profile', {
      label: this.state.city,
      action: 'change-and-verify-city',
    });
    this._saveUser(payload);
  };

  render() {
    const { t, options, onCancel } = this.props;
    const user = this.props.user || options?.user || {};
    const isModal = options?.display === 'modal';

    return (
      <div>
        {isModal && <SecondaryHeader>{t('common:nav.verifyYourLocation')}</SecondaryHeader>}
        {this.state.showChangeCity ? (
          <>
            <label>{t('common:labels.whereAreYouLocated')}</label>
            <ConnectedCitySelect
              api={API}
              placeholder={t('common:placeholders.yourCityName')}
              priorityRegion={user?.region}
              priorityCountry={user?.country}
              onChange={this.updateLocation}
              autoFocus
            />
            <FormActions>
              {isModal && (
                <Buttons.Alternate onClick={onCancel}>
                  {t('commonPlat:actions.cancel')}
                </Buttons.Alternate>
              )}
              <Buttons.Primary onClick={this.confirmLocation} disabled={!this.state.city}>
                {t('commonPlat:actions.save')}
              </Buttons.Primary>
            </FormActions>
          </>
        ) : (
          <>
            <label>
              {t('common:labels.areYouLocatedInCity', { city: localizeCity(user.city, t) })}
            </label>
            <Buttons.Primary onClick={this.confirmCity}>
              {t('common:actions.yesThatsRight')}
            </Buttons.Primary>
            &nbsp;
            <Buttons.Secondary onClick={this.showChangeCity}>
              {t('common:actions.noChangeIt')}
            </Buttons.Secondary>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation(['common', 'commonPlat'], { withRef: true })(
  MiniModalVerifyLocation,
);
