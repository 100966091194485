import React from 'react';
import MobileMenuItem from './mobileMenuItem';

export default function MobileMenuItems({ options = [], onSelect }) {
  return (
    <div>
      <ul className="menu-grid">
        {options.map((option, idx) => (
          <MobileMenuItem {...option} key={idx} onSelect={onSelect} />
        ))}
      </ul>
    </div>
  );
}
