import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFetch } from 'services/Hooks';

import { commafy } from '@dosomegood/platform/dist/utils/Numbers';
import { StatBlock, StatsWrapper } from 'components/ui/StatBlock';
import { Bubble, BubbleWrap } from '@dosomegood/platform/dist/components/ui/Bubble';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';

export default function PlatformTicker() {
  const { t } = useTranslation(['vol', 'common']);
  const [ticker, loading] = useFetch('/user/ticker');

  const numHours = parseInt(ticker?.platformHours || 0, 10);
  const numOrganizations = ticker?.platformOrganizations || 0;

  return (
    <div style={{ paddingBottom: 48 }}>
      <BubbleWrap>
        <StatsWrapper fill style={{ marginBottom: 22 }}>
          <StatBlock
            name={t('vol:labels.totalHoursVolunteered')}
            value={commafy(numHours)}
            loading={loading}
            compact
          />
          <StatBlock
            name={t('common:labels.organizationsSupported')}
            value={commafy(numOrganizations)}
            loading={loading}
            compact
          />
        </StatsWrapper>
        <Bubble bottom attachRight style={{ width: '100%', textAlign: 'center' }}>
          <Icon name="heart" style={{ color: 'crimson', marginRight: '8px' }} />
          {t('common:labels.forceForGood')}
        </Bubble>
      </BubbleWrap>
    </div>
  );
}
