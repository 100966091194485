import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import AppStore from '../stores/app';
import AppActions from '../actions/app';
import { getJoyrideLocale } from './Lang';
import { useStore } from './alt';
import { api as API } from '../mixins/api';

/**
 * For dynamically setting the title of the page
 *
 * @param {string} title
 * @param {array} deps
 */
export function usePageTitle(title, deps = []) {
  useEffect(() => {
    AppActions.changePageTitle(title);
  }, deps);
}

// So we don't continually make a new array for comparison
const emptyFlags = [];

/**
 * Provides an array of string-based flags set on a user profile, and a
 * function to toggle them on/off - handling the API request and state updates.
 *
 * @returns {[string[], (flag: string, value: boolean) => void]} [flags, setFlag]
 */
export function useUserFlags() {
  const [appStore] = useStore(AppStore);
  const [cachedFlags = emptyFlags, setCachedFlags] = useState(appStore.user?.flags || emptyFlags);
  const handleSetFlag = useCallback(
    (flag, value) => {
      if (!appStore.user) return;
      if (cachedFlags.includes(flag) && value) return;
      if (!cachedFlags.includes(flag) && !value) return;
      let newFlags;
      if (value) {
        newFlags = [...(cachedFlags || []), flag];
      } else {
        newFlags = cachedFlags.filter((f) => f !== flag);
      }
      setCachedFlags(newFlags);
      AppActions.updateUser({ flags: cachedFlags });
      API.makeRequest('/user', 'PUT', { flags: newFlags }, (err, result) => {
        if (!err && result.ok) {
          AppActions.updateUser(result.body);
        }
      });
    },
    [cachedFlags, appStore.user],
  );
  useEffect(() => {
    setCachedFlags(appStore.user?.flags);
  }, [appStore.user?.flags]);
  return [cachedFlags, handleSetFlag];
}

/**
 * Provided the correct steps array for the Joyride system, returns a start function and properties object,
 * the properties object should be spread over the <Joyride /> component to configure it correctly.
 *
 * @param {*} steps
 * @param {function(boolean): void} onJoyrideDone
 * @returns {[function(): void, object, boolean, number]} [startJoyride, props, joyrideRun, joyrideStep]
 */
export function useJoyride(steps, onJoyrideDone = () => {}) {
  const { t } = useTranslation(['common', 'commonPlat']);
  const [joyrideRun, setJoyrideRun] = useState(false);
  const [joyrideStep, setJoyrideStep] = useState(0);

  const handleJoyrideChange = useCallback(
    ({ action, index, status, type }) => {
      if (action === ACTIONS.CLOSE) {
        setJoyrideRun(false);
        onJoyrideDone(false);
        return;
      }

      if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        if (type === EVENTS.TARGET_NOT_FOUND) console.warn('Joyride missing step on index:', index);
        const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        // If we have an onBefore function in the step we're going to, trigger it first
        if (steps.length > nextIndex && steps[nextIndex].onBefore) {
          steps[nextIndex].onBefore();
          // Update state to advance the tour, but wait for actions to settle before doing so.
          setTimeout(() => setJoyrideStep(nextIndex), 100);
        } else {
          // Update state to advance the tour
          setJoyrideStep(nextIndex);
        }
      } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        // Need to set our running state to false, so we can restart if we click start again.
        setJoyrideRun(false);
        onJoyrideDone(status === STATUS.FINISHED);
      }
    },
    [steps, onJoyrideDone],
  );

  const startJoyride = useCallback(() => {
    // Synthetic delay to encourage settling of any async content on the page incase the target(s) we're looking for isn't there yet
    const _delayStart = setTimeout(() => {
      setJoyrideStep(0);
      setJoyrideRun(true);
    }, 30);
    return () => clearTimeout(_delayStart);
  }, []);

  const props = {
    run: joyrideRun,
    stepIndex: joyrideStep,
    steps,
    callback: handleJoyrideChange,
    styles: {
      options: {
        primaryColor: '#2667ac',
        zIndex: 1100,
        width: 460,
      },
    },
    continuous: true,
    scrollOffset: 94,
    scrollToFirstStep: true,
    locale: getJoyrideLocale(t),
  };

  return [startJoyride, props, joyrideRun, joyrideStep];
}

/**
 * Takes an array of watchKeys, adds event listeners for each key - setting boolean states for whether each key is down.
 * @param {string[]} watchKeys
 * @returns {object} keyStates
 */
export function useKeyboardInput(watchKeys) {
  const [keyStates, setKeyStates] = useState(
    watchKeys.reduce((acc, key) => ({ ...acc, [key]: false }), {}),
  );
  const handleKeyDown = useCallback(
    (e) => {
      if (watchKeys.includes(e.key)) {
        setKeyStates((prev) => ({ ...prev, [e.key]: true }));
      }
    },
    [watchKeys],
  );
  const handleKeyUp = useCallback(
    (e) => {
      if (watchKeys.includes(e.key)) {
        setKeyStates((prev) => ({ ...prev, [e.key]: false }));
      }
    },
    [watchKeys],
  );
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [watchKeys]);
  return keyStates;
}
