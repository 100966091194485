import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { api as API } from '../mixins/api';
import { analytics } from '../mixins/analytics';

import AppActions from '../actions/app';

import Panel from '@dosomegood/platform/dist/components/layout/Panel';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import { SecondaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import { usePageTitle } from '../utils/Hooks';
import { resolveFullLocale } from '../utils/Lang';
import { ModalFooterActions } from './ui/ModalComponents';
import { browserHistory } from 'react-router';
import Link from '@dosomegood/platform/dist/components/buttons/Link';

const NEWEST_VERSION = 2;

/**
 * This says Agree To Terms, but it's really Agree to the Subscription and Licensing Agreement
 * @param {*} param0
 * @returns
 */
export default function AgreeToTerms({ targets }) {
  const firstNonAgreed = targets[0];

  const { t, i18n } = useTranslation(['common', 'commonPlat', 'socialPlat']);
  usePageTitle(t('common:labels.licenseAgreement'));

  const handleSendAgreed = useCallback(() => {
    API.makeRequest(
      '/' + firstNonAgreed.type + '/' + firstNonAgreed.target.slug,
      'PUT',
      { agreedTerms: NEWEST_VERSION },
      (err, res) => {
        analytics.trackEvent('terms-of-service', {
          label: 'on ' + new Date(),
          action: 'agreed',
        });

        AppActions.updateUserRelation({
          ...firstNonAgreed,
          target: { ...firstNonAgreed.target, agt: NEWEST_VERSION },
        });
      },
    );
  }, [firstNonAgreed]);

  const title = t('common:labels.licenseAgreementUpdated');

  return (
    <Panel>
      <SecondaryHeader>{title}</SecondaryHeader>
      <Paragraph>
        {t('common:labels.licenseAgreementIntro', { name: firstNonAgreed.target.name })}
      </Paragraph>
      <iframe
        src={`https://dosomegood.ca/${resolveFullLocale(
          i18n,
        )}/legal/subscription-and-licensing-agreement?embed=true`}
        width="100%"
        height="400"
        style={{ border: 0 }}
      />
      <Paragraph>
        <Trans t={t} i18nKey="common:labels.ifQuestionsAgreementPleaseContact">
          If you have questions about the updated agreement, please contact{' '}
          <Link href="mailto:info@dosomegood.ca">info@dosomegood.ca</Link>
        </Trans>
      </Paragraph>

      <ModalFooterActions>
        <Buttons.Alternate onClick={() => browserHistory.push(`/dashboard`)}>
          {t('commonPlat:actions.cancel')}
        </Buttons.Alternate>
        <Buttons.Primary onClick={handleSendAgreed}>
          {t('commonPlat:actions.acceptAndContinue')}
        </Buttons.Primary>
      </ModalFooterActions>
    </Panel>
  );
}
