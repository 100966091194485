import * as React from 'react';
import cookieDough from 'cookie-dough';
import { browserHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { api as API } from '../../../mixins/api';

import Dropdown from '@dosomegood/platform/dist/components/buttons/Dropdown';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import HeaderIcon from './HeaderIcon';
import { CollectionItemDetail } from '@dosomegood/platform/dist/components/typography/Content';

function LocaleDropdown({ showLabel = false, user, location, footer = false }) {
  const { i18n } = useTranslation();
  const { languages, changeLanguage } = i18n;
  const resolvedLanguage = languages[0];
  const cookie = cookieDough();

  const locales = [
    { id: 'en-CA', name: 'English', icon: resolvedLanguage === 'en' ? 'checkmark' : undefined },
    { id: 'fr-CA', name: 'Français', icon: resolvedLanguage === 'fr' ? 'checkmark' : undefined },
  ];

  const today = new Date();
  const cookieExpiry = new Date(
    // One Year, should be as long as possible so language setting isn't lost (Chrome has 400 day max)
    today.getTime() + 365 * 1000 * 24 * 60 * 60,
  );

  const title = `Language options / Options de langue`;

  return (
    <Dropdown
      options={locales}
      bottom
      fixed
      ariaLabel={title}
      onSelect={({ id }) => {
        changeLanguage(id);
        cookie.set('locale', id, {
          path: '/',
          domain: `.${window.location.hostname}`,
          expires: cookieExpiry,
        });
        // If we have a location, we can trim off any explicit locale query param since we want to ensure the chosen one is used instead
        if (location) {
          browserHistory.replace({ ...location, query: { ...location?.query, locale: undefined } });
        }
        // If we're logged in, persist the choice to their account so API communications are done in correct language
        if (user) API.makeRequest('/user', 'PUT', { locale: id }, () => true);
      }}
    >
      <HeaderIcon tabIndex={0} $hasLabel={showLabel}>
        <Icon name="globe" title={title} style={showLabel ? { marginRight: 11 } : {}} />
        {showLabel && (
          <CollectionItemDetail style={{ minHeight: '18px' }}>
            {locales.find((l) => l.icon === 'checkmark')?.name || 'English'}
          </CollectionItemDetail>
        )}
      </HeaderIcon>
    </Dropdown>
  );
}

export default LocaleDropdown;
