import React from 'react';
import Link from '@dosomegood/platform/dist/components/buttons/Link';
import { useTranslation } from 'react-i18next';

export default function ProfilePreviewMenu({ path = '' }) {
  const { t } = useTranslation(['sMgmt']);
  const pathSearch = /(business|organization|user)/.exec(path);
  const type = pathSearch && pathSearch[0];

  const returnToManage = (
    <Link to={`${path}/manage/profile`}>{t('sMgmt:actions.returnToManagement')}</Link>
  );
  const returnToUser = (
    <Link to={path.replace('?preview', '')}>{t('sMgmt:actions.returnToProfile')}</Link>
  );

  return (
    <ul className="primary-menu preview-menu available-menu">
      <li>
        <p className="title-menu">{t('sMgmt:labels.howProfileAppearsToPublic')}</p>
      </li>
      <li style={{ width: '200px' }}>
        {type === 'business' || type === 'organization' ? returnToManage : false}
        {type === 'user' ? returnToUser : false}
      </li>
    </ul>
  );
}
