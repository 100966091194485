import smoothScroll from 'smoothscroll';

const NAV_HEIGHT = 90;

const smoothScrolling = {
  toTop: function () {
    if (typeof window !== 'object') return;
    smoothScroll(document.body);
  },

  toId: function (selector) {
    if (typeof window !== 'object') return;
    const target = document.getElementById(selector);
    if (target) smoothScroll(target, undefined, undefined, undefined, -NAV_HEIGHT);
  },

  toIdWithOffset: function (selector, offsetY) {
    if (typeof window !== 'object') return;
    const target = document.getElementById(selector);
    if (target) smoothScroll(target, undefined, undefined, undefined, offsetY);
  },

  toIdWithOffsetQuick: function (selector, offsetY, callback = undefined) {
    if (typeof window !== 'object') return;
    const target = document.getElementById(selector);
    if (target) smoothScroll(target, 200, callback, undefined, offsetY);
  },
};

export default { smoothScrolling };
export { smoothScrolling };
