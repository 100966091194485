const LocalStorage = {
  isSupported() {
    if (typeof window === 'undefined') return false;
    const testVal = 'DoSomeGood <3 u';
    try {
      localStorage.setItem(testVal, testVal);
      localStorage.removeItem(testVal);
      return true;
    } catch (ex) {
      return false;
    }
  },

  setItem(key, value) {
    if (this.isSupported()) {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
    } else {
      // console.warn('LocalStorage is not supported.');
      return false;
    }
  },

  getItem(key) {
    if (this.isSupported()) {
      const val = localStorage.getItem(key);
      return val !== undefined ? JSON.parse(val) : null;
    } else {
      // console.warn('LocalStorage is not supported.');
      return;
    }
  },

  removeItem(key) {
    if (this.isSupported()) {
      localStorage.removeItem(key);
      return true;
    } else {
      console.warn('LocalStorage is not supported.');
      return false;
    }
  },
};

export default LocalStorage;
export { LocalStorage };
