import APIClient from '../services/api';
import configAuth from '../../shared/auth';

let api = {};

if (typeof window === 'object') {
  const auth = configAuth();
  api = global.api || new APIClient(auth);
  global.api = api;
}

export default { api };
export { api };
