import config from '../../config';
import React, { PureComponent } from 'react';
import { api as API } from '../mixins/api';

import AppStore from '../stores/app';
import AppActions from '../actions/app';

export default class Diagnostics extends PureComponent {
  state = {
    adblock: false,
    adblock2: false,
    create: false,
    update: false,
    del: false,
    location: false,
    localLocation: AppStore.getState().defaultLocation,
  };

  componentDidMount() {
    AppActions.changePageTitle('Platform Diagnostics');
    this.loadData();
  }

  loadData = () => {
    API.makeRequest(`/ad/affiliate/banner`, 'GET', {}, (err, res) => {
      this.setState({ adblock: err || res.body });

      API.makeRequest(`/ads/affiliate/banner`, 'GET', {}, (err, res) => {
        this.setState({ adblock2: err || res.body });

        API.makeRequest(`/diag/user`, 'POST', { email: 'info@dosomegood.ca' }, (err, res) => {
          this.setState({ create: err || res.body });

          API.makeRequest(
            `/diag/user`,
            'PUT',
            { firstName: 'Test', lastName: 'User' },
            (err, res) => {
              this.setState({ update: err || res.body });

              API.makeRequest(`/diag/user/affiliations`, 'DELETE', {}, (err, res) => {
                this.setState({ del: err || res.body });

                API.makeRequest(`/diag/location`, 'GET', {}, (err, res) => {
                  this.setState({ location: err || res.body });
                });
              });
            },
          );
        });
      });
    });
  };

  handleResetAndRun = () => {
    this.setState({
      adblock: false,
      adblock2: false,
      create: false,
      update: false,
      del: false,
      location: false,
      localLocation: AppStore.getState().defaultLocation,
    });
    this.loadData();
  };

  render() {
    return (
      <div>
        <h1>Diagnostics</h1>
        <hr />

        <p>Adblock URL: {JSON.stringify(this.state.adblock)}</p>
        <p>Adblock URL 2: {JSON.stringify(this.state.adblock2)}</p>
        <p>Adblock Images:</p>
        <div
          style={{
            width: 10,
            height: 10,
            backgroundColor: 'red',
            float: 'left',
            position: 'relative',
            margin: 10,
          }}
        >
          <img
            style={{
              border: 0,
              margin: 0,
              padding: 0,
              position: 'relative',
              top: 0,
              left: 0,
              verticalAlign: 'top',
            }}
            src={`${config.api.host}/ad/affiliate/banner.gif`}
            alt="Failed"
          />
        </div>
        <div
          style={{
            width: 10,
            height: 10,
            backgroundColor: 'red',
            float: 'left',
            position: 'relative',
            margin: 10,
          }}
        >
          <img
            style={{
              border: 0,
              margin: 0,
              padding: 0,
              position: 'relative',
              top: 0,
              left: 0,
              verticalAlign: 'top',
            }}
            src={`${config.api.host}/ads/banner.gif`}
            alt="Failed"
          />
        </div>
        <p style={{ clear: 'both', paddingTop: 20 }}>
          Create Profile: {JSON.stringify(this.state.create)}
        </p>
        <p>Update Profile: {JSON.stringify(this.state.update)}</p>
        <p>Delete w/Adblock: {JSON.stringify(this.state.del)}</p>
        <p>Location, IP and Headers: {JSON.stringify(this.state.location)}</p>
        <p>IP to Location (Client-side): {JSON.stringify(this.state.localLocation)}</p>

        <button onClick={this.handleResetAndRun}>Re-run Tests</button>
      </div>
    );
  }
}
