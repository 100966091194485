import OrganizationDashboardActions from 'actions/organizationDashboard';
import { api as API } from 'mixins/api';
import { browserHistory } from 'react-router';

export function getMasterList(context, t) {
  const {
    classification,
    onboardingGoal,
    usage: { numForms, numStories, numPositions } = {},
  } = context;
  const flags = context.flags || {};

  //const hasCommunityStatement = !!context.communityStatement && context.communityStatement.length > 0;
  const hasAbout = !!context.about && context.about.length > 0;
  const hasCauses = !!context.causes && context.causes.length > 0;
  const hasLogo = !!context.logo;

  const hasConnection =
    context.managementStats?.relationshipsOutBusinesses > 0 ||
    context.managementStats?.relationshipsInBusinesses > 0;

  const hasStory = context.managementStats?.organizationPostsSource > 0;
  const hasTestimonials = context.numTestimonialsForBusiness > 0;

  const goalMetMedia = !!numStories || hasStory;
  const goalMetApplications = !!numForms || flags.sFormCreate;
  const goalMetSpotlight = !!numForms || flags.sFormCreate;
  const goalMetInviteBusiness = flags.dInviteBusiness || flags.sInviteBusiness;
  const goalMetRecruitAndSchedule = !!numPositions || flags.sPositions;
  const primaryGoalMetMedia = onboardingGoal === 'media' && goalMetMedia;
  const primaryGoalMetApplications = onboardingGoal === 'applications' && goalMetApplications;
  const primaryGoalMetSpotlight = onboardingGoal === 'spotlight' && goalMetSpotlight;
  const primaryGoalMetInviteBusiness = onboardingGoal === 'inviteBusiness' && goalMetInviteBusiness;
  const primaryGoalMetRecruitAndSchedule =
    onboardingGoal === 'recruitAndSchedule' && goalMetRecruitAndSchedule;

  const metPrimaryGoal =
    primaryGoalMetMedia ||
    primaryGoalMetApplications ||
    primaryGoalMetSpotlight ||
    primaryGoalMetInviteBusiness ||
    primaryGoalMetRecruitAndSchedule;
  const metSecondaryGoals = goalMetMedia;

  const goals = {
    onboardingGoal,
    goalMetMedia,
    goalMetApplications,
    goalMetSpotlight,
    goalMetInviteBusiness,
    goalMetRecruitAndSchedule,
    primaryGoalMetMedia,
    primaryGoalMetApplications,
    primaryGoalMetSpotlight,
    primaryGoalMetInviteBusiness,
    primaryGoalMetRecruitAndSchedule,
    metPrimaryGoal,
    metSecondaryGoals,
    onboarded: flags.onboarded,
  };

  const brandSteps = [
    { id: 'basic', caption: `Provide name and address`, done: true },
    {
      id: 'profile',
      caption: t('oMgmt:labels.onboardShineUpProfile'),
      done:
        hasLogo &&
        hasAbout &&
        hasCauses &&
        (classification !== 'charity' || !!context.registeredId) &&
        (hasConnection || flags.sBCon) &&
        (!hasConnection || flags.sTest || hasTestimonials) &&
        (hasStory || flags.sStory),
      subSteps: [
        !hasLogo && {
          id: 'logo',
          caption: t('sMgmtPlat:labels.logo'),
          path: `/welcome/profile/logo`,
          done: hasLogo,
        },
        classification === 'charity' && {
          id: 'charityId',
          caption: t('oMgmt:labels.onboardRegisteredCharityId'),
          path: `/welcome/profile/registered-id`,
          done: !!context.registeredId,
        },
        {
          id: 'about',
          caption: t('oMgmt:labels.onboardCausesAbout'),
          path: `/welcome/profile/about`,
          done: hasAbout && hasCauses,
        },
        {
          id: 'connections',
          caption: t('oMgmt:labels.connections'),
          path: `/welcome/profile/connections`,
          done: hasConnection || flags.sBCon,
        },
        {
          id: 'testimonials',
          caption: t('common:labels.testimonials'),
          path: `/welcome/profile/testimonials`,
          done: hasTestimonials || flags.sTest,
        },
        {
          id: 'story',
          caption: t('oMgmt:labels.firstStory'),
          path: `/welcome/profile/story`,
          done: hasStory || flags.sStory,
        },
      ].filter((s) => !!s),
    },
  ].filter((s) => !!s);

  return [brandSteps, [], goals];
}

export function getSubSteps(masterId, context, t) {
  const ms = getMasterList(context, t).find((s) => s.find((ss) => ss.id === masterId));
  const s = ms && ms.find((ss) => ss.id === masterId);
  return s && s.subSteps.filter((ss) => !!ss);
}

export function getSteps(masterId, context, t) {
  const ms = getMasterList(context, t).find((s) => s.find((ss) => ss.id === masterId));
  const s = ms && ms.find((ss) => ss.id === masterId);
  return s;
}

export function fetchOverviewAndResume(context, getMasterList, t) {
  API.makeRequest(`/${context.type}/${context.slug}/management-overview`, 'GET', {}, (err, res) => {
    API.makeRequest(
      `/${context.type}/${context.slug}/management-stats`,
      'GET',
      {},
      (err, stats) => {
        if (!err && res?.body) {
          resumeOnboarding({ ...res.body, managementStats: stats?.body || {} }, getMasterList, t);
        }
      },
    );
  });
}

/**
 * Given the current state of onboarding, determines if and where a user should navigate to and proceeds there automatically
 *
 * @param {*} context
 * @returns
 */
export function resumeOnboarding(context, getMasterList, t) {
  const { flags = {} } = context || {};

  // Stop if we've already onboarded
  if (flags?.onboarded) return false;

  const [primarySteps, __, { onboardingGoal, metPrimaryGoal, metSecondaryGoals }] = getMasterList(
    context,
    t,
  );

  // No goal set, use traditional onboarding funnel with steps
  if (!onboardingGoal) {
    const nextStep = primarySteps.find((s) => !s.done);
    if (nextStep) {
      const nextPath = nextStep.subSteps.find((s) => !s.done)?.path;
      if (nextPath) {
        return browserHistory.push({
          pathname: `/${context.type}/${context.slug}/manage${nextPath}`,
          query: { returning: true },
        });
      } else {
        console.error('No unfinished steps found for wizard-based onboarding');
        markOnboardingComplete(context, () =>
          alert(t('sMgmt:alerts.ourMistakeOnboardingComplete')),
        );
      }
    } else {
      console.error('No unfinished steps found for wizard-based onboarding');
      markOnboardingComplete(context, () => alert(t('sMgmt:alerts.ourMistakeOnboardingComplete')));
    }
  } else {
    // If we haven't met our primary goal, let's find where we're supposed to go based on the selected goal
    if (!metPrimaryGoal) {
      switch (onboardingGoal) {
        case 'media':
          browserHistory.push({
            pathname: `/${context.type}/${context.slug}/manage/social/feed`,
            query: { welcome: true },
          });
          break;
        case 'applications':
        case 'spotlight':
          browserHistory.push({
            pathname: `/${context.type}/${context.slug}/manage/forms/list`,
            query: { welcome: true },
          });
          break;
        case 'inviteBusiness':
          browserHistory.push({
            pathname: `/${context.type}/${context.slug}/manage/relationships/businesses/invite`,
            query: { welcome: true },
          });
          break;
        case 'recruitAndSchedule':
          browserHistory.push({
            pathname: `/${context.type}/${context.slug}/manage/volunteerism/positions/list`,
            query: { welcome: true },
          });
          break;
        case 'notsure':
        default:
          browserHistory.push({
            pathname: `/${context.type}/${context.slug}/manage`,
            query: { welcome: true },
          });
      }
      return;
    }
    if (!metSecondaryGoals) {
      browserHistory.push({
        pathname: `/${context.type}/${context.slug}/manage/social/feed`,
        query: { welcome: true },
      });
      return;
    }
  }

  // If all else fails and we don't have a specific place in mind, take them to the home page that can guide them further
  browserHistory.push(`/${context.type}/${context.slug}/manage`);
}

export function fetchCheckAndUpdateOnboarding(context, callback = () => {}, getMasterList, t) {
  const flags = context.flags || {};
  // If we've already been marked as onboarded, we can just skip ahead
  if (flags.onboarded) return callback(context, false);

  const [_, __, { onboardingGoal, metPrimaryGoal, metSecondaryGoals }] = getMasterList(context, t);

  // If we met our goals with the information provided, then we can just continue as normal
  if (onboardingGoal && metPrimaryGoal && metSecondaryGoals) {
    console.info('Passed pre-check, no need to fetch overview');
    return checkAndUpdateOnboarding(context, callback, getMasterList, t);
  } else if (onboardingGoal && !(metPrimaryGoal && metSecondaryGoals)) {
    console.info('Pre-fetching new overview to check for onboarding criteria');
    // If we have a goal, and didn't fully meet them - let's refetch the management overview to check on any recent changes and look again
    API.makeRequest(
      `/${context.type}/${context.slug}/management-overview`,
      'GET',
      {},
      (err, res) => {
        if (!err && res && res.body) {
          OrganizationDashboardActions.updateManagementOverview(res.body);
          checkAndUpdateOnboarding(res.body, callback, getMasterList, t);
        }
      },
    );
    return;
  }
  console.info(
    "No onboarding goal, we'll just check without fetching (This probably shouldn't happen)",
  );
  return checkAndUpdateOnboarding(context, callback, getMasterList, t);
}

/**
 * Checks the onboarding progress of a business and marks onboarding as finished or not based on the criteria
 *
 * @param {*} context
 * @param {*} callback
 */
export function checkAndUpdateOnboarding(context, callback = () => {}, getMasterList, t) {
  const flags = context.flags || {};
  // If we've already been marked as onboarded, we can just skip ahead
  if (flags.onboarded) return callback(context, false);

  const [_, __, { onboardingGoal, metPrimaryGoal, metSecondaryGoals }] = getMasterList(context, t);

  // If we've met all our goals then we can mark as onboarded and continue
  if (onboardingGoal && metPrimaryGoal && metSecondaryGoals) {
    markOnboardingComplete(context, callback);
    return;
  }

  callback(context, false);
}

export function markOnboardingComplete(context, callback = () => {}) {
  const flags = context.flags || {};
  API.makeRequest(
    `/${context.type}/${context.slug}`,
    'PUT',
    { flags: { ...flags, onboarded: true } },
    (_, __) => {
      callback({ ...context, flags: { ...flags, onboarded: true } }, true);
    },
  );
}
