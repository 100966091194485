import React from 'react';
import styled from 'styled-components';

import AppStatusStore from '../../stores/appStatus';

import _Icon from '@dosomegood/platform/dist/components/ui/Icon';
import { kfSpin } from '../ui/Animations';
import { useStore } from '../../utils/alt';

const Icon = styled(_Icon)`
  display: inline-block;
  animation-name: ${kfSpin};
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

function GlobalActivitySpinner() {
  const [{ isXhrActive }] = useStore(AppStatusStore);
  if (!isXhrActive) return null;
  return (
    <div className="global-loader">
      <Icon name="loading" />
    </div>
  );
}

export default GlobalActivitySpinner;
