import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

import { api as API } from '../../../mixins/api';
import { analytics as Analytics } from '../../../mixins/analytics';

import CharacterCounter from '../../widgets/CharacterCounter';
import ErrorDisplay from '../../forms/ErrorDisplay';

import ConnectedMessageRecipientSelect from '@dosomegood/platform/dist/components/inputs/connected/MultiRecipientSelect';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import { FormActions } from '@dosomegood/platform/dist/components/inputs/Form';

class MessageCompose extends PureComponent {
  constructor(props) {
    super(props);

    // Common Props: Type, hash, id, label, message, subject
    // Special Props: value, values (literal objects as if from the server)
    const { recipient } = props;

    let defaultRecipients = [];

    if (recipient?.hash) {
      // Direct to user
      defaultRecipients = [
        {
          targetRel: recipient.type + '_1_' + recipient.hash,
          label: recipient.label,
        },
      ];
    } else if (recipient?.id && (recipient.type === 'events' || recipient.type === 'attendees')) {
      defaultRecipients = [
        {
          targetRel: recipient.type + '_5_' + recipient.id,
          label: recipient.label,
        },
      ];
    } else if (recipient?.id && recipient.type === 'opportunities') {
      defaultRecipients = [
        {
          targetRel: recipient.type + '_4_' + recipient.id,
          label: recipient.label,
        },
      ];
    } else if (
      recipient?.id &&
      ['formsubs', 'formsubsappr', 'formsubsrej'].includes(recipient.type)
    ) {
      // Special handling of explicit ids from form submission related targets (last part of targetRel provided directly by page via the id)
      defaultRecipients = [
        {
          targetRel: recipient.type + '_' + recipient.id,
          label: recipient.label,
        },
      ];
    } else if (recipient?.value && recipient?.label) {
      // Page supplying the recipient has pre-formatted the data, except for the targetRel is called value
      defaultRecipients = [{ ...recipient, targetRel: recipient.value }];
    } else if (recipient?.values) {
      // Page supplies multiple recipients, fully formatted data provided for each recipient
      defaultRecipients = recipient.values;
    }

    this.state = {
      to: defaultRecipients,
      subject: recipient?.subject || '',
      message: recipient?.message || '',
      errors: [],
      sending: false,
    };
  }

  updateField = (event) => {
    const newState = { [event.target.name]: event.target.value };
    this.setState(newState);
    this.props.onChange({ ...this.state, ...newState });
  };

  updateRecipients = (to) => {
    const newState = { to };
    this.setState(newState);
    this.props.onChange({ ...this.state, ...newState });
  };

  handleSendMessage = (event) => {
    event.preventDefault();

    const { to, subject, message } = this.state;

    // Validation
    if (!to) return alert('Please choose one or more recipients');
    if (!subject) return alert('Please enter a subject for your message');
    if (!message) return alert('Please type a message');

    this.setState({ sending: true });

    API.makeRequest(
      '/user/messages',
      'POST',
      {
        subject,
        body: message,
        recipients: to.map((r) => r.targetRel),
      },
      (err, res) => {
        if (!err && res.ok) {
          Analytics.trackEvent('direct-message', {
            label: subject,
            action: 'compose',
          });

          this.setState({ errors: [], sending: false, message: '', subject: '' });

          if (this.props.onSend) this.props.onSend();
        } else {
          if (res.body) this.setState({ errors: res.body.errors, sending: false });
        }
      },
    );
  };

  onCancel = () => {
    if (this.props.onCancel) this.props.onCancel();
  };

  render() {
    const { t } = this.props;
    const { sending, to } = this.state;
    return (
      <form onSubmit={this.handleSendMessage}>
        <div className="form-group">
          <label className="control-label">{t('common:labels.to')}</label>
          <ConnectedMessageRecipientSelect
            id="to"
            api={API}
            value={to}
            onChange={this.updateRecipients}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="subject" className="control-label">
            {t('common:labels.subject')}
          </label>
          <small className="help-block text-muted pull-right mt-none mb-none">
            <CharacterCounter source={this.state.subject} maxLength={96} />
          </small>
          <input
            id="subject"
            type="text"
            name="subject"
            className="form-control"
            onChange={this.updateField}
            value={this.state.subject}
            minLength={3}
            maxLength={96}
            required
          />
          <ErrorDisplay errors={this.state.errors} name="subject" />
        </div>

        <div className="form-group">
          <label htmlFor="message" className="control-label-invisible">
            {t('commonPlat:actions.message')}
          </label>
          <small className="help-block text-muted pull-right mt-none mb-none">
            <CharacterCounter source={this.state.message} maxLength={4000} />
          </small>
          <textarea
            id="message"
            name="message"
            className="form-control form-control-invisible"
            rows={4}
            onChange={this.updateField}
            value={this.state.message}
            maxLength={4000}
            required
          />
          <ErrorDisplay errors={this.state.errors} name="body" />
        </div>

        <FormActions>
          <Buttons.Alternate onClick={this.onCancel}>
            {t('commonPlat:actions.cancel')}
          </Buttons.Alternate>
          <Buttons.Primary icon="send" type="submit" disabled={sending}>
            {sending ? t('common:labels.sending') : t('commonPlat:actions.sendMessage')}
          </Buttons.Primary>
        </FormActions>
      </form>
    );
  }
}

export default withTranslation(['commonPlat', 'common'])(MessageCompose);
