import React, { cloneElement } from 'react';

export default function OptionalAuth({ location, router, user, children, ...props }) {
  const {
    query: { forceLogin },
  } = location;
  if (user || !forceLogin) return cloneElement(children, { location, router, user, ...props });
  // If they aren't logged in we need to present them with the signup/sign in form
  router.replace({ pathname: '/signup', state: { from: location } });
  return null;
}
