import React from 'react';
import styled, { css } from 'styled-components';

export const ModalContainer = styled.div<{ $centered?: Boolean }>`
  padding: ${(props) => props.theme.margins.xxs};
  ${(props) =>
    props.$centered &&
    css`
      text-align: center;
    `};
`;

export const ModalFooterActions = styled.footer`
  text-align: right;
  padding-top: ${(props) => props.theme.margins.xs};
  & > * {
    margin-left: ${(props) => props.theme.margins.xs};
  }
`;

export const ModalFooterSimple = styled.footer`
  text-align: center;
  padding-top: ${(props) => props.theme.margins.xs};
`;
