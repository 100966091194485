import React from 'react';
import loadable from '@loadable/component';

// Purposely split for more compact imports
import Redirect from 'react-router/lib/Redirect';
import Route from 'react-router/lib/Route';
import IndexRoute from 'react-router/lib/IndexRoute';
import IndexRedirect from 'react-router/lib/IndexRedirect';

import NotFoundHandler from './components/PageNotFound';
import AppHandler from './components/AppContainer';
import UnsubscribeHandler from './components/user/Unsubscribe';
import SignupHandler from './components/user/Signup';
import LoginHandler from './components/user/Login';
import LogoutHandler from './components/Logout';
import ResetPasswordHandler from './components/user/ResetPassword';
import ForgotPasswordHandler from './components/user/ForgotPassword';
import RequireAuthHandler from './components/RequireAuth';
import OptionalAuthHandler from './components/OptionalAuth';
import DonationPage from './components/organization/Donate';
import DiagnosticsHandler from './components/Diagnostics';

import FocusedExperience from './components/FocusedExperience';
import AdaptiveExperience from './components/AdaptiveExperience';
import ManagementExperience from './components/ManagementExperience';
import CheckAgreements from './components/CheckAgreements';
import PlatformOfflineCheck from './components/PlatformOfflineCheck';
import { CollectionViewLoadingIndicator } from '@dosomegood/platform/dist/components/ui/LoadingIndicator';

const RouteLoading = () => <CollectionViewLoadingIndicator />;
const loadConfig = { fallback: <RouteLoading /> };

// prettier-ignore
export default (
  <Route name="app" path="/" component={AppHandler}>

    {/* Fullscreen dedicated post page for use in oEmbed previews of stories, used when composing a story in the platform */}
    <Route name="post" path="/embed/post/:id" component={loadable(() => import(/* webpackChunkName: "embed" */ `./components/social/SinglePostEmbed`), loadConfig)} />

    <Route component={PlatformOfflineCheck}>
      <Route component={FocusedExperience}>
        <Route name="signup" path="/signup" component={SignupHandler} />
        <Route name="login" path="/login" component={LoginHandler} />
        <Route name="logout" path="/logout" component={LogoutHandler} />

        <Route path="/registration/business/payment" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/registration/BusinessUpgrade`), loadConfig)}>
          <Route path="memberships" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/account/AvailablePackages`), loadConfig)} />
          <Route path="memberships/:packageId" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/account/AvailableTiers`), loadConfig)} />
          <IndexRedirect to="memberships" />
        </Route>

        <Route path="/user/reset-password/:token" component={ResetPasswordHandler} />
        <Route path="/user/forgot-password" component={ForgotPasswordHandler} />
        <Route path="/unsubscribe/:token" component={UnsubscribeHandler} />
        <Route path="/donate" component={DonationPage} />
      </Route>

      <Route component={AdaptiveExperience}>
        <Route name="diagnostics" path="diagnostics" component={DiagnosticsHandler} />

        <Route name="publicProfile" path="/user/profile/:hash">
          <Route component={RequireAuthHandler}>
            <Route path="edit" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/profile/Edit`), loadConfig)} />
            <Route path="add-position" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/profile/AddPosition`), loadConfig)}>
              <IndexRoute component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/profile/add-position/ChooseType`), loadConfig)} />
              <Route path="formal">
                <IndexRoute component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/profile/add-position/formal`), loadConfig)} />
                <Route path=":organizationSlug" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/profile/add-position/formal/Details`), loadConfig)} />
              </Route>
              <Route path="informal" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/profile/add-position/Informal`), loadConfig)} />
            </Route>
          </Route>

          <Route component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/Profile`), loadConfig)}>
            <Route name="userVolunteerism" path="volunteerism" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/profile/Volunteerism`), loadConfig)} />
            <Route name="userTestimonials" path="testimonials" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/profile/Testimonials`), loadConfig)} />
            <Route name="userPosts" path="posts" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/profile/Stories`), loadConfig)} />
            <Route component={RequireAuthHandler}>
              <Route path="submissions" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/profile/Submissions`), loadConfig)} />
            </Route>
            <IndexRedirect to="volunteerism" />
          </Route>

        </Route>

        <Route name="businessProfile" path="business/:slug">
          <IndexRoute component={loadable(() => import(/* webpackChunkName: "user" */ `./components/business/Profile`), loadConfig)} />
          <Route path="employee-volunteerism" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/business/EmployeeVolunteerism`), loadConfig)} />
          <Route path="organizations" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/business/SupportedOrganizations`), loadConfig)} />
          <Route path="events" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/business/Events`), loadConfig)} />
          <Route path="positions" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/business/Positions`), loadConfig)} />
          <Route path="testimonials">
            <IndexRoute component={loadable(() => import(/* webpackChunkName: "user" */ `./components/business/Testimonials`), loadConfig)} />
            <Route name="businessTestimonial" path=":testimonialId" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/business/ViewTestimonial`), loadConfig)} />
          </Route>
          <Route path="pledge" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/business/Pledge`), loadConfig)} />
          <Route path="locations" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/business/Locations`), loadConfig)} />
        </Route>

        <Route name="organizationProfile" path="organization/:slug">
          <IndexRoute component={loadable(() => import(/* webpackChunkName: "user" */ `./components/organization/Profile`), loadConfig)} />
          <Route path="employee-volunteerism" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/organization/EmployeeVolunteerism`), loadConfig)} />
          <Route path="relationships" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/organization/Relationships`), loadConfig)} />
          <Route path="events" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/organization/Events`), loadConfig)} />
          <Route path="positions" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/organization/Positions`), loadConfig)} />
          <Route path="locations" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/organization/Locations`), loadConfig)} />
        </Route>

        <Route name="opportunityProfile" path="opportunity/:slug" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/opportunities/View`), loadConfig)} />
        <Route name="eventProfile" path="event/:id" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/events/Profile`), loadConfig)} />

        <Route name="opportunitiesBrowse" path="opportunities/browse" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/opportunities/browse`), loadConfig)} />

        <Route path="community">
          <Route name="events" path="events" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/community/Events`), loadConfig)}/>
          <Route name="organizations" path="organizations" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/community/Organizations`), loadConfig)}/>
          <Route name="businesses" path="businesses" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/community/Businesses`), loadConfig)} />
          <IndexRedirect to="organizations" />
        </Route>

        <Route component={OptionalAuthHandler}>
          <Route path="/organization/:organizationSlug/leave-testimonial/:businessSlug/:token" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/business/LeaveInformalTestimonial`), loadConfig)} />
          <Route name="post" path="/post/:id" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/social/SinglePost`), loadConfig)} />
          <Route path="/post/:postId/print" printMode component={loadable(() => import(/* webpackChunkName: "user" */ `./components/social/PrintPost`), loadConfig)} />
          
          <Route path="logout-of-proxy" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/LogoutOfProxy`), loadConfig)} />
        </Route>

      </Route>

      <Route component={RequireAuthHandler}>

        <Route component={FocusedExperience}>

          <Route name="formsContext" path="/forms/business/:contextSlug" defaultSignup component={loadable(() => import(/* webpackChunkName: "forms" */ `./components/forms/common/Context`), loadConfig)}>
            <Route path=":formSlug">
              <IndexRoute name="formView" component={loadable(() => import(/* webpackChunkName: "forms" */ `./components/forms/common/View`), loadConfig)} />
              <Route path="thanks" component={loadable(() => import(/* webpackChunkName: "forms" */ `./components/forms/common/Thanks`), loadConfig)} />
            </Route>
            <Route path="*" component={NotFoundHandler} />
          </Route>

          <Route name="formsContext" path="/forms/organization/:contextSlug" defaultSignup component={loadable(() => import(/* webpackChunkName: "forms" */ `./components/forms/common/Context`), loadConfig)}>
            <Route path=":formSlug">
              <IndexRoute name="formView" component={loadable(() => import(/* webpackChunkName: "forms" */ `./components/forms/common/View`), loadConfig)} />
              <Route path="thanks" component={loadable(() => import(/* webpackChunkName: "forms" */ `./components/forms/common/Thanks`), loadConfig)} />
            </Route>
            <Route path="*" component={NotFoundHandler} />
          </Route>

          <Route path="/user/needs/city" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/UnknownCity`), loadConfig)} />
          <Route path="/user/needs/canada-us" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/CanadaUSOnly`), loadConfig)} />
          <Route path="/user/needs/verify-sso-email" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/VerifySsoEmail`), loadConfig)} />
          <Route path="/user/welcome" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/WelcomeWizard`), loadConfig)} />

          <Route name="registerBusiness" path="/registration/business/:step" defaultSignup component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/registration/Business`), loadConfig)} />
          <Route path="/registration/business/2/:slug" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/registration/BusinessVideo`), loadConfig)} />
          <Route name="businessManagement" path="/registration/business/goal/:slug" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/registration/BusinessGoal`), loadConfig)} />
          <Route path="/registration/business/3/:slug" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/registration/BusinessPledge`), loadConfig)} />

          <Route path="/registration/business/payment" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/registration/BusinessUpgrade`), loadConfig)}>
            <Route path="memberships/:packageId/:tierId/billing-info" defaultSignup component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/account/BillingInfo`), loadConfig)} />
            <Route name="businessManagement" path="thank-you/:slug/:transactionId" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/account/ThankYou`), loadConfig)} />
          </Route>

          <Redirect from="/registration/organization/1" to="/registration/organization" />
          <Route path="/registration/organization" defaultSignup component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/registration/Organization`), loadConfig)} />
          <Route name="organizationManagement" path="/registration/organization/goal/:slug" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/registration/OrganizationGoal`), loadConfig)} />

          <Route name="viewInvite" path="view-invite/:token" defaultSignup component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/ViewInvite`), loadConfig)} />
          <Route name="managePrintSubmission" path="/:contextType/:contextSlug/manage/forms/submissions/:submissionId/print" component={loadable(() => import(/* webpackChunkName: "sMgmt" */ `./components/management/forms/submissions/PrintView`), loadConfig)} />

        </Route>

        <Route component={AdaptiveExperience}>

          <Route path="welcome" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/Welcome`), loadConfig)} />

          <Route name="acceptInvite" path="accept-invite/:token" defaultSignup component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/AcceptInvite`), loadConfig)} />
          <Route name="dashboard" path="dashboard(/:feedMode)" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/Dashboard`), loadConfig)} />

          <Route path="/post/:id/edit" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/social/EditPost`), loadConfig)} />

          <Route path="/user/settings" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/settings`), loadConfig)} />
          <Route path="/user/log-hours" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/log-time`), loadConfig)}>
            <IndexRoute component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/log-time/PositionPicker`), loadConfig)} />
            <Route path="thanks" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/log-time/Thanks`), loadConfig)} />
            <Route path=":applicationSlug">
              <Route path="pick-shift" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/log-time/ShiftPicker`), loadConfig)} />
              <Route path="pick-date" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/log-time/DateLogger`), loadConfig)} />
              <Route path="shift/:shiftId/:startDate" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/log-time/ShiftLogger`), loadConfig)} />
            </Route>
          </Route>

          <Route name="userMessageInbox" path="/user/messages" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/messaging/inbox`), loadConfig)} />
          <Route name="userMessageThread" path="/user/messages/thread/:id" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/messaging/thread`), loadConfig)} />
          <Route name="userMessageThreadGroup" path="/user/messages/thread-group/:id/:signature" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/user/messaging/thread`), loadConfig)} />

          <Route name="opportunityProfileApply" path="/opportunity/:slug/apply" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/opportunities/Apply`), loadConfig)} />
          <Route name="opportunityProfileApply" path="/opportunity/:slug/apply/:applicationId/thanks" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/opportunities/ApplyThanks`), loadConfig)} />
          <Route name="opportunityProfileApply" path="/opportunity/:slug/apply/shift/:shiftId" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/opportunities/Apply`), loadConfig)} />
          <Route name="opportunityProfileApply" path="/opportunity/:slug/apply/shift/:shiftId/:applicationId/thanks" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/opportunities/ApplyThanks`), loadConfig)} />
          <Route name="opportunityProfileApply" path="/opportunity/:slug/update/shift/:shiftId" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/opportunities/UpdateAttendance`), loadConfig)} />
          <Route name="opportunityProfileApplySpecific" path="/opportunity/:slug/apply/:applicationId" component={loadable(() => import(/* webpackChunkName: "user" */ `./components/opportunities/Apply`), loadConfig)} />

          <Route path="/forms/submission/:id" component={loadable(() => import(/* webpackChunkName: "forms" */ `./components/forms/user/Submission`), loadConfig)} />

          <Route path="/business/:contextSlug/team" component={loadable(() => import(/* webpackChunkName: "team" */ `./components/business/team/index`), loadConfig)}>
            <Route path="list" component={loadable(() => import(/* webpackChunkName: "team" */ `./components/business/team/Coworkers`), loadConfig)}/>
            <Route path="activity-feed" component={loadable(() => import(/* webpackChunkName: "team" */ `./components/business/team/Feed`), loadConfig)}/>
            <Route path="dashboard" component={loadable(() => import(/* webpackChunkName: "team" */ `./components/business/team/Dashboard`), loadConfig)}/>
            <IndexRedirect to="list" />
          </Route>

          <Redirect from="/business/:slug/employees" to="/business/:slug/team/list" />
          <Redirect from="/business/:slug/dashboard/employee-dashboard" to="/business/:slug/team/dashboard" />

          <Route path="/staff">
            <IndexRoute component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/Dashboard`), loadConfig)} />
            <Route name="staffDashboardUsers" path="users" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/users`), loadConfig)} />
            <Route name="staffDashboardOrgs" path="organizations" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/orgList`), loadConfig)} />
            <Route path="broadcast" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/MessageUsers`), loadConfig)} />
            <Route path="merge-organizations" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/mergeOrgs`), loadConfig)} />
            <Route path="invite-business" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/InviteBusiness`), loadConfig)} />
            <Route path="geo-report" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/geoReport`), loadConfig)} />
            <Route path="trend-page" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/trendPage/trendPage`), loadConfig)} />
            <Route path="push" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/PushBroadcast`), loadConfig)} />
            <Route path="short-url" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/ShortUrl`), loadConfig)} />
            <Route path="custom-reports" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/CustomReports`), loadConfig)}/>
            <Route path="regen-reports" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/reports/RegenReport`), loadConfig)}/>
            <Route path="city/add" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/CityAdd`), loadConfig)}/>
            <Route path="city(/:id)" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/CityWrapper`), loadConfig)}>
              <Route path="edit" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/CityEdit`), loadConfig)}/>
              <Route path="radius" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/CityRadius`), loadConfig)}/>
            </Route>
            <Route path="business(/:slug)" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/BusinessWrapper`), loadConfig)}>
              <Route path="details" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/BusinessDetails`), loadConfig)}/>
              <Route path="payment-method" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/PaymentMethod`), loadConfig)} />
              <Route path="stats" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/BusinessStats`), loadConfig)} />
            </Route>
            <Route path="organization(/:slug)" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/OrganizationWrapper`), loadConfig)}>
              <Route path="details" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/OrganizationDetails`), loadConfig)} />
              <Route path="payment-method" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/PaymentMethod`), loadConfig)} />
            </Route>
            <Route path="reports">
              <IndexRoute component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/reports/List`), loadConfig)} />
              <Route path="editor/:id" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/reports/Editor`), loadConfig)} />
            </Route>
            <Route path="user(/:hash)">
              <Route path="details" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/UserDetails`), loadConfig)} />
            </Route>
            <Route path="media" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/media`), loadConfig)}>
              <Route path="list" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/media/List`), loadConfig)} />
              <Route path="create" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/media/Create`), loadConfig)} />
              <Route path=":id/summary" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/media/Summary`), loadConfig)} />
              <Route name="mediaSitePosts" path=":id/feed/:key" component={loadable(() => import(/* webpackChunkName: "staff" */ `./components/staff/media/Feed`), loadConfig)} />
              <IndexRedirect to="list" />
            </Route>
          </Route>

        </Route>

        <Route component={CheckAgreements}>
          <Route component={ManagementExperience}>

            <Route name="businessManagement" path="/business/:slug/manage" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/layout/Business`), loadConfig)}>

              <IndexRedirect to="home" />
              <Route path="home" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/home/IndexBusiness`), loadConfig)}>
                <Route path="dashboard" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/home/Business`), loadConfig)} />
                <Route path="account">
                  <IndexRoute component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/account`), loadConfig)} />
                  <Route path="memberships" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/account/AvailablePackages`), loadConfig)} />
                  <Route path="memberships/:packageId" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/account/AvailableTiers`), loadConfig)} />
                  <Route path="memberships/:packageId/:tierId/billing-info" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/account/BillingInfo`), loadConfig)} />
                  <Route path="thank-you/:unused/:transactionId" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/account/ThankYou`), loadConfig)} />
                </Route>
                <IndexRedirect to="dashboard" />
              </Route>

              <Route name="businessWelcome" path="welcome" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business`), loadConfig)}>
                <IndexRedirect to="profile" />
                <Route path="overview" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/StepOverview`), loadConfig)} />
                <Route path="profile" masterStep="profile" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/stepset/PageTemplate`), loadConfig)}>
                  <Route path="pledge" subStep="pledge" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/Pledge`), loadConfig)} />
                  <Route path="logo" subStep="logo" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/Logo`), loadConfig)} />
                  <Route path="causes" subStep="causes" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/Causes`), loadConfig)} />
                  <Route path="about" subStep="about" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/About`), loadConfig)} />
                  <Route path="about-all" subStep="aboutAll" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/AboutCombined`), loadConfig)} />
                  <Route path="connection" subStep="addConnection" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/AddConnection`), loadConfig)} />
                  <Route path="story" subStep="postStory" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/AutoPostStory`), loadConfig)} />
                  <Route path="*" component={NotFoundHandler} />
                  <IndexRedirect to="pledge" />
                </Route>
                <Route path="collective" masterStep="collective" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/stepset/PageTemplate`), loadConfig)}>
                  <Route path="contributions" subStep="contributions" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/Contributions`), loadConfig)} />
                  <IndexRedirect to="contributions" />
                </Route>
                <Route path="email-signatures" masterStep="email-signatures" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/stepset/PageTemplate`), loadConfig)}>
                  <Route path="add" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/EmailSignatures`), loadConfig)} />
                  <IndexRedirect to="add" />
                </Route>
                <Route path="career-postings" masterStep="career-postings" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/stepset/PageTemplate`), loadConfig)}>
                  <Route path="add" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/CareerPostings`), loadConfig)} />
                  <IndexRedirect to="add" />
                </Route>
                <Route path="share" masterStep="share" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/stepset/PageTemplate`), loadConfig)}>
                  <Route path="profile" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/ShareProfile`), loadConfig)} />
                  <IndexRedirect to="profile" />
                </Route>
                <Route path="business-type" masterStep="business-type" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/stepset/PageTemplate`), loadConfig)}>
                  <Route path="choose" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/onboarding/business/steps/BusinessType`), loadConfig)} />
                  <IndexRedirect to="choose" />
                </Route>
              </Route>

              <Route path="social" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/social`), loadConfig)}>
                <Route path="feed" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/social/Feed`), loadConfig)} />
                <Route path="marketing" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/marketing/Resources`), loadConfig)} />
                <Route name="businessDashboardApproveContent" path="approval" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/approvalqueues/ApproveContent`), loadConfig)} />
                <IndexRedirect to={'feed'} />
              </Route>

              <Route path="volunteerism" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/opportunities`), loadConfig)}>
                <Route path="positions">
                  <Route path="list" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/opportunities/List`), loadConfig)} />
                  <Route path="create(/:eventId)" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/opportunities/Create`), loadConfig)} />
                  <Route path=":positionId">
                    <Route path="add-beneficiary" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/opportunities/AddBeneficiary`), loadConfig)} />
                    <Route path="summary" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/opportunities/Summary`), loadConfig)} />
                    <Route path="edit" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/opportunities/Edit`), loadConfig)} />
                    <Route path="shift">
                      <Route path="create" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/opportunities/CreateShift`), loadConfig)} />
                      <Route path=":shiftId">
                        <Route path="summary" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/opportunities/ShiftSummary`), loadConfig)} />
                        <Route path="edit" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/opportunities/ShiftEdit`), loadConfig)} />
                        <IndexRedirect to={'summary'} />
                      </Route>
                    </Route>
                    <IndexRedirect to={'summary'} />
                  </Route>
                  <IndexRedirect to={'list'} />
                </Route>
                <Route path="events">
                  <Route path="list" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/events/List`), loadConfig)} />
                  <Route path="create" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/events/Create`), loadConfig)} />
                  <Route path=":eventId">
                    <Route path="add-supporter" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/events/AddSupporter`), loadConfig)} />
                    <Route path="add-beneficiary" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/events/AddBeneficiary`), loadConfig)} />
                    <Route path="summary" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/events/Summary`), loadConfig)} />
                    <Route path="edit" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/events/Edit`), loadConfig)} />
                    <IndexRedirect to={'summary'} />
                  </Route>
                  <IndexRedirect to={'list'} />
                </Route>
                
                <Route path="approve-applicants" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/approvalqueues/ApproveApplicants`), loadConfig)} />
                <Route name="businessDashboardApproveHours" path="approve-hours" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/approvalqueues/ApproveOrganizationOrBusinessHours`), loadConfig)}/>

                <Route path="reports(/:reportId)" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/reports/business/Volunteerism`), loadConfig)} />

                <IndexRedirect to={'positions'} />

              </Route>

              <Route path="invite" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/invites`), loadConfig)}>
                <Route path="organizations" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/invites/Organizations`), loadConfig)} />
                <Route path="businesses" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/invites/Businesses`), loadConfig)} />
                <Route path="employees" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/invites/Employees`), loadConfig)} />
                <Route path="admins" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/invites/Admins`), loadConfig)} />
                <Route path="members" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/invites/Members`), loadConfig)} />
                <IndexRedirect to={'organizations'} />
              </Route>

              <Route path="employees" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/employees`), loadConfig)}>
                <Route path="list" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/employees/List`), loadConfig)} />
                <Route path="roles" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/employees/roles`), loadConfig)} >
                  <Route path="list" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/employees/roles/List`), loadConfig)} />
                  <Route path="create" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/employees/roles/Create`), loadConfig)} />
                  <Route path=":roleId">
                    <Route path="summary" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/employees/roles/Summary`), loadConfig)} />
                    <Route path="edit" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/employees/roles/Edit`), loadConfig)} />
                    <IndexRedirect to={'summary'} />
                  </Route>
                  <IndexRedirect to="list" />
                </Route>
                <Route path="dashboard" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/employees/dashboard/BusinessTeamDashboard`), loadConfig)} />
                <Route path="invite/employees" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/invites/Employees`), loadConfig)} />
                <Route path="invite/admins" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/invites/Admins`), loadConfig)} />
                <Route path="approval" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/approvalqueues/ApproveTeam`), loadConfig)} />
                <Route path="reports(/:reportId)" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/reports/business/Team`), loadConfig)} />
                <Route path=":relationshipId">
                  <Route path="summary" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/employees/Summary`), loadConfig)} />
                  <IndexRedirect to={'summary'} />
                </Route>
                <IndexRedirect to={'list'} />
              </Route>

              <Route path="members" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/members`), loadConfig)}>
                <Route path="list" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/members/List`), loadConfig)} />
                <Route name="businessDashboardMembersSummary" path="dashboard" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/members/dashboard/MemberDashboard`), loadConfig)} />
                <Route path="invite" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/invites/Members`), loadConfig)} />
                <IndexRedirect to={'list'} />
              </Route>

              <Route path="relationships">
                <Route path="organizations" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/relationships/business/IndexOrganizations`), loadConfig)}>
                  <Route path="testimonials" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/relationships/business/Testimonials`), loadConfig)} />
                  <Route path="list" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/relationships/business/Organizations`), loadConfig)} />
                  <Route path="add" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/relationships/business/AddOrganization`), loadConfig)} />
                  <Route path="invite" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/invites/Organizations`), loadConfig)} />
                  <Route path="reports(/:reportId)" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/reports/business/Community`), loadConfig)} />
                  <Route path=":id/:direction" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/relationships/business/organizations/Details`), loadConfig)} />
                  <IndexRedirect to={'list'} />
                </Route>
                <Route path="businesses" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/IndexBusinesses'), loadConfig)}>
                  <Route path="testimonials" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/businesses/Testimonials'), loadConfig)} />
                  <Route path="widgets">
                    <IndexRoute component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/businesses/Widgets'), loadConfig)} />
                    <Route component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/businesses/widgets/index'), loadConfig)}>
                      <Route path="news" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/businesses/widgets/News'), loadConfig)} />
                      <Route path="orgs-supported" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/businesses/widgets/Organizations'), loadConfig)} />
                      <Route path="member-logos" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/businesses/widgets/Businesses'), loadConfig)} />
                      <Route path="testimonials" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/businesses/widgets/Testimonials'), loadConfig)} />
                      <Route path="impact" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/businesses/widgets/Ticker'), loadConfig)} />
                    </Route>
                  </Route>
                  <Route path="news" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/businesses/News'), loadConfig)} />
                  <Route path="list"  component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/businesses/List'), loadConfig)} />
                  <Route path="add" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ './components/management/relationships/business/AddBusiness'), loadConfig)} />
                  <Route path="invite" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/invites/Businesses`), loadConfig)} />
                  <Route path="reports(/:reportId)" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/reports/business/Business`), loadConfig)} />
                  <IndexRedirect to={'list'} />
                </Route>
                
                <Redirect from="affiliates" to="organizations" />
                <Redirect from="testimonials" to="organizations/testimonials" />
                <Redirect from=":id" to="organizations/:id/outbound" />
                <IndexRedirect to={'organizations'} />
              </Route>

              <Route path="profile" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/profile`), loadConfig)}>
                <Route path="edit" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/profile/business/Profile`), loadConfig)} />
                <Route path="addresses" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/profile/Addresses`), loadConfig)} />
                <Route path="getlisted" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/profile/BusinessGetListed`), loadConfig)} />
                <Route path="settings" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/profile/business/Settings`), loadConfig)} />
                <IndexRedirect to={'edit'} />
              </Route>

              <Route path="pages" component={loadable(() => import(/* webpackChunkName: "bMgmtPages" */ `./components/management/pages`), loadConfig)}>
                <Route path="list" component={loadable(() => import(/* webpackChunkName: "bMgmtPages" */ `./components/management/pages/List`), loadConfig)} />
                <Route path="reports" component={() => false} />
                <Route path="create" component={loadable(() => import(/* webpackChunkName: "bMgmtPages" */ `./components/management/pages/Create`), loadConfig)} />
                <Route path=":pageIndexSlug">
                  <Route path=":pageSlug">
                    <Route path="builder" component={loadable(() => import(/* webpackChunkName: "bMgmtPages" */ `./components/management/pages/builder`), loadConfig)} />
                    <Route path="preview" component={loadable(() => import(/* webpackChunkName: "bMgmtPages" */ `./components/management/pages/Preview`), loadConfig)} />
                  </Route>
                </Route>
                <IndexRedirect to={'list'} />
              </Route>

              <Route path="forms" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms`), loadConfig)}>
                <Route path="list" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/forms/List`), loadConfig)} />
                <Route path="create(/:parentId)" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/forms/Create`), loadConfig)} />

                <Route path="reports(/:reportId)" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/reports/business/Forms`), loadConfig)} />

                <Route path="submissions">
                  <Route path="list" >
                    <IndexRoute component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/submissions/List`), loadConfig)} />
                    <Route path="custom-views">
                      <IndexRoute component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/submissions/ListCustomViews`), loadConfig)} />
                      <Route path="manage" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/submissions/ManageViews`), loadConfig)} />
                      <Route path="create/template" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/submissions/CreateView`), loadConfig)} />
                      <Route path=":view" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/submissions/ListCustomViews`), loadConfig)} />
                    </Route>
                  </Route>
                  <Route path=":submissionId">
                    <Route path="summary" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/submissions/Summary`), loadConfig)} />
                    <Route path="create-story" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/submissions/CreatePost`), loadConfig)} />
                    <Route path="post/:postId" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/submissions/SharePost`), loadConfig)} />
                    <IndexRedirect to={"summary"} />
                  </Route>
                  <IndexRedirect to={'list'} />
                </Route>

                <Route path="owner-groups">
                  <Route path="list" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/ownerGroups/List`), loadConfig)} />
                  <Route path="create" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/ownerGroups/Create`), loadConfig)} />

                  <Route path=":ownerGroupId">
                    <Route path="edit" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/ownerGroups/Edit`), loadConfig)} />
                    <Route path="summary" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/ownerGroups/Summary`), loadConfig)} />
                  </Route>
                  <IndexRedirect to={'list'} />
                </Route>

                <Route path=":formSlug">
                  <Route path="builder" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/builder`), loadConfig)} />
                  <Route path="edit" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/forms/Edit`), loadConfig)} />
                  <Route path="summary" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/forms/forms/Summary`), loadConfig)} />
                </Route>
            
                <IndexRedirect to={'list'} />
              </Route>

              <Route path="widgets" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/marketing/widgets`), loadConfig)}>
                <Route path="ticker" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/marketing/widgets/Ticker`), loadConfig)} />
                <Route path="causes" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/marketing/widgets/Causes`), loadConfig)} />
                <Route path="testimonials" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/marketing/widgets/Testimonials`), loadConfig)} />
                <Route path="organizations" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/marketing/widgets/Organizations`), loadConfig)} />
                <Route path="news" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/marketing/widgets/News`), loadConfig)} />
                <Route path="events" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/marketing/widgets/Events`), loadConfig)} />
                <Route path="positions" component={loadable(() => import(/* webpackChunkName: "bMgmt" */ `./components/management/marketing/widgets/Positions`), loadConfig)} />
                <IndexRedirect to={'news'} />
              </Route>

              <Redirect from="marketing(/*)" to="social/marketing" />

              <Route path="*" component={NotFoundHandler} />
            </Route>

            <Route name="organizationManagement" path="/organization/:slug/manage" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/layout/Organization`), loadConfig)}>

              <IndexRoute component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/home/Organization`), loadConfig)} />

              <Route name="organizationWelcome" path="welcome" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/onboarding/organization`), loadConfig)}>
                <IndexRedirect to="profile" />
                <Route path="overview" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/onboarding/organization/StepOverview`), loadConfig)} />
                <Route path="profile" masterStep="profile" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/onboarding/organization/stepset/PageTemplate`), loadConfig)}>
                  <Route path="registered-id" subStep="charityId" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/onboarding/organization/steps/RegisteredId`), loadConfig)} />
                  <Route path="logo" subStep="logo" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/onboarding/organization/steps/Logo`), loadConfig)} />
                  <Route path="about" subStep="about" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/onboarding/organization/steps/AboutCombined`), loadConfig)} />
                  <Route path="story" subStep="story" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/onboarding/organization/steps/AutoPostStory`), loadConfig)} />
                  <Route path="connections" subStep="connections" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/onboarding/organization/steps/AddConnection`), loadConfig)} />
                  <Route path="testimonials" subStep="testimonials" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/onboarding/organization/steps/Testimonials`), loadConfig)} />
                  <Route path="*" component={NotFoundHandler} />
                  <IndexRedirect to="logo" />
                </Route>
              </Route>

              <Route path="social" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/social`), loadConfig)}>
                <Route path="feed" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/social/Feed`), loadConfig)} />
                <Route name="organizationDashboardApproveContent" path="approval" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/approvalqueues/ApproveContent`), loadConfig)} />
                <IndexRedirect to={'feed'} />
              </Route>

              <Route path="volunteerism" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/opportunities`), loadConfig)}>
                <Route path="positions">
                  <Route path="list" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/opportunities/List`), loadConfig)} />
                  <Route path="create(/:eventId)" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/opportunities/Create`), loadConfig)} />
                  <Route path=":positionId">
                    <Route path="add-beneficiary" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/opportunities/AddBeneficiary`), loadConfig)} />
                    <Route path="summary" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/opportunities/Summary`), loadConfig)} />
                    <Route path="edit" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/opportunities/Edit`), loadConfig)} />
                    <Route path="shift">
                      <Route path="create" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/opportunities/CreateShift`), loadConfig)} />
                      <Route path=":shiftId">
                        <Route path="summary" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/opportunities/ShiftSummary`), loadConfig)} />
                        <Route path="edit" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/opportunities/ShiftEdit`), loadConfig)} />
                        <IndexRedirect to={'summary'} />
                      </Route>
                    </Route>
                    <IndexRedirect to={'summary'} />
                  </Route>
                  <IndexRedirect to={'list'} />
                </Route>
                <Route path="events">
                  <Route path="list" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/events/List`), loadConfig)} />
                  <Route path="create" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/events/Create`), loadConfig)} />
                  <Route path=":eventId">
                    <Route path="add-supporter" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/events/AddSupporter`), loadConfig)} />
                    <Route path="add-beneficiary" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/events/AddBeneficiary`), loadConfig)} />
                    <Route path="summary" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/events/Summary`), loadConfig)} />
                    <Route path="edit" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/events/Edit`), loadConfig)} />
                    <IndexRedirect to={'summary'} />
                  </Route>
                  <IndexRedirect to={'list'} />
                </Route>

                <Route name="organizationDashboardApproveHours" path="approve-hours" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/approvalqueues/ApproveOrganizationOrBusinessHours`), loadConfig)} />
                <Route path="approve-applicants" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/approvalqueues/ApproveApplicants`), loadConfig)} />
                <Route path="reports(/:reportId)" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/reports/organization/Volunteerism`), loadConfig)} />

                <IndexRedirect to={'positions'} />
              </Route>

              <Route path="invite" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/invites`), loadConfig)}>
                <Route path="employees" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/invites/Employees`), loadConfig)} />
                <Route path="admins" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/invites/Admins`), loadConfig)} />
                <Route path="organizations" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/invites/Organizations`), loadConfig)} />
                <Route path="businesses" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/invites/Businesses`), loadConfig)} />
                <IndexRedirect to={'volunteers'} />
              </Route>

              <Route path="employees" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/employees`), loadConfig)}>
                <Route path="list" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/employees/List`), loadConfig)} />
                <Route path="roles" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/employees/roles`), loadConfig)} >
                  <Route path="list" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/employees/roles/List`), loadConfig)} />
                  <Route path="create" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/employees/roles/Create`), loadConfig)} />
                  <Route path=":roleId">
                    <Route path="summary" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/employees/roles/Summary`), loadConfig)} />
                    <Route path="edit" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/employees/roles/Edit`), loadConfig)} />
                    <IndexRedirect to={'summary'} />
                  </Route>
                  <IndexRedirect to="list" />
                </Route>
                <Route path="invite/employees" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/invites/Employees`), loadConfig)} />
                <Route path="invite/admins" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/invites/Admins`), loadConfig)} />
                <Route path="reports(/:reportId)" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/reports/business/Team`), loadConfig)} />
                <Route path=":relationshipId">
                  <Route path="summary" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/employees/Summary`), loadConfig)} />
                  <IndexRedirect to={'summary'} />
                </Route>
                <IndexRedirect to={'list'} />
              </Route>

              <Route path="volunteers" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/volunteers`), loadConfig)}>
                <Route path="list" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/volunteers/List`), loadConfig)} />
                <Route path="recruit" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/volunteers/FindVolunteers`), loadConfig)} />
                <Route name="organizationDashboardRecognize" path="recognize" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/approvalqueues/ApproveEndorsementRequests`), loadConfig)} />
                <Route path="invite" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/invites/Contributors`), loadConfig)} />

                <Route path="dashboard" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/volunteers/dashboard/OrganizationDashboard`), loadConfig)}>
                  <Route path="volunteers" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/volunteers/dashboard/Volunteers`), loadConfig)} />
                  <IndexRedirect to={'volunteers'} />
                </Route>

                <IndexRedirect to={'list'} />
              </Route>

              <Route path="relationships">
                <Route path="businesses" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/organization/IndexBusinesses`), loadConfig)}>
                  <Route path="testimonials" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/organization/Testimonials`), loadConfig)} />
                  <Route path="testimonial-requests" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/organization/TestimonialRequests`), loadConfig)} />
                  <Route path="list" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/organization/Businesses`), loadConfig)} />
                  <Route path="add" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/organization/AddBusiness`), loadConfig)} />
                  <Route path="invite" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/invites/Businesses`), loadConfig)} />
                  <IndexRedirect to={'list'} />
                </Route>
                <Route path="organizations" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/organization/IndexOrganizations`), loadConfig)}>
                  <Route path="list" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/organization/Organizations`), loadConfig)} />
                  <Route path="add" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/organization/AddOrganization`), loadConfig)} />
                  <IndexRedirect to={'list'} />
                </Route>
                <Route path=":id" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/Details`), loadConfig)} />
                <Redirect from="testimonials" to="businesses/testimonials" />
                <Redirect from="affiliates" to="businesses" />
                <IndexRedirect to={'businesses'} />
              </Route>

              <Route path="profile" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/profile`), loadConfig)}>
                <Route path="edit" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/profile/Organization`), loadConfig)} />
                <Route path="addresses" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/profile/Addresses`), loadConfig)} />

                <Route path="relationships">
                  <Route path="organizations" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/organization/IndexOrganizations`), loadConfig)}>
                    <Route path="list" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/organization/Organizations`), loadConfig)} />
                    <Route path="add" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/organization/AddOrganization`), loadConfig)} />
                    <IndexRedirect to={'list'} />
                  </Route>
                  <Route path=":id" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/relationships/Details`), loadConfig)} />
                  <IndexRedirect to={'organizations'} />
                </Route>

                <IndexRedirect to={'edit'} />
              </Route>

              <Route path="pages" component={loadable(() => import(/* webpackChunkName: "oMgmtPages" */ `./components/management/pages`), loadConfig)}>
                <Route path="list" component={loadable(() => import(/* webpackChunkName: "oMgmtPages" */ `./components/management/pages/List`), loadConfig)} />
                <Route path="reports" component={() => false} />
                <Route path="create" component={loadable(() => import(/* webpackChunkName: "oMgmtPages" */ `./components/management/pages/Create`), loadConfig)} />
                <Route path=":pageIndexSlug">
                  <Route path=":pageSlug">
                    <Route path="builder" component={loadable(() => import(/* webpackChunkName: "oMgmtPages" */ `./components/management/pages/builder`), loadConfig)} />
                    <Route path="preview" component={loadable(() => import(/* webpackChunkName: "oMgmtPages" */ `./components/management/pages/Preview`), loadConfig)} />
                  </Route>
                </Route>
                <IndexRedirect to={'list'} />
              </Route>

              <Route path="forms" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms`), loadConfig)}>
                <Route path="list" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/forms/List`), loadConfig)} />
                <Route path="create(/:parentId)" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/forms/Create`), loadConfig)} />

                <Route path="reports(/:reportId)" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/reports/organization/Forms`), loadConfig)} />

                <Route path="submissions">
                  <Route path="list" >
                    <IndexRoute component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/submissions/List`), loadConfig)} />
                    <Route path="custom-views">
                      <IndexRoute component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/submissions/ListCustomViews`), loadConfig)} />
                      <Route path="manage" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/submissions/ManageViews`), loadConfig)} />
                      <Route path="create/template" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/submissions/CreateView`), loadConfig)} />
                      <Route path=":view" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/submissions/ListCustomViews`), loadConfig)} />
                    </Route>
                  </Route>
                  <Route path=":submissionId">
                    <Route path="summary" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/submissions/Summary`), loadConfig)} />
                    <Route path="create-story" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/submissions/CreatePost`), loadConfig)} />
                    <Route path="post/:postId" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/submissions/SharePost`), loadConfig)} />
                    <IndexRedirect to={"summary"} />
                  </Route>
                  <IndexRedirect to={'list'} />
                </Route>

                <Route path=":formSlug">
                  <Route path="builder" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/builder`), loadConfig)} />
                  <Route path="edit" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/forms/Edit`), loadConfig)} />
                  <Route path="summary" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/forms/forms/Summary`), loadConfig)} />
                </Route>

                <IndexRedirect to={'list'} />
              </Route>

              <Route path="widgets" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/marketing/widgets`), loadConfig)}>
                <Route path="businesses" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/marketing/widgets/Businesses`), loadConfig)} />
                <Route path="news" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/marketing/widgets/News`), loadConfig)} />
                <Route path="events" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/marketing/widgets/Events`), loadConfig)} />
                <Route path="positions" component={loadable(() => import(/* webpackChunkName: "oMgmt" */ `./components/management/marketing/widgets/Positions`), loadConfig)} />
                <IndexRedirect to={'news'} />
              </Route>

              <Route path="*" component={NotFoundHandler} />
            </Route>

          </Route>
        </Route>

        <IndexRedirect to="/dashboard" />

      </Route>
    </Route>

    <IndexRedirect to="/dashboard" />
    <Route path="*" component={NotFoundHandler} />

  </Route>
);
