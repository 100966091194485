import React, { PureComponent } from 'react';

import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import { SecondaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import { withTranslation } from 'react-i18next';
import CheckBox from '@dosomegood/platform/dist/components/inputs/CheckBox';
import { Form, FormControl } from '@dosomegood/platform/dist/components/inputs/Form';
import { ModalContainer, ModalFooterActions } from '../ui/ModalComponents';

class ConfirmModal extends PureComponent {
  state = {
    extraValue: false,
  };

  handleCallback = () => {
    if (this.props.onCancel) this.props.onCancel(); // Close
    if (this.props.options.onConfirm) this.props.options.onConfirm();
  };

  handleCancel = () => {
    if (this.props.options.onCancel) this.props.options.onCancel();
    if (this.props.onCancel) this.props.onCancel();
  };

  render() {
    const { extraValue } = this.state;
    const { t } = this.props;
    const {
      title,
      message,
      extraCheck,
      cancelCaption = t('commonPlat:actions.cancel'),
      confirmCaption = t('commonPlat:actions.confirm'),
      hideCancel = false,
      danger = false,
    } = this.props.options || {};
    return (
      <ModalContainer>
        <SecondaryHeader>{title}</SecondaryHeader>
        <Paragraph>{message}</Paragraph>
        {!!extraCheck && (
          <Form id="extra">
            <FormControl label={extraCheck}>
              <CheckBox
                id="extraCheck"
                onChange={(extraValue) => this.setState({ extraValue })}
                required
              />
            </FormControl>
          </Form>
        )}
        <ModalFooterActions>
          {!hideCancel && (
            <Buttons.Alternate onClick={this.handleCancel}>{cancelCaption}</Buttons.Alternate>
          )}
          <Buttons.Primary
            disabled={extraCheck && !extraValue}
            danger={danger}
            onClick={this.handleCallback}
          >
            {confirmCaption}
          </Buttons.Primary>
        </ModalFooterActions>
      </ModalContainer>
    );
  }
}

export default withTranslation(['commonPlat'])(ConfirmModal);
