import config from '../../../config';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import AppActions from '../../actions/app';
import AppStore from '../../stores/app';
import InviteStore from '../../stores/invite';
import BusinessStore from '../../stores/business';
import OrganizationStore from '../../stores/organization';

import AccountSection from '@dosomegood/platform/dist/components/content/onboarding/AccountSection';
import WelcomeInfoSection from '@dosomegood/platform/dist/components/content/onboarding/WelcomeInfoSection';
import DoubleWide from '@dosomegood/platform/dist/components/content/onboarding/DoubleWide';
import { api as API } from '../../mixins/api';
import LocalStorage from '../../mixins/LocalStorage';
import Auth from '../../utils/Auth';
import {
  getNextLocation,
  getOnboardingType,
  getOnboardingDirect,
  getOnboardingReferral,
} from '../../utils/LocationState';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import Link from '@dosomegood/platform/dist/components/buttons/Link';
import { buildReturnPath } from '@dosomegood/platform/dist/utils/URLFormatters';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import { emailRegex } from '@dosomegood/platform/dist/utils/RegexPatterns';
import { getSocialError, handleSocialConnect } from 'utils/SocialConnect';

class Signup extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    lastEmail: null,
    ssoRedirectUrl: null,
    enforceSso: false,
  };

  componentDidMount() {
    const { t } = this.props;
    AppActions.changePageTitle(t('commonPlat:actions.signUp'));
  }

  handleChangeRegistration = ({ email }) => {
    if (email && this.state.lastEmail !== email && emailRegex.test(email)) {
      this.setState({ lastEmail: email });
      clearTimeout(this._emailCheckTimer);
      this._emailCheckTimer = setTimeout(() => {
        API.makeRequest(
          `/sso/tenants?email=${encodeURIComponent(email)}`,
          'GET',
          {},
          (err, res) => {
            if (!err && res && res.body) {
              let t = res.body.tenants || [];
              if (!t.length || res.body.usePasswordLogin) {
                return this.setState({ ssoRedirectUrl: null, enforceSso: false });
              }
              const sso = t[0];
              const { invite } = InviteStore.getState();
              const { location } = this.props;
              const next = getNextLocation(invite, location, 'signup');
              const returnPath = buildReturnPath(next.pathname, next.query);
              this.setState({
                ssoRedirectUrl: `${config.auth.host}/redirect/${
                  sso.type
                }?email=${encodeURIComponent(email)}&returnPath=${returnPath}`,
                enforceSso: sso.enforceSso,
              });
              return;
            }
            // Reset to nothing if we previously had a result and no longer do
            if (this.state.ssoRedirectUrl) {
              this.setState({ ssoRedirectUrl: null, enforceSso: false });
            }
          },
        );
      }, 500);
    } else {
      // Unset any previously found redirect url
      if (this.state.ssoRedirectUrl) {
        this.setState({ ssoRedirectUrl: null, enforceSso: false });
      }
    }
  };

  handleRegister = (
    { firstName, lastName, email, password, confirmPassword },
    handleServerResponse,
  ) => {
    const { i18n, t } = this.props;
    const { defaultLocation = {} } = AppStore.getState();
    let mutLocation = { ...defaultLocation };
    if (!mutLocation.city) delete mutLocation.city;
    if (!mutLocation.region) delete mutLocation.region;
    if (!mutLocation.country) delete mutLocation.country;

    if (password !== confirmPassword) {
      return handleServerResponse(() => true)(null, {
        body: { errors: [{ password: t('common:errors.passwordsDontMatch') }] },
      });
    }

    API.makeRequest(
      '/user',
      'POST',
      {
        firstName,
        lastName,
        email,
        password,
        locale: i18n?.language || i18n?.resolvedLanguage,
        ...mutLocation,
      },
      handleServerResponse((response) => {
        if (response) {
          Auth.login(email, password, (loggedIn, accessToken) => {
            if (loggedIn) {
              LocalStorage.setItem('registration.method', 'email');
              LocalStorage.setItem('registration.email', email);
              // Setting these values for instant login effect without reload
              API.activeAccessToken = accessToken;
              API.accessToken = accessToken;
              if (response.user) {
                AppActions.login(response.user);
                // Provide enough time for stores to update
                setTimeout(() => {
                  this.props.router.push(
                    getNextLocation(InviteStore.getState().invite, this.props.location, 'signup'),
                  );
                }, 10);
              } else {
                alert(`There was an error fetching your profile, reload and try again.`);
              }
            }
          });
        }
      }),
    );
  };

  render() {
    const { location, t } = this.props;
    const { ssoRedirectUrl, enforceSso } = this.state;
    const { error } = location.query;
    const { user } = AppStore.getState();
    const { invite } = InviteStore.getState();
    const { business } = BusinessStore.getState();
    const { organization } = OrganizationStore.getState();

    const context =
      business && business.slug
        ? { ...business, type: 'business' }
        : { ...organization, type: 'organization' };

    const type = getOnboardingType(invite, location);
    const direct = getOnboardingDirect(location, context);
    const referral = getOnboardingReferral(location);
    const socialError = getSocialError(error);

    return (
      <DoubleWide>
        {user ? (
          <div style={{ textAlign: 'center' }}>
            <Paragraph>
              {t('common:alerts.alreadyLoggedInAsName', {
                name: `${user.firstName} ${user.lastName}`,
              })}
            </Paragraph>
            <Buttons.Primary to={`/dashboard`}>{t('common:actions.goToNewsFeed')}</Buttons.Primary>
            <br />
            <Buttons.Alternate to={`/logout`}>{t('commonPlat:actions.logout')}</Buttons.Alternate>
          </div>
        ) : (
          <>
            <WelcomeInfoSection type={type} invite={invite} direct={direct} referral={referral} />
            <AccountSection
              socialError={socialError}
              token={location.query.token}
              state={location.state}
              nextLocation={getNextLocation(invite, location, 'signup')}
              onEmailRegister={this.handleRegister}
              onSocialConnect={handleSocialConnect}
              onChangeRegister={this.handleChangeRegistration}
              onContinueSSO={() => (window.location = ssoRedirectUrl)}
              ssoNotice={
                !!ssoRedirectUrl && (
                  <div style={{ margin: 5.5 }}>
                    <Link onClick={() => (window.location = ssoRedirectUrl)}>
                      {enforceSso ? (
                        <>{t('common:labels.thisEmailRequiresYouToContinueWithSSO')} </>
                      ) : (
                        <>{t('common:labels.thisEmailAllowsSSO')} </>
                      )}
                      <Icon name="chevron-right" />
                    </Link>
                  </div>
                )
              }
              enforceSso={enforceSso}
              hidePasswordField
              showApple
              create
            />
          </>
        )}
      </DoubleWide>
    );
  }
}

export default withTranslation(['commonPlat', 'common'])(Signup);
