import config from '../config';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18nConfig = {
  debug: false,
  load: 'languageOnly',
  fallbackLng: 'en',
  defaultNS: 'commonPlat',
  nonExplicitSupportedLngs: true,
  //lng: 'en', // Forces language

  interpolation: {
    escapeValue: false, // react already safes from xss
  },

  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
  react: {
    useSuspense: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'small', 'p', 'em', 'u'],
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi);

// Force the language to the one in the config
if (config.locale && config.locale !== 'auto') {
  i18nConfig.lng = config.locale.split('-')[0];

  // Or use autodetect
} else if (config.locale === 'auto') {
  i18nConfig.detection = {
    order: ['querystring', 'cookie', 'navigator'],

    lookupQuerystring: 'locale',
    lookupCookie: 'locale',
  };
  i18n.use(LanguageDetector);
}

i18n.init(i18nConfig);

export default i18n;
