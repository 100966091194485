const verifyAccountNeeds = (user, router, allowNavigate = true) => {
  if (user) {
    // Make sure they have a city set
    if (!user.city) {
      if (allowNavigate)
        router.replace({ pathname: '/user/needs/city', state: { from: router.location } });
      return false;
    }
    // Make sure they're in Canada
    if (user.city && !['CA', 'US'].includes(user.country)) {
      if (allowNavigate)
        router.replace({ pathname: '/user/needs/canada-us', state: { from: router.location } });
      return false;
    }
    if (user.verifiedSsoEmail === false) {
      if (allowNavigate) {
        router.replace({
          pathname: '/user/needs/verify-sso-email',
          state: { from: router.location },
        });
      }
      return false;
    }
    return true;
  }
  if (allowNavigate) router.replace({ pathname: '/signup', state: { from: router.location } });
  return false;
};

// For businesses
const getListingStatus = (loading, profile = {}, features = {}, t) => {
  const steps = !loading
    ? [
        {
          caption: t('bMgmt:labels.addLogo'),
          completed: typeof profile.logo === 'object' ? !!profile.logo.previewUrl : !!profile.logo,
        },
        {
          caption: t('bMgmt:labels.addCausesCaredAbout'),
          completed: profile.causes && !!profile.causes.length,
        },
        {
          caption: t('bMgmt:labels.fillAboutUs'),
          completed: !!profile.about,
        },
      ]
    : [];

  const needUpgrade = !features.listed;
  const isListed = !steps.map((s) => s.completed).filter((s) => !s).length;
  const isUnlisted = !isListed;

  return { steps, needUpgrade, isListed, isUnlisted };
};

export { verifyAccountNeeds, getListingStatus };
