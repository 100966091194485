import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import FocusedExperience from 'components/FocusedExperience';
import Link from '@dosomegood/platform/dist/components/buttons/Link';
import { usePageTitle } from '../utils/Hooks';

export default function PageNotFound() {
  const { t } = useTranslation(['common']);
  usePageTitle(t('common:labels.pageNotFound'));

  return (
    <FocusedExperience>
      <PrimaryHeader>{t('common:labels.pageNotFound')}</PrimaryHeader>
      <Paragraph>{t('common:labels.pageNotFoundDescription')}</Paragraph>
      <Link to={{ pathname: `/dashboard` }}>{t('common:actions.backToFeed')}</Link>
    </FocusedExperience>
  );
}
