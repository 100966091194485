import React from 'react';

import { SecondaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import { Form, FormControl, FormActions } from '@dosomegood/platform/dist/components/inputs/Form';
import EmailInput from '@dosomegood/platform/dist/components/inputs/EmailInput';
import { useTranslation } from 'react-i18next';
import { ModalContainer } from '../ui/ModalComponents';

export default function SSOLoginModal(props) {
  const { t } = useTranslation(['commonPlat', 'common']);
  const { options: { onSubmit } = {}, onCancel } = props;

  return (
    <ModalContainer>
      <SecondaryHeader>{t('common:labels.connectWithSSO')}</SecondaryHeader>
      <Paragraph>
        {t('common:labels.connectWithSSODescription')}{' '}
        <strong>{t('common:labels.connectWithSSONote')}</strong>
      </Paragraph>
      <Form onSubmit={onSubmit}>
        <FormControl label={t('common:labels.workEmail')}>
          <EmailInput id={'email'} required />
        </FormControl>
        <FormActions>
          {onCancel && (
            <Buttons.Alternate onClick={onCancel}>
              {t('commonPlat:actions.cancel')}
            </Buttons.Alternate>
          )}
          <Buttons.Primary type={'submit'}>{t('commonPlat:actions.next')}</Buttons.Primary>
        </FormActions>
      </Form>
    </ModalContainer>
  );
}
