import React, { forwardRef, useRef } from 'react';
import styled from 'styled-components';
import { browserHistory } from 'react-router';

import { api as API } from 'mixins/api';

import ConnectedSingleSelect from '@dosomegood/platform/dist/components/inputs/connected/Select';
import SingleSelect from '@dosomegood/platform/dist/components/inputs/SingleSelect';
import SimpleNameChip from '@dosomegood/platform/dist/components/inputs/select/chips/SimpleName';
import {
  CollectionItemDetail,
  CollectionItemHeader,
} from '@dosomegood/platform/dist/components/typography/Content';
import { formalDateTime } from '@dosomegood/platform/dist/utils/Chrono';
import { friendlyRange } from '@dosomegood/platform/dist/utils/ChronoRange';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import ProfileImage from '@dosomegood/platform/dist/components/ui/ProfileImage';
import { capitalize } from '@dosomegood/platform/dist/utils/String';
import { useTranslation } from 'react-i18next';

const Details = styled.div`
  flex: 1;
  margin-left: ${(props) => props.theme.margins.xs};
  & > * {
    display: block;
  }
  text-align: left;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${(props) => props.theme.margins.xxs};
  display: inline-block;
  min-width: 15px;
  text-align: center;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${(props) => props.theme.margins.xs} 0;
  padding-left: ${(props) => props.theme.margins.xs};
  cursor: pointer;
  background-color: ${(props) =>
    props.$inverse
      ? props.theme.brandColors.hsla(props.theme.brandColors.tertiary)
      : props.theme.brandColors.lightestBackground};
  & i,
  & > div > span {
    color: ${(props) =>
      props.theme.brandColors[props.$inverse ? 'primaryTextInverse' : 'primaryText']} !important;
  }
  &:hover {
    background-color: ${(props) => props.theme.brandColors.hsla(props.theme.brandColors.tertiary)};
    & i,
    & > div > span {
      color: ${(props) => props.theme.brandColors.primaryTextInverse} !important;
    }
  }
`;

export function SearchResultRow({ containerRef, type, data, inverse, onClick }) {
  const { t, i18n } = useTranslation(['common']);
  const { firstName, lastName, name, roleName, profilePhoto, logo, city, startDate, endDate } =
    data;
  const fName = name || roleName || `${firstName} ${lastName}`;

  return (
    <RowContainer ref={containerRef} $inverse={inverse} onClick={onClick}>
      {['user', 'business', 'organization'].includes(type) && (
        <ProfileImage image={profilePhoto || logo} type={type} size="sm" name={fName} />
      )}
      <Details>
        <CollectionItemHeader>{fName}</CollectionItemHeader>
        <CollectionItemDetail>
          <StyledIcon name={'map-marker'} />
          {capitalize(type)} in {city}
        </CollectionItemDetail>
        {type === 'opportunity' && (
          <CollectionItemDetail>
            <StyledIcon name={'calendar-empty'} />
            {!!startDate && !!endDate && friendlyRange(startDate, endDate, t, i18n)}
            {!!startDate && !endDate && `Starts ${formalDateTime(startDate, t, i18n)}`}
            {!startDate && `Flexible Schedule`}
          </CollectionItemDetail>
        )}
        {type === 'event' && (
          <CollectionItemDetail>
            <StyledIcon name={'calendar-empty'} /> {formalDateTime(startDate, t, i18n)}
          </CollectionItemDetail>
        )}
      </Details>
    </RowContainer>
  );
}

const UniversalSelect = forwardRef((props, ref) => {
  const { t } = useTranslation(['common']);
  // Reformat the results in a manner that we get unique ids and searchable names
  const options = props.options.map((o) => ({
    ...o,
    id: o.data.slug || o.data.hash,
    name: o.data.name || `${o.data.firstName} ${o.data.lastName}`,
  }));
  return (
    <SingleSelect
      {...props}
      ref={ref}
      options={options}
      compact
      placeholder={t('common:placeholders.searchDSG')}
      optionRenderer={SearchResultRow}
      selectedRenderer={SimpleNameChip}
      searchKey="name"
      autoSelectOnBlur={false}
    />
  );
});

const SearchContainer = styled.div`
  width: 100%;
  padding-right: ${(props) => props.theme.margins.xs};
  & > * {
    max-width: 360px;
    margin-left: auto;
  }
`;

export default function UniversalSearch({ onClose }) {
  const selectRef = useRef(null);

  function handleSelect(item) {
    if (item) {
      const { type, data } = item;
      onClose && onClose();
      selectRef && selectRef.current && selectRef.current.clear();
      switch (type) {
        case 'user':
          return browserHistory.push(`/user/profile/${data.hash}`);
        default:
          return browserHistory.push(`/${type}/${data.id || data.slug || data.hash}`);
      }
    }
  }

  return (
    <SearchContainer>
      <ConnectedSingleSelect
        ref={selectRef}
        id="universal-search"
        api={API}
        primaryKey={'data'}
        filterWhileSearching={false}
        endpoint={(value) => `/search/universal?query=${encodeURIComponent(value)}`}
        component={UniversalSelect}
        onChange={handleSelect}
      />
    </SearchContainer>
  );
}
