import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import UserDashboardStore from '../../stores/userDashboard';

import { CollectionItemHeader } from '@dosomegood/platform/dist/components/typography/Content';

const Container = styled.div`
  max-width: 1170px;
  padding: ${(props) => props.theme.margins.xs};
  margin: 0 auto;
`;

const Panel = styled.div`
  position: relative;
  ${(props) => props.theme.borders.standard};
  border-color: ${(props) => props.theme.brandColors.hsla(props.theme.brandColors.primary)};
  padding: ${(props) => props.theme.margins.sm};
  text-align: center;
  &,
  & * {
    color: ${(props) => props.theme.brandColors.hsla(props.theme.brandColors.primary)} !important;
  }
`;

const PointerDiv = ({ hidden, ...props }) => <div {...props} />;
const Pointer = styled(PointerDiv)`
  border: 1px solid ${(props) => props.theme.brandColors.hsla(props.theme.brandColors.primary)};
  border-bottom: 0;
  border-right: 0;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background-color: white;
  position: absolute;
  top: -11px;
  right: 44px;
  z-index: ${(props) => (props.hidden ? 0 : 1000)};
`;

const getMessage = ({ pathname }, t) => {
  if (pathname.startsWith('/post/')) {
    return t('common:labels.guestCtaPost');
  } else if (pathname.startsWith('/business/')) {
    return t('common:labels.guestCtaBusiness');
  } else if (pathname.startsWith('/organization/')) {
    return t('common:labels.guestCtaOrganization');
  } else if (pathname.startsWith('/event/')) {
    return t('common:labels.guestCtaEvent');
  } else if (pathname.startsWith('/opportunity/')) {
    return t('common:labels.guestCtaOpportunity');
  } else if (pathname.startsWith('/user/profile/')) {
    const firstName = (UserDashboardStore.profile || {}).firstName;
    return firstName
      ? t('common:labels.guestCtaUserProfileForX', { firstName })
      : t('common:labels.guestCtaUserProfile');
  } else if (pathname.startsWith('/community/businesses')) {
    return t('common:labels.guestCtaBusinesses');
  } else if (pathname.startsWith('/community/organizations')) {
    return t('common:labels.guestCtaOrganizations');
  } else if (pathname.startsWith('/community/events')) {
    return t('common:labels.guestCtaEvents');
  }
  return t('common:labels.guestCtaGeneral');
};

export default function GuestCallToAction({ location }) {
  const { t } = useTranslation(['common']);
  const [hidden, setHidden] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 8 && !hidden) {
      setHidden(true);
    } else if (window.scrollY <= 8 && hidden) {
      setHidden(false);
    }
  }, [hidden]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const message = getMessage(location, t);
  return (
    <Container>
      <Panel>
        <CollectionItemHeader>{message}</CollectionItemHeader>
        <Pointer hidden={hidden} />
      </Panel>
    </Container>
  );
}
