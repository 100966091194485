import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AppActions from '../../../actions/app';

import Link from '@dosomegood/platform/dist/components/buttons/Link';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';

class MobileMenuItem extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  closeMobileMenu() {
    AppActions.closeMobileMenu();
  }

  handleClick = (e) => {
    if (this.props.onClick) {
      if (e && this.props.title === 'More') e.preventDefault();
      this.props.onClick();
    } else {
      this.closeMobileMenu();
      this.props.onSelect && this.props.onSelect();
    }
  };

  render() {
    const liClassList = {};
    const isActive = !!this.props.route && this.context.router.isActive(this.props.route);

    this.props.type ? (liClassList[`type-${this.props.type}`] = true) : false;
    this.props.parent ? (liClassList['nav-parent'] = true) : false;
    isActive ? (liClassList.active = true) : false;

    let liClasses = classNames(liClassList);

    return (
      <li className={liClasses}>
        <Link
          role="menuitem"
          to={this.props.route}
          onClick={this.handleClick}
          activeClassName="active"
        >
          <Icon name={this.props.icon} />
          <span>{this.props.shortTitle || this.props.title}</span>
          {this.props.notice > 0 && (
            <span className="pull-right label label-primary sidebar-notice">
              {this.props.notice} New
            </span>
          )}
        </Link>
      </li>
    );
  }
}

export default MobileMenuItem;
