import React from 'react';
import styled from 'styled-components';

import AppActions from '../../../actions/app';

import { api as API } from '../../../mixins/api';
import { analytics as Analytics } from '../../../mixins/analytics';

import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import MiniModalVerifyLocation from '../../minimodals/MiniModalVerifyLocation';
import { Trans, useTranslation } from 'react-i18next';

const Container = styled.div`
  padding: ${(props) => props.theme.margins.xs};
  text-align: center;
  background-color: ${(props) => props.theme.brandColors.statusColors.warning};

  & p {
    margin: 0 !important;
  }

  & button {
    padding: 6.6px !important;
  }
`;

const handleChangeCity = (user, t) => {
  AppActions.setModal({
    component: MiniModalVerifyLocation,
    options: {
      user,
      display: 'modal',
      change: true,
      onUpdate: () => {
        AppActions.setModal(false);
        AppActions.showNotice({
          message: t('common:alerts.thanksVerifyCity'),
        });
      },
    },
  });
};

const handleVerifyCity = (user, t) => {
  API.makeRequest('/user', 'PUT', { verifiedCity: true }, (err, result) => {
    if (!err && result.ok) {
      AppActions.updateUser(result.body);
      Analytics.trackEvent('user-profile', {
        label: user.city,
        action: 'verify-city',
      });
      AppActions.showNotice({
        message: t('common:alerts.thanksVerifyCity'),
      });
    }
  });
};

function VerifyCity({ user }) {
  const { t } = useTranslation(['common']);
  return (
    <Container>
      <Paragraph inverse>
        <Trans
          t={t}
          i18nKey="common:alerts.headerVerifyCity"
          tOptions={{ city: user.city, region: user.region }}
        >
          In order to show you local content, we'd like to verify that you live in{' '}
          <strong>
            {user.city}, {user.region}
          </strong>
          .
        </Trans>{' '}
        <Buttons.Secondary inverse onClick={() => handleVerifyCity(user, t)}>
          {t('common:actions.yesThatCorrect')}
        </Buttons.Secondary>
        <Buttons.Alternate inverse onClick={() => handleChangeCity(user, t)}>
          {t('common:actions.noNotRight')}
        </Buttons.Alternate>
      </Paragraph>
    </Container>
  );
}

export default VerifyCity;
