import React, { useEffect, useRef } from 'react';

import AppStore from '../stores/app';
import { useStore } from '../utils/alt';

/**
 * This route handler ensures there's a logged in user before showing the child routes.
 * However it's not very SSR friendly, and will momentarily render a blank screen until
 * redirected to the login/signup on the client. In the future when we replace the
 * routing library, we need to find a way to make this redirect on the server for a
 * better user experience.
 */
function RequireAuth({ children, router, routes, location, ...props }) {
  const [{ user, usingProxyUser }] = useStore(AppStore);
  const lastLocation = useRef(null);

  useEffect(() => {
    // Remember location and only redirect if we haven't already
    if (!user && lastLocation.current !== location) {
      lastLocation.current = location;
      const defaultSignupRoute = routes.find((r) => !!r.defaultSignup);
      // If they aren't logged in we need to present them with the signup/sign in form
      // Preserve any tokens in the path so we can lookup the invite info
      router.replace({
        pathname: defaultSignupRoute ? '/signup' : '/login',
        query: { token: location.query.token || router.params.token, error: location.query.error },
        state: { from: location },
      });
    }
  }, [user, location, router, routes]);

  if (user) {
    lastLocation.current = location;
    return React.cloneElement(children, {
      user,
      usingProxyUser,
      router,
      location,
      routes,
      ...props,
    });
  }

  return null;
}

export default RequireAuth;
