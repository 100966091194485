import {
  fetchOverviewAndResume as fetchOverviewAndResumeBusiness,
  getMasterList as getMasterListBusiness,
} from '../components/management/onboarding/business/MasterSteps';
import {
  fetchOverviewAndResume as fetchOverviewAndResumeOrganization,
  getMasterList as getMasterListOrganization,
} from '../components/management/onboarding/organization/MasterSteps';
import { getBusinessName } from '@dosomegood/platform/dist/utils/Names';

const MenuMixin = {
  flattenMenu(menu) {
    let flatMenu = [];
    menu.forEach((item) => {
      if (item.route) flatMenu.push(item);
      (item.items || []).forEach((subItem) => {
        if (subItem.route) flatMenu.push(subItem);
      });
    });

    return flatMenu;
  },

  buildMenu(user, t) {
    let sidebarWidgets = [];
    const sidebarItems = [
      { id: 'nav-news', icon: 'news', title: t('common:nav.news'), route: '/dashboard' },
      {
        icon: 'star-full',
        title: t('common:nav.getInvolved'),
        items: [
          {
            icon: 'group',
            title: t('common:nav.localOrganizations'),
            shortTitle: t('common:nav.localOrganizationsShort'),
            route: '/community/organizations',
          },
          {
            icon: 'search',
            title: t('common:nav.opportunities'),
            shortTitle: t('common:nav.opportunitiesShort'),
            route: '/opportunities/browse',
          },
          {
            icon: 'calendar',
            title: t('common:nav.upcomingEvents'),
            shortTitle: t('common:nav.upcomingEventsShort'),
            route: '/community/events',
          },
        ],
      },
      {
        icon: 'building',
        title: t('common:nav.goodCompanies'),
        shortTitle: t('common:nav.goodCompaniesShort'),
        route: '/community/businesses',
      },
      {
        id: 'menu-user-profile',
        icon: 'user',
        title: t('common:nav.myProfile'),
        route: `/user/profile/${user.hash}`,
      },
      { icon: 'clock', title: t('common:nav.logTime'), route: '/user/log-hours' },
    ];

    const businesses = (user.relations || []).filter((rel) =>
      rel ? rel.type === 'business' : false,
    );
    const organizations = (user.relations || []).filter((rel) =>
      rel ? rel.type === 'organization' : false,
    );

    // Build menus for businesses
    if (businesses && businesses.length) {
      businesses.forEach((relation) => {
        // Profile link for employees only
        if (relation.relation === 'Employee') {
          // Main widget with title
          let widget = {
            icon: 'building',
            title: relation.target.name,
            type: 'business',
            route: `/business/${relation.target.slug}`,
            items: [],
          };

          // Profile
          widget.items.push({
            icon: 'v-card',
            title: t(`common:nav.typeProfile`, {
              type: getBusinessName(
                relation.target && (relation.target.subType || relation.target.sub_type),
                t,
              ),
            }),
            route: `/business/${relation.target.slug}`,
          });

          // Dashboard
          if (relation.target?.features?.dashboard) {
            widget.items.push({
              icon: 'group',
              title: t('common:nav.teamArea'),
              route: `/business/${relation.target.slug}/team`,
            });
          }

          sidebarWidgets.push(widget);
        } else if (relation.relation === 'Limited Admin') {
          let widget = {
            icon: 'building',
            title: relation.target.name,
            slug: relation.target.slug,
            type: 'business',
            route: `/business/${relation.target.slug}`,
            items: [],
          };

          widget.items.push({
            icon: 'v-card',
            title: t(`common:nav.typeProfile`, {
              type: getBusinessName(
                relation.target && (relation.target.subType || relation.target.sub_type),
                t,
              ),
            }),
            route: `/business/${relation.target.slug}`,
          });

          widget.items.push({
            type: 'management',
            icon: 'briefcase',
            title: t('common:nav.management'),
            route: `/business/${relation.target.slug}/manage`,
          });

          sidebarWidgets.push(widget);
        } else if (relation.relation === 'Administrator') {
          // Full toolset for admins

          // Main widget with title
          let widget = {
            icon: 'building',
            title: relation.target.name,
            slug: relation.target.slug,
            type: 'business',
            route: `/business/${relation.target.slug}`,
            items: [],
          };

          widget.items.push({
            icon: 'v-card',
            title: t(`common:nav.typeProfile`, {
              type: getBusinessName(
                relation.target && (relation.target.subType || relation.target.sub_type),
                t,
              ),
            }),
            route: `/business/${relation.target.slug}`,
          });

          // Dashboard
          if (relation.target?.features?.dashboard) {
            widget.items.push({
              icon: 'group',
              title: t('common:nav.teamArea'),
              route: `/business/${relation.target.slug}/team`,
            });
          }

          // Management
          if (relation.target?.flags?.onboarded) {
            widget.items.push({
              type: 'management',
              icon: 'briefcase',
              title: t('common:nav.management'),
              route: `/business/${relation.target.slug}/manage`,
            });
          } else {
            const branding = relation.target?.features?.branding;
            widget.items.push({
              icon: branding ? 'checkmark' : 'briefcase',
              title: branding ? t('common:nav.finishSetup') : t('common:nav.management'),
              onClick: () =>
                fetchOverviewAndResumeBusiness(
                  {
                    type: 'business',
                    slug: relation.target.slug,
                  },
                  getMasterListBusiness,
                  t,
                ),
              /*route: `/business/${relation.target.slug}/manage/welcome/profile/about-all?returning=true`,*/
            });
          }

          sidebarWidgets.push(widget);
        }
      });
    }
    if (organizations?.length) {
      organizations.forEach((relation) => {
        // Profile link for employees only
        if (relation.relation === 'Employee') {
          sidebarItems.push({
            icon: 'users',
            title: relation.target.name,
            route: `/organization/${relation.target.slug}`,
            type: 'organization',
          });
        } else if (relation.relation === 'Limited Admin') {
          let widget = {
            icon: 'users',
            title: relation.target.name,
            slug: relation.target.slug,
            type: 'organization',
            route: `/organization/${relation.target.slug}`,
            items: [],
          };
          widget.items.push({
            type: 'management',
            icon: 'briefcase',
            title: t('common:nav.management'),
            route: `/organization/${relation.target.slug}/manage`,
          });
          sidebarWidgets.push(widget);
        } else if (relation.relation === 'Administrator') {
          // Full toolset for admins
          // Main widget with title
          let widget = {
            icon: 'users',
            title: relation.target.name,
            slug: relation.target.slug,
            type: 'organization',
            route: `/organization/${relation.target.slug}`,
            items: [],
          };

          // Profile
          widget.items.push({
            icon: 'v-card',
            title: t('common:nav.organizationProfile'),
            route: `/organization/${relation.target.slug}`,
          });

          // Dashboard
          widget.items.push({
            icon: 'dashboard',
            title: t('common:nav.dashboard'),
            route: `/organization/${relation.target.slug}/manage/volunteers/dashboard`,
          });

          // Organization Management
          if (relation.target?.flags?.onboarded) {
            widget.items.push({
              type: 'management',
              icon: 'briefcase',
              title: t('common:nav.management'),
              route: `/organization/${relation.target.slug}/manage`,
            });
          } else {
            widget.items.push({
              icon: 'checkmark',
              title: t('common:nav.finishSetup'),
              onClick: () =>
                fetchOverviewAndResumeOrganization(
                  {
                    type: 'organization',
                    slug: relation.target.slug,
                  },
                  getMasterListOrganization,
                  t,
                ),
            });
          }

          sidebarWidgets.push(widget);
        }
      });
    }

    return {
      items: sidebarItems,
      grouped: sidebarWidgets,
    };
  },
};

export default MenuMixin;
export { MenuMixin };
