import { forEachSeries } from '@dosomegood/platform/dist/utils/Async';
import ServiceHandler from './services';

export default (req, res, params, callback) => {
  let toResolve = [];

  const services = ServiceHandler(req, res, params);

  // Find which services we need to resolve
  // before rendering
  params.routes.forEach((route) => {
    if (typeof services[route.name] === 'function') {
      toResolve.push(services[route.name]);
    }
  });

  let nextState = {};

  // Resolve them from the outside route, inward
  forEachSeries(
    toResolve,
    (service, done) => {
      // Past nextState as an optional 2nd param to each service resolver
      service((err, result = {}) => {
        //console.log('service resolved:', err, result, 'nextState', nextState);
        // Keep nextState up to date as we go down the chain
        nextState = { ...nextState, ...result };
        done(err, result);
      }, nextState);
    },
    () => {
      return callback(nextState);
    },
  );
};
