import fetch from 'cross-fetch';
import AppStatusActions from 'actions/appStatus';
import { getApiHost, getAuthHost } from 'utils/MultiDomain';
import { guessTimezoneName } from '@dosomegood/platform/dist/utils/Chrono';

class APIClient {
  queue = [];

  // Are there active requests waiting?
  active = false;

  // Are we currently allowing processing (not awaiting a refresh)?
  processing = true;

  // Are we actively refreshing?
  refreshing = false;

  // Stored callback once refresh ultimately succeeds or fails
  refreshCallback = null;

  // Track an incrementing requestId so we can cancel or alter them mid-flight
  requestId = 0;

  // Number if in-flight requests active
  inFlight = 0;

  // How many token refreshes have been attempted since the last success?
  refreshAttempts = 0;

  constructor(auth, req) {
    this.req = req;
    this.auth = auth;

    //setInterval(() => console.info('inflight', this.inFlight), 5000);
  }

  /**
   * Determine the best host to use for API requests from config file
   *
   * @returns {String} pathname
   * @private
   */
  host() {
    return getApiHost(this.req);
  }

  token() {
    return [this.auth.proxyToken() || this.auth.token(), !!this.auth.proxyToken()];
  }

  makeRequest(url, method, params, callback, forceGuest = false) {
    //console.log('makeRequest', url, this.token());
    const requestId = this.requestId++;
    this.queue.push({
      url,
      method,
      params,
      callback,
      forceGuest,
      retryCount: 0,
      requestId,
      cancel: false,
    });
    this.checkQueue();
    return this.handleCancel(requestId);
  }

  requestDownload(url, method, params, filename, callback, forceGuest = false) {
    //console.log('makeRequest', url, this.token());
    const requestId = this.requestId++;
    this.queue.push({
      url,
      method,
      params,
      callback,
      forceGuest,
      retryCount: 0,
      requestId,
      cancel: false,
      download: true,
      filename,
    });
    this.checkQueue();
    return this.handleCancel(requestId);
  }

  setActive(active) {
    if (this.active !== active) {
      this.active = active;
      if (typeof window === 'object') AppStatusActions.setIsXhrActive(active);
    }
  }

  handleCancel(requestId) {
    return () => {
      this.queue.forEach((q) => {
        if (q.requestId === requestId) {
          q.cancel = true;
        }
      });
    };
  }

  async checkExpiry() {
    const [_, isProxy] = this.token();
    const exp = !isProxy ? this.auth.expires() : this.auth.proxyExpires();
    if (!exp) return false; // If we don't have expiry info, then proceed as normal and hope the 401 detection can fix the token
    const diff = (exp - new Date()) / 1000;
    //if (isProxy) console.info('Proxy access token expires in', diff, 'seconds');
    // If we're within 5 minutes of expiry, let's pre-emptively refresh
    if (diff < 300) {
      //console.log('Pre-emptively refreshing token', diff);
      await this.requestRefresh();
      return true;
    }
    return false;
  }

  async checkQueue(processAll = false) {
    if (this.queue.length && this.processing) {
      const [token] = this.token();
      // If we have a token, check the expiry, if not just proceed and allow guest experience through
      const refreshed = token ? await this.checkExpiry() : false;
      // If we didn't need to refresh, proceed normally - if we did refresh, it'll handle running the whole queue afterwards
      if (!refreshed) {
        const max = processAll ? this.queue.length : 1;
        for (let n = 0; n < max; n++) {
          const request = this.queue.shift();
          if (!request) return console.trace('Warning: Exhausted API queue');
          this.inFlight++;
          this.setActive(this.inFlight !== 0);
          await this.processRequest(request);
        }
      }
    }
  }

  async requestRefresh(cb) {
    this.processing = false;
    //console.log('Requesting a refresh of the access token');

    try {
      const result = await this.processRefresh();
      if (result === 'WAIT') {
        if (cb) {
          this.refreshCallback = cb;
        }
        return;
      }
      console.log(
        'Refreshed, Resuming...',
        cb ? 'With Immediate Refresh Callback' : '',
        this.refreshCallback ? 'With Deferred Refresh Callback' : '',
      );
      this.processing = true;
      if (cb || this.refreshCallback) {
        const whichCb = cb || this.refreshCallback;
        whichCb(null, () => this.checkQueue(true));
        this.refreshCallback = null;
      } else {
        this.checkQueue(true);
      }
    } catch (err) {
      console.error('Could not refresh token, clearing queue', err);
      if (cb || this.refreshCallback) {
        const whichCb = cb || this.refreshCallback;
        whichCb(err);
        this.refreshCallback = null;
      }

      // On error clear the queue and reset state
      this.setActive(false);
      this.processing = true;
      this.refreshing = false;
      this.refreshAttempts = 0;
      this.queue = [];

      // Client side let's just logout and refresh to kick to login screen
      if (typeof window === 'object') {
        this.auth.logout(() => {
          console.log('Logged out');
          alert(
            `Your session has expired and you have been logged out. Please sign back in to continue where you left off.`,
          );
          location.reload();
        });
      } else {
        //console.log(this.req);
        this.req.res.redirect(`${getAuthHost(this.req)}/auth/logout?error=expired&redirect=true`);
      }
    }
  }

  async processRefresh() {
    if (this.refreshing) {
      console.info('Already refreshing, waiting until complete...');
      return 'WAIT';
    }
    if (this.refreshAttempts > 5) {
      throw new Error('Too many failed session refresh attempts, giving up.');
    }
    this.refreshing = true;

    const [token, isProxy] = this.token();

    if (!token) {
      console.error('Attempted to refresh a token where no token is present.');
      this.refreshing = false;
      return 'WARN';
    }

    const proxySuffix = isProxy ? '/proxy' : '';

    let response;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
    };
    try {
      // If we're refreshing on the server, use cookies to authenticate and mimick the user agent
      if (typeof window !== 'object') {
        const credentials = this.auth.credentials();
        headers['cookie'] = credentials;
        if (this.req) headers['user-agent'] = this.req.headers['user-agent'];
      }
      this.refreshAttempts += 1;
      response = await fetch(`${getAuthHost(this.req)}/auth/refresh${proxySuffix}`, {
        credentials: 'include',
        method: 'POST',
        headers,
        timeout: typeof window !== 'object' ? 5000 : 16000,
        redirect: 'follow',
      });
    } catch (err) {
      console.error('Token Refresh Network Error', err);
    }

    if (!response) throw new Error('Could not obtain refresh token - network error');

    if (response.status === 404) {
      throw new Error('Auth resource not found');
    } else if (response.status === 500) {
      if (typeof window === 'object') {
        alert(
          `There was an unrecoverable system error trying to keep you logged in. Please clear your browser's cookies and reload the site. If this keeps recurring, please contact support@dosomegood.ca`,
        );
      }
      throw new Error('Internal server error');
      //return 'FATAL'; // Not yet handled elsewhere
    }

    let body;
    try {
      body = await response.json();
    } catch (err) {
      console.error(`Could not parse JSON`);
      throw err;
    }

    if (response.status !== 200) throw new Error('Could not obtain refresh token');

    // Server-side only, on client the accessing of the auth endpoint sets the cookies via headers
    if (typeof window !== 'object') {
      const cHeaders = response.headers.raw()['set-cookie'];
      //console.log('Refreshed', body, cHeaders);

      if (cHeaders) {
        this.auth.setCredentials(cHeaders);
      }
    }

    //console.log('Setting updated token and expiry locally');
    if (isProxy) {
      this.auth.setProxyTokenAndExpiry(body.access_token, body.expires_in);
    } else {
      this.auth.setTokenAndExpiry(body.access_token, body.expires_in);
    }

    if (this.token()[0] !== body.access_token) {
      console.warn(
        'Unable to save refreshed token locally. Running cookie fix...',
        this.token(),
        body,
      );
      await this.auth.repair();
      // Check again
      if (this.token()[0] !== body.access_token) {
        console.warn(
          'Cookie fix was ineffective (possible non-SSL environment), falling back to local patch...',
        );
        if (typeof window === 'object') {
          if (isProxy) {
            this.auth.setProxyTokenAndExpiry(body.access_token, body.expires_in, true);
          } else {
            this.auth.setTokenAndExpiry(body.access_token, body.expires_in, true);
          }
        }
        if (this.token()[0] !== body.access_token) {
          console.error(
            'Completely failed to set cookies and local token, please clear your browser cookies and try again',
          );
          throw new Error('Could not establish refreshed token');
        }
      }
    }

    this.refreshing = false;
    this.refreshAttempts = 0;

    // Tell the realtime system to update its token just incase it gets disconnected and needs to re-auth
    if (typeof window === 'object' && global.dsgRealtime) {
      global.dsgRealtime.updateToken(this.token()[0]);
    }

    return 'DONE';
  }

  async processRequest(request) {
    const { url, method, params, callback = () => {}, forceGuest, retryCount } = request;
    const [token, isProxy] = this.token();

    if (retryCount > 2) {
      this.inFlight--;
      this.setActive(this.inFlight !== 0);
      if (request.cancel) return;
      console.error('Exceeded max retries');
      return callback(new Error('Failed to gain permission after retrying to gain token'), {
        status: 500,
        ok: false,
        body: { error: 'Failed to refresh credentials' },
        isProxy,
      });
    }

    let response;
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      'X-Timezone': guessTimezoneName(),
    };

    if (!forceGuest && token) headers.Authorization = `Bearer ${token}`;

    // Attach real client IP if on server
    if (this.req) {
      const ips = Array.isArray(this.req.ips) && this.req.ips.length ? this.req.ips : [this.req.ip];
      headers['X-Forwarded-For'] = ips.join(', ');
    }

    try {
      response = await fetch(`${this.host()}${url}`, {
        method,
        headers,
        timeout: typeof window !== 'object' ? 5000 : 16000,
        body: method.toUpperCase() !== 'GET' ? JSON.stringify(params) : undefined,
      });
    } catch (err) {
      this.inFlight--;
      this.setActive(this.inFlight !== 0);
      if (request.cancel) return;
      callback(err, {
        status: response ? response.status : 500,
        ok: response ? response.ok : false,
        body: { error: 'API request failed' },
        isProxy,
      });
      return console.log('API Error', err);
    }

    this.inFlight--;
    this.setActive(this.inFlight !== 0);

    // Abort any cancelled requests
    if (request.cancel) return;

    const retType = response.headers.get('content-type');
    if (response.headers.get('x-backoff')) {
      console.warn(
        'Backoff detected, requesting too fast. (Ignoring for now)',
        response.headers.get('x-backoff'),
      );
      //this.queue.push({ ...request, retryCount: request.retryCount + 1 });
      //return;
    }

    let body;
    try {
      if (retType.includes('application/json')) {
        body = await response.json();
      } else if (retType.includes('text/html')) {
        body = await response.text();
      } else {
        body = await response.blob();
      }
    } catch (err) {
      if (response.status === 404) {
        return callback(new Error('API resource not found'), {
          status: response.status,
          ok: response.ok,
          isProxy,
        });
      }
      console.error(`Could not parse body`, retType);
      return callback(err, { status: response.status, ok: response.ok, isProxy });
    }

    if (response.status === 401) {
      //this.inFlight++; Increasing handled when queue processing checks state
      //this.setActive(this.inFlight !== 0);

      if (token) {
        // Wait for a new token and freeze the queue
        this.processing = false;
        // Push back on the queue
        this.queue.push({ ...request, retryCount: request.retryCount + 1 });
        // Refresh and retry
        console.warn('Detected an expired token too late (upon request), requesting a refresh...');
        this.requestRefresh();
        return;
      } else {
        console.warn('Not logged in, no permissions to this endpoint:', url);
      }
    }

    callback(null, {
      status: response.status,
      ok: response.ok,
      body,
      isProxy,
      text: retType.includes('text/html') ? body : undefined,
    });

    if (request.download) {
      const blob = new Blob([body], { type: response.headers.get('content-type') });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = request.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }
}

export default APIClient;
