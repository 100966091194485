import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactEmojiMixin from 'react-emoji';
import { withTranslation } from 'react-i18next';

import { api as API } from '../../../mixins/api';
import { Utils } from '../../../mixins/utils';
import { analytics as Analytics } from '../../../mixins/analytics';

import AppStore from '../../../stores/app';
import AppActions from '../../../actions/app';

import NotificationStore from '../../../stores/notification';

import Link from '@dosomegood/platform/dist/components/buttons/Link';
import Picture from '../../widgets/Picture';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import connectToStores from 'alt-utils/lib/connectToStores';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import UnreadIndicator from '@dosomegood/platform/dist/components/ui/UnreadIndicator';
import HeaderIcon from './HeaderIcon';
import MessageCompose from './MessageCompose';
import Confirm from '../../modals/Confirm';

const Actions = styled.div`
  padding: ${(props) => props.theme.margins.xxs};
  flex-direction: row;
  display: flex;

  & > a:first-child {
    flex: 1;
  }
`;

@connectToStores
class MessagesDropdown extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static getStores() {
    return [NotificationStore, AppStore];
  }

  static getPropsFromStores() {
    const { messagesDropdownOpen, composeMessage } = AppStore.getState();
    return { notifications: NotificationStore.getState(), messagesDropdownOpen, composeMessage };
  }

  state = {
    updateCount: 0,
    messages: {
      threads: [],
    },
    isOpen: false,
    isComposing: false,
    isSending: false,
    loading: false,
    recipient: false,
    canCompose:
      Utils.relationshipsOfAccessLevel('employee').length > 0 ||
      Utils.relationshipsOfAccessLevel('limited admin').length > 0 ||
      Utils.relationshipsOfAccessLevel('administrator').length > 0,
  };

  componentDidMount() {
    this._mounted = true;
    this.fetchMessages();

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    this._mounted = false;
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messagesDropdownOpen !== this.props.messagesDropdownOpen) {
      this.setState({ isOpen: this.props.messagesDropdownOpen });
    }
    if (prevProps.composeMessage !== this.props.composeMessage) {
      this.setState({
        isComposing: !!this.props.composeMessage,
        recipient: this.props.composeMessage,
      });
    }
    if (prevProps.user !== this.props.user) {
      this.setState({
        canCompose:
          Utils.relationshipsOfAccessLevel('employee').length > 0 ||
          Utils.relationshipsOfAccessLevel('limited admin').length > 0 ||
          Utils.relationshipsOfAccessLevel('administrator').length > 0,
      });
    }
  }

  fetchMessages = () => {
    this.setState({ loading: true });
    API.makeRequest('/user/messages/new', 'GET', {}, (_err, res) => {
      if (!this._mounted) return;
      const content = res && res.body && !res.body.error ? res.body : [];
      this.setState({ loading: false, messages: { threads: content } });
    });
  };

  openCompose = (event) => {
    if (event) event.stopPropagation();
    AppActions.composeMessage(true);
    this._dirty = false;
  };

  closeCompose = (event) => {
    if (event) event.stopPropagation();
    if (this._dirty) {
      this.confirmDiscard();
    } else {
      AppActions.composeMessage(false);
    }
  };

  handleSendMessage = () => {
    this._dirty = false;

    AppActions.setMessageDropdownOpen(false);
    AppActions.composeMessage(false);
    this.setState({ isSending: true });

    setTimeout(() => {
      if (this._mounted) this.setState({ isSending: false });
    }, 4000);
  };

  handleChangeMessage = ({ to, subject, message }) => {
    this._dirty = !!to?.length || !!subject || !!message;
  };

  handleToggleDropdown = (pd, event) => {
    if (pd) event.preventDefault();
    if (this.state.isComposing && this.state.isOpen) return event.stopPropagation();
    const nextState = !this.state.isOpen;
    AppActions.setMessageDropdownOpen(nextState);
    if (nextState) {
      // if the dialog is open
      this.fetchMessages();
      Analytics.trackEvent('messages', {
        action: 'opened-dropdown',
      });
    }
  };

  handleClickOutside = (event) => {
    const node = this._container;
    if (!node.contains(event.target)) {
      if (this.state.isOpen && !this._dirty) {
        AppActions.setMessageDropdownOpen(false);
        AppActions.composeMessage(false);
      } else if (this._dirty) {
        this.confirmDiscard();
      }
    }
  };

  confirmDiscard = () => {
    const { t } = this.props;
    // Temporarily disable the click outside handler so interactions with the modal don't re-trigger it
    document.removeEventListener('mousedown', this.handleClickOutside);

    // Display a ConfirmModal ensuring the user intended to discard their message
    AppActions.setModal({
      component: Confirm,
      options: {
        title: t('common:labels.discardMessage'),
        message: t('common:labels.areYouSureDiscardMessage'),
        onCancel: (e) => {
          // Re-enable the click outside handler
          document.addEventListener('mousedown', this.handleClickOutside);
        },
        onConfirm: (e) => {
          this._dirty = false;
          AppActions.setMessageDropdownOpen(false);
          AppActions.composeMessage(false);

          // Re-enable the click outside handler
          document.addEventListener('mousedown', this.handleClickOutside);
        },
      },
    });
  };

  render() {
    const { isOpen, canCompose, messages: _messages = {} } = this.state;
    const { user = {}, t } = this.props;
    const { threads = [] } = _messages;

    const messages = threads.slice(0, 5).map((thread) => {
      const isGroupThread = thread.groupId && thread.numRecipients > 1;
      const threadDest = isGroupThread
        ? `/user/messages/thread-group/${thread.groupId}/${thread.signature}`
        : `/user/messages/thread/${thread.id}`;

      const topRecipients = (
        <span>
          {thread.topRecipients.map((r) => r.firstName).join(', ')}
          {thread.numRecipients - 3 >= 1 && (
            <small className="text-muted">
              {t('common:labels.andXMore', { count: thread.numRecipients - 3 })}
            </small>
          )}
        </span>
      );

      const author = thread.author.firstName + ' ' + thread.author.lastName;
      const isAuthor = user.hash === thread.author.hash;
      const isActive = this.context.router.isActive(threadDest);
      return (
        <li
          className={classNames(
            { active: isActive, unread: thread.unreadMessages && !isGroupThread },
            'not-rounded',
          )}
          key={thread.id}
        >
          <Link to={threadDest} onClick={this.handleToggleDropdown.bind(this, false)}>
            <figure className="image">
              <Picture
                src={thread.lastAuthor.profilePhoto}
                type="person"
                size="xs"
                shape="circle"
              />
            </figure>
            <span className="title">{isAuthor ? topRecipients : author}</span>
            <span className="message">
              {ReactEmojiMixin.emojify(thread.lastMessage.substring(0, 36), {
                attributes: { width: '12px', height: '12px' },
              })}
            </span>
          </Link>
        </li>
      );
    });

    const dropdownMenu = (
      <div className="dropdown-menu notification-menu" id="messages-dropdown-list">
        <div className="notification-heading">
          <span className="pull-right label label-default" aria-hidden>
            {messages.length}
          </span>
          {t('common:labels.messages')}
        </div>
        {messages.length ? (
          <ul className="dropdown-menu-items">{messages}</ul>
        ) : (
          <div className="content p-xlg">
            <div className="text-center">{t('common:labels.noUnreadMessages')}</div>
          </div>
        )}

        <Actions>
          <Buttons.Alternate
            icon="inbox"
            to="/user/messages"
            compact
            onClick={this.handleToggleDropdown.bind(this, false)}
          >
            {t('commonPlat:actions.viewAll')}
          </Buttons.Alternate>
          {!!canCompose && (
            <Buttons.Primary compact onClick={this.openCompose}>
              {t('common:actions.composeNew')}
            </Buttons.Primary>
          )}
        </Actions>
      </div>
    );

    const dropdownCompose = (
      <div className="dropdown-menu notification-menu xlarge">
        <div className="notification-heading">
          <div onClick={this.closeCompose} className="close">
            &times;
          </div>
          {t('common:labels.composeNewMessage')}
        </div>
        <div className="content">
          <MessageCompose
            onChange={this.handleChangeMessage}
            recipient={this.state.recipient}
            onCancel={this.closeCompose}
            onSend={this.handleSendMessage}
          />
        </div>
      </div>
    );

    // Determine badge icon
    let badge = null;
    if (this.state.isSending) {
      badge = (
        <div className="notice small icon spinner">
          <Icon name="loading" />
        </div>
      );
    } else if (this.state.isComposing && !isOpen) {
      badge = (
        <div className="notice small icon draft">
          <Icon name="pencil" />
        </div>
      );
    } else if (this.props.notifications.unreadCount) {
      badge = <UnreadIndicator>{this.props.notifications.unreadCount}</UnreadIndicator>;
    }

    return (
      <div
        ref={(r) => (this._container = r)}
        style={{ position: 'relative' }}
        className={classNames({ open: isOpen })}
      >
        <HeaderIcon
          onClick={this.handleToggleDropdown.bind(this, true)}
          onKeyPress={(e) =>
            e.key === ' ' || e.key === 'Space' || e.key === 'Enter'
              ? this.handleToggleDropdown(true, e)
              : false
          }
          aria-label={`Messages, ${this.props.notifications.unreadCount} unread`}
          aria-expanded={isOpen}
          aria-controls="messages-dropdown-list"
          tabIndex={0}
        >
          <Icon name={isOpen ? 'envelope-open-o' : 'envelope-o'} />
          {badge}
        </HeaderIcon>
        {this.state.isComposing ? dropdownCompose : dropdownMenu}
      </div>
    );
  }
}

export default withTranslation(['common', 'commonPlat'])(MessagesDropdown);
