// Polyfills (These are for 3rd party libraries that lack them)
// Normally preset-env would pick them up and polyfill automatically
// but when they're in a node_modules folder it needs to be explicitly
// added to babel-loader config, however we haven't tracked down all
// deps that need these things (yet)
import 'core-js/features/object/assign';
import 'core-js/features/object/keys';
import 'core-js/features/object/values';

if (!Array.prototype.includes) require('core-js/features/array/includes');
if (!String.prototype.includes) require('core-js/features/string/includes');
if (!String.prototype.startsWith) require('core-js/features/string/starts-with');
import 'core-js/features/map';
import 'core-js/features/set';
import 'core-js/features/array';
import 'core-js/es/number';

import * as Sentry from '@sentry/react';
import config from '../config';
import rev from '../revision.json';

if (!window.location.hostname.startsWith('dev.')) {
  Sentry.init({ ...config.sentry, release: rev.revision });
  if (window.location.protocol === 'file:') {
    alert(
      `It appears that you have saved this page to your computer or mobile device, for security reasons you will not be able to submit any information and functionality may be extremely limited. Please visit us online at ${getWebHost()} for the full experience.`,
    );
  }
} else {
  console.info('Skipping Sentry in Dev Environment', window.location.protocol);
}

import Iso from 'iso';
import React from 'react';
import { hydrate } from 'react-dom';
//import { hydrateRoot } from 'react-dom/client';
import { Router, browserHistory, match } from 'react-router';
import { loadableReady } from '@loadable/component';

import altResolver from '../shared/alt-resolver';
import alt from './utils/alt';
import routes from './routes';
import Realtime from './realtime';

import AppActions from './actions/app';
import { getWebHost } from './utils/MultiDomain';

// Force the loading of the stores/actions chunk in webpack (otherwise we have nowhere to bootstrap)
import './stores/index';

// Internationalization support
import './i18n';

global.loadedFromServer = true;
global.process = { env: process.env };

// Avoid scrolling if we have a hash present, indicating we should scroll to a spot
const handleUpdate = () => {
  const { hash, pathname } = window.location;
  // Don't scroll on changing opportunity browsing params, don't scroll or trigger closing of modals on forms (avoids closing the draft choice modal on invalid draft id)
  if (
    pathname.startsWith('/opportunities/browse') ||
    pathname.startsWith('/forms/') ||
    pathname.includes('/manage/forms/submissions')
  )
    return;
  if (!hash || hash.trim().length === 0) {
    window.scrollTo(0, 0);
  }
  // Close modals just incase we navigated from links within them
  AppActions.setModal(false);
};

const buildRoutes = (initialState) => ({
  getChildRoutes({ location }, callback) {
    if (global.loadedFromServer) {
      global.loadedFromServer = false;
      return callback(null, routes);
    }

    // Skip bootstrapping for pages where we're replacing the querystring properties to drive filters, and we're just doing a replace on the URL
    if (
      (location.pathname === '/opportunities/browse' ||
        location.pathname.includes('/manage/forms')) &&
      location.action === 'REPLACE'
    ) {
      return callback(null, routes);
    }

    match({ location, routes }, (error, redirectLocation, renderProps) => {
      if (error) {
        console.log(error);
        return browserHistory.push('/dashboard?error=true');
      }
      if (redirectLocation) {
        return browserHistory.replace(redirectLocation);
      }
      altResolver(
        false, // No req object because client-side
        false, // No res object because client-side
        {
          routes: renderProps.routes,
          params: renderProps.params,
          location: renderProps.location,
          state: initialState,
        },
        (nextState) => {
          alt.bootstrap(JSON.stringify(nextState));
          callback(null, routes);
        },
      );
    });
  },
});

Iso.bootstrap((state, node) => {
  // Bootstrap data into client Alt
  alt.bootstrap(state);

  loadableReady(() => {
    /* Broken, pages don't seem to initialize properly/no client side navigation, no errors noted in console either
   
  hydrateRoot(
    node,
    <Router onUpdate={handleUpdate} history={browserHistory} routes={buildRoutes(state)} />,
  );*/

    hydrate(
      <Router onUpdate={handleUpdate} history={browserHistory} routes={buildRoutes(state)} />,
      node,
    );

    global.loadedFromServer = false;

    // Make sure we only initialize the realtime system once
    if (typeof global.dsgRealtime === 'undefined') {
      global.dsgRealtime = new Realtime();
    }
  });
});
