import React, { PureComponent } from 'react';
import Link from '@dosomegood/platform/dist/components/buttons/Link';
import classNames from 'classnames';

import MobileMenuItem from './mobileMenuItem';

import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import PlatformTicker from '../../social/ticker/Platform';

export default class MobileMenuGroupedItems extends PureComponent {
  state = {
    openWidget: false,
  };

  toggleOpen = (widget, event) => {
    event.stopPropagation();
    this.setState({ openWidget: this.state.openWidget !== widget.title ? widget.title : false });
  };

  handleItemClick = (item, event) => {
    if (item.items && item.items.length > 0) {
      event && event.preventDefault();
      this.setState({ openWidget: this.state.openWidget === item.title ? false : item.title });
    } else {
      this.props.hideOverflow && this.props.hideOverflow();
    }
  };

  render() {
    const { manage = [], options = [] } = this.props;

    let widgets = (menuItems) =>
      menuItems.map((widget, idx) => {
        const { items = [] } = widget;

        // Widget Classes
        let widgetClasses = {
          'nav-parent': items.length,
          'nav-expanded': this.state.openWidget === widget.title,
          'nav-active': this.state.openWidget === widget.title,
        };
        widget.type ? (widgetClasses['type-' + widget.type] = true) : false;
        let widgetOpen = classNames(widgetClasses);

        return (
          <li className={widgetOpen} key={idx}>
            <Link to={widget.route} onClick={this.handleItemClick.bind(this, widget)}>
              <Icon name={widget.icon} />
              <span className="truncate">{widget.title}</span>
            </Link>
            <ul className="nav nav-children">
              {items.map((option, idx) => (
                <MobileMenuItem {...option} key={idx} onClick={this.props.hideOverflow} />
              ))}
            </ul>
          </li>
        );
      });

    return (
      <ul id="mobile-overflow" className={this.props.className}>
        <ul className="menu-ticker">
          <PlatformTicker />
        </ul>
        <ul className="menu-manage">{widgets(manage)}</ul>
        <ul className="menu-options">{widgets(options)}</ul>
      </ul>
    );
  }
}
