import React from 'react';
import Link from '@dosomegood/platform/dist/components/buttons/Link';
import ReactEmoji from 'react-emoji';

const linkify = require('linkifyjs'); // 'import' doesn't work

import AppStore from 'stores/app';
import AppActions from 'actions/app';

import Picture from 'components/widgets/Picture';
import LeaveTestimonialModal from 'components/modals/LeaveTestimonial';

const Utils = {
  isAdminOfType(type, slug) {
    const relationship = this.relationshipTo(type, slug);
    return relationship?.relation.toLowerCase() === 'administrator';
  },

  isAdminOrLimitedAdminOfType(type, slug) {
    const relationship = this.relationshipTo(type, slug);
    return ['administrator', 'limited admin'].includes(relationship?.relation.toLowerCase());
  },

  relationshipTo(type, slug, altUser) {
    const { user: { relations = [] } = {} } = AppStore.getState();
    return relations.find((r) => r.target?.slug === slug && r.target.type === type);
  },

  relationshipsOfType(type, altUser) {
    const user = altUser || AppStore.getState().user || {};
    return user.relations?.filter((rel) => rel?.type?.toLowerCase() === type.toLowerCase()) || [];
  },

  relationshipsOfAccessLevel(type, altUser) {
    const user = altUser || AppStore.getState().user || {};
    return (
      user.relations?.filter((rel) => rel?.relation?.toLowerCase() === type.toLowerCase()) || []
    );
  },

  relationshipTypeToId(type = '') {
    switch (type.toLowerCase()) {
      case 'administrator':
        return 2;
      case 'limited-admin':
      case 'limited admin':
        return 3;
      case 'employee':
        return 5;
      case 'member':
        return 6;
      case 'contributor':
        return 7;
      case 'unverified contributor':
        return 8;
      case 'follower':
        return 10;
      default:
        return 10;
    }
  },

  imageUrlForQuality(file, quality, ext) {
    if (typeof file !== 'object') return false;
    ext = ext || 'jpg';
    if (quality === 'logo') ext = 'png';
    return '//' + file.bucket + '/' + file.baseFile + '_' + quality.toLowerCase() + '.' + ext;
  },

  _leaveTestimonial(user, orgSlug) {
    const appState = AppStore.getState();

    // TODO: Replace with new modal that doesn't include the legacy business/org testimonial stuff
    AppActions.setModal({
      component: LeaveTestimonialModal,
      options: {
        recipientType: 'user',
        recipientId: user.hash,
        recipientName: `${user.firstName} ${user.lastName}`,
        sourceType: 'organization',
        sourceId: orgSlug,
        user,
        admin: appState && appState.user,
        onComplete: (state, res, onSuccess) => onSuccess(),
        onRemove: () => {},
        onError: () => {},
      },
    });
  },

  // TODO: Refactor to use Wade's custom parser in the Platform repository
  // Takes in a raw string and produces react array with formatted content
  formatParagraphs(
    content = '',
    editCount = 0,
    images = [],
    allowTags = false,
    plainText = false,
    allowEmoticons = false,
  ) {
    function forceAscii(input) {
      var output = '';
      for (var i = 0; i < input.length; i++) {
        if (input.charCodeAt(i) <= 127) {
          output += input.charAt(i);
        }
      }
      return output;
    }

    // Start at the current editCount and add four 0's so we don't have collisions across re-renders
    let seq = editCount * 10000;

    // Image sequencing
    let iSeq = 0;

    const getImage = (s) => {
      if (Array.isArray(images) && images.length > s) {
        return this.imageUrlForQuality(images[s], 'medium');
      } else {
        return null;
      }
    };

    let paragraphsFormatted = [];

    // Split into paragraphs by line breaks
    content.split('\n').forEach((paragraph) => {
      // Pull out specially formatted links to other people's profiles @[Name Here](type:identifier),
      // grab special formatting groups, or just tokenize by spaces.
      const mentions = /(@\[[^@]+\]\(\w+:[\w\d-]+\)|\[[\w\d]+\].+\[\/[\w\d]+\])/g;
      let paraSplit = [];
      paragraph.split(mentions).forEach((s) => {
        if (mentions.test(s)) return paraSplit.push(s);
        let w = s.split(' ');
        for (let i = w.length; i-- > 1; ) {
          w.splice(i, 0, ' ');
        }
        paraSplit = [...paraSplit, ...w];
      });

      const contentArray = paraSplit
        .filter((p) => p !== '')
        .map((piece) => {
          // Is this a link?
          if (linkify.test(piece)) {
            const isEmail = linkify.test(piece, 'email');

            // Limit length of <a> content:
            let displayName = forceAscii(piece);
            if (displayName.length > 32) displayName = displayName.substr(0, 32) + '...';

            // Determine link prefix:
            let prefix = 'http://';
            if (piece.indexOf('http://') === 0 || piece.indexOf('https://') === 0) {
              prefix = '';
            } else if (isEmail) {
              prefix = 'mailto:';
            }

            return plainText ? (
              piece
            ) : (
              <a
                key={seq++}
                href={prefix + forceAscii(piece)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {displayName}
              </a>
            );
          } else if (piece.substring(0, 1) === '@' && piece.substring(piece.length - 1) === ')') {
            // Parse out links to people's profiles

            const match = /@\[([^@]+)\]\((\w+):([\w\d-]+)\)/g.exec(piece);
            if (!match) return piece;
            const displayName = match[1].trim();
            const type = match[2];
            const link = match[3];

            switch (type) {
              case 'user':
                return plainText ? (
                  displayName
                ) : (
                  <strong key={seq++}>
                    <Link to={`/user/profile/${link}`}>{displayName}</Link>
                  </strong>
                );

              default:
                return plainText ? (
                  displayName
                ) : (
                  <strong key={seq++}>
                    <Link to={`/${type}/${link}`}>{displayName}</Link>
                  </strong>
                );
            }
          } else {
            // Normal content piece

            const formatting = /\[([\w\d]+)\](.+)\[\/[\w\d]+\]/;
            const formattedText = piece.match(formatting);
            if (!formattedText) return piece;

            const tag = formattedText[1];
            const text = formattedText[2];

            if (plainText) return tag !== 'im' ? text : '';
            if (allowTags) {
              switch (tag) {
                case 'im':
                  return (
                    <Picture
                      key={seq++}
                      src={getImage(parseInt(iSeq++))}
                      align={text}
                      shape="square"
                      className="p-md"
                    />
                  );
                case 'h1':
                  return <h1 key={seq++}>{text}</h1>;
                case 'h2':
                  return <h2 key={seq++}>{text}</h2>;
                case 'h3':
                  return <h3 key={seq++}>{text}</h3>;
                case 'h4':
                  return <h4 key={seq++}>{text}</h4>;
                case 'h5':
                  return <h5 key={seq++}>{text}</h5>;
                case 'h6':
                  return <h6 key={seq++}>{text}</h6>;
                case 'b':
                  return <strong key={seq++}>{text}</strong>;
                case 'i':
                  return <em key={seq++}>{text}</em>;
              }
              return text;
            } else {
              return piece;
            }
          }
        });

      let contentFormatted = [];
      contentArray.forEach((piece) => {
        if (typeof piece === 'string') {
          contentFormatted.push(
            plainText ? piece : ReactEmoji.emojify(piece, { useEmoticon: allowEmoticons }),
          );
        } else {
          contentFormatted.push(piece);
        }
      });

      // Put the paragraph on the stack
      if (contentFormatted.length) {
        paragraphsFormatted.push(
          plainText ? (
            contentFormatted.join('')
          ) : (
            <div className="paragraph" key={seq++}>
              {contentFormatted}
            </div>
          ),
        );
      }
    });

    return plainText ? paragraphsFormatted.join('\n') : paragraphsFormatted;
  },

  makeQueryString(query, skipFirst = false) {
    function removeByKey(myObj, deleteKey) {
      return Object.keys(myObj)
        .filter((key) => key !== deleteKey)
        .reduce((result, current) => {
          result[current] = myObj[current];
          return result;
        }, {});
    }

    let qs = '';
    let i = 0;
    Object.keys(removeByKey(query || {}, 'nextPath')).forEach((k) => {
      const s = !skipFirst && i === 0 ? '?' : '&';
      qs += `${s}${k}=${(query || {})[k]}`;
      i++;
    });
    return qs;
  },
};

export default Utils;
export { Utils };
