import React from 'react';

import AppStore from '../stores/app';
import FullExperience from './FullExperience';
import GuestExperience from './GuestExperience';
import { useStore } from '../utils/alt';

/**
 * Adapts the navigation structures based on having a logged in or guest user
 */
function AdaptiveExperience(props) {
  const [{ user, usingProxyUser }] = useStore(AppStore);
  if (user) return <FullExperience {...props} user={user} usingProxyUser={usingProxyUser} />;
  return <GuestExperience {...props} />;
}

export default AdaptiveExperience;
