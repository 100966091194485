import React from 'react';
import styled from 'styled-components';

import HeaderBar from './header/Header';
import ErrorBoundary from './ErrorBoundary';
import { CollectionItemHeader } from '@dosomegood/platform/dist/components/typography/Content';
import { SuspenseSSR } from './ui/SuspenseSSR';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  background: ${(props) => props.theme.brandColors.darkerBackground};
  width: 100%;
  padding-top: 94px;
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  background: ${(props) => props.theme.brandColors.lightestBackground};
  box-shadow: 0px 0px 50px 2px rgba(0, 0, 0, 0.8);

  transition: max-width 0.5s;
`;

const MobileWarning = styled.div`
  display: none;
  ${(props) => props.theme.media.phone`display: block;`};
  margin-bottom: 2px;
  padding: ${(props) => props.theme.margins.xs};
  background-color: ${(props) => props.theme.brandColors.statusColors.warning};
  & > span {
    color: white !important;
  }
`;

export default function ManagementExperience({
  children,
  user,
  routes,
  location,
  params,
  usingProxyUser,
  ...props
}) {
  const { t } = useTranslation(['common']);
  return (
    <Container>
      <HeaderBar
        routes={routes}
        location={location}
        params={params}
        user={user}
        usingProxyUser={usingProxyUser}
        management
      />
      {/* 1300px page container + 160px sidebar + 33px of padding */}
      <ContentWrapper
        style={location.query.fw === undefined ? { maxWidth: 1493 } : { maxWidth: 5120 }}
      >
        <MobileWarning>
          <CollectionItemHeader>{t('common:alerts.managementMobileWarning')}</CollectionItemHeader>
        </MobileWarning>
        <ErrorBoundary>
          <SuspenseSSR>
            {React.cloneElement(children, { user, routes, location, params, ...props })}
          </SuspenseSSR>
        </ErrorBoundary>
      </ContentWrapper>
      <audio id="notificationSound" preload="auto">
        <source src="/assets/media/notification.mp3" type="audio/mp3" />
        <source src="/assets/media/notification.ogg" type="audio/ogg" />
      </audio>
    </Container>
  );
}
