/**
 * Builds a full react-router location object from an invite's context
 * (Used after having logged in and viewing the invite that was accepted)
 *
 * @param {*} invite
 * @param {*} location
 */
export function getContextLocationFromInvite(invite, location) {
  if (!invite) return `/dashboard`;
  const { context } = invite;
  const { target: { slug: targetSlug } = {}, targetType } = invite;
  if (!context || !context.trigger || !targetSlug || !targetType) {
    if (location.query && location.query.welcome) return `/user/welcome`;
    return `/dashboard`;
  }
  switch (context.trigger) {
    case 'relationship':
      return { pathname: `/${targetType}/${targetSlug}/manage/profile`, query: { welcome: true } };
    case 'requestTestimonial':
      return {
        pathname: `/${targetType}/${targetSlug}/manage/relationships/testimonials`,
        query: { welcome: true },
      };
    case 'volunteerProfile':
      return {
        pathname: `/${targetType}/${targetSlug}/manage/volunteers`,
        query: { welcome: true },
      };
  }
  if (location.query && location.query.welcome) return `/user/welcome`;
  return `/dashboard`;
}

/**
 * Builds a full react-router location object from a DSG invite object (optional)
 * plus an optional known return location, and the mode (signup/login)
 *
 * @param invite
 * @param location
 * @param mode
 * @returns
 */
export function getNextLocation(invite, location, mode) {
  let queryAdditions = {};
  if (mode === 'signup') queryAdditions.welcome = true;
  if (mode === 'login') queryAdditions.returning = true;
  const defaultLocation =
    mode === 'signup' ? { pathname: `/user/welcome` } : { pathname: '/dashboard' };
  const { state = {} } = location;
  let nextLocation = state.from;
  if (invite && !nextLocation) {
    switch (invite.role) {
      case 'business':
        nextLocation = {
          pathname: `/registration/business/1`,
          query: { token: location.query.token },
        };
        break;
      case 'organization':
        nextLocation = {
          pathname: `/registration/organization`,
          query: { token: location.query.token },
        };
        break;
      case 'employee':
      case 'member':
      case 'contributor':
      case 'follower':
      case 'limited admin':
      case 'limited-admin':
      case 'administrator':
        nextLocation = {
          pathname: `/accept-invite/${location.query.token}`,
        };
        break;
      default:
        // If they're signing up, send to dedicated welcome experience
        if (mode === 'signup') {
          nextLocation = { pathname: `/user/welcome` };
        } else {
          nextLocation = { pathname: `/dashboard` };
        }
    }
  }
  // Fallback to the provided location or the default one
  nextLocation = nextLocation || defaultLocation;
  return {
    ...nextLocation,
    query: { ...(nextLocation.query || {}), ...queryAdditions },
  };
}

export function getPathName(location) {
  const { state = {} } = location;
  if (state.from && state.from.pathname) {
    const { pathname } = state.from;
    switch (pathname) {
      case '/registration/organization':
        return 'registerOrganization';
      case '/registration/business/1':
        return 'registerBusiness';
    }
    const routes = pathname.split('/').filter((p) => !!p);
    if (routes[0] === 'request-for-donations') {
      return 'requestDonations';
    } else if (routes[0] === 'forms') {
      return 'fillForm';
    } else if (
      routes[0] === 'registration' &&
      routes[1] === 'business' &&
      routes[2] === 'payment'
    ) {
      return 'payment';
    }
  }
  return 'default';
}

export function getOnboardingType(invite, location) {
  if (invite) return 'invite';
  if (location.query.cx) return 'referral';
  return 'direct';
}

export function getOnboardingReferral(location) {
  return {
    type: getPathName(location),
    context: {
      trigger: location.query.cx,
    },
  };
}

export function getOnboardingDirect(location, context = {}) {
  return {
    type: getPathName(location),
    context,
  };
}
