import React, { useCallback, useState } from 'react';
import Link from '@dosomegood/platform/dist/components/buttons/Link';

import { api as API } from '../../mixins/api';

import AlertBlock from '../widgets/AlertBlock';

import SoloContent from '@dosomegood/platform/dist/components/content/onboarding/SoloContent';
import Panel from '@dosomegood/platform/dist/components/layout/Panel';
import Buttons from '@dosomegood/platform/dist/components/buttons/index';
import { Form, FormActions, FormControl } from '@dosomegood/platform/dist/components/inputs/Form';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import EmailInput from '@dosomegood/platform/dist/components/inputs/EmailInput';
import { usePageTitle } from '../../utils/Hooks';
import { useTranslation } from 'react-i18next';

export default function ForgotPassword({}) {
  const { t } = useTranslation(['common', 'commonPlat']);
  const [success, setSuccess] = useState(false);
  usePageTitle(t('common:nav.resetPassword'));

  const handleSubmit = useCallback(({ email }, _, __, hsr) => {
    API.makeRequest(
      '/user/forgot-password',
      'POST',
      {
        email,
      },
      hsr((res) => {
        if (res) {
          setSuccess(true);
        }
      }),
    );
  }, []);

  return (
    <SoloContent>
      <Panel>
        {success ? (
          <AlertBlock
            icon="check-circle"
            title={t('common:labels.success')}
            text={t('common:alerts.resetInstructionsSentInbox')}
            style="success"
          />
        ) : (
          <Paragraph>{t('common:labels.forgotPasswordIntro')}</Paragraph>
        )}

        <Form id="reset-password" onSubmit={handleSubmit}>
          <FormControl label={t('commonPlat:labels.emailAddress')}>
            <EmailInput id="email" required />
          </FormControl>

          <FormActions
            alternateActions={
              <>
                {t('common:labels.rememberedQues')}
                <br />
                <Link to="/login">{t('common:actions.signInExcl')}</Link>
              </>
            }
          >
            <Buttons.Primary type="submit" disabled={success}>
              {t('commonPlat:actions.submit')}
            </Buttons.Primary>
          </FormActions>
        </Form>
      </Panel>
      <p className="text-center text-muted mt-md mb-md">
        © {new Date().getFullYear()} Do Some Good Community Contribution Company Inc. All Rights
        Reserved.
      </p>
    </SoloContent>
  );
}
