import { api as API } from '../mixins/api';
import { useFetchWithAPI } from '@dosomegood/platform/dist/utils/Hooks';

export function useFetchPut(endpoint, query, whenChanged, callbackAfterFetch) {
  return useFetchWithAPI(API, endpoint, query, 'PUT', whenChanged, callbackAfterFetch);
}

export function useFetch(endpoint, query, whenChanged, callbackAfterFetch) {
  return useFetchWithAPI(API, endpoint, query, 'GET', whenChanged, callbackAfterFetch);
}

export function useFetchWithInitialData(endpoint, query, whenChanged, initialData) {
  return useFetchWithAPI(API, endpoint, query, 'GET', whenChanged, () => {}, initialData);
}
