import React from 'react';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import { TertiaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import styled, { css } from 'styled-components';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';

const Container = styled.div`
  padding: ${(props) => props.theme.margins.xs};
  margin-bottom: ${(props) => props.theme.margins.xs};
  ${(props) => props.theme.borders.innerInvisible};
  ${(props) =>
    props.$info &&
    css`
      background-color: ${props.theme.brandColors.statusColors.notice};
    `};
  ${(props) =>
    props.$warning &&
    css`
      background-color: ${props.theme.brandColors.statusColors.warning};
      & * {
        color: white !important;
      }
    `};
  ${(props) =>
    props.$success &&
    css`
      background-color: ${props.theme.brandColors.statusColors.success};
      & * {
        color: white !important;
      }
    `};
`;

export default function AlertBlock({ icon, title, text, style, className }) {
  const iconComponent = !!icon ? <Icon name={icon} style={{ marginRight: 6 }} /> : null;

  let titleComponent = null;
  if (title) {
    titleComponent = (
      <TertiaryHeader>
        {iconComponent} {title}
      </TertiaryHeader>
    );
  }

  const content = (
    <>
      {titleComponent || iconComponent}
      {text ? <Paragraph> {text}</Paragraph> : null}
    </>
  );

  return (
    <Container
      $info={style === 'info'}
      $warning={style === 'warning'}
      $success={style === 'success'}
      className={className}
    >
      {content}
    </Container>
  );
}
