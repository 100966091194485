import AppStore from '../stores/app';

const analytics = {
  _enableTracking() {
    // Only track client-side:
    if (typeof window !== 'object') return false;

    // Don't track proxied users:
    if (AppStore.getState().usingProxyUser) return false;

    return true;
  },

  boot: function () {
    if (!this._enableTracking()) return;

    // No longer needed with enhanced measurement feature in gtag
    /*if (typeof window.ga !== 'undefined') {
      window.ga('send', 'pageview');
    }*/
  },

  trackEvent: function (eventName, metadata = {}) {
    if (!this._enableTracking()) return;
    if (!eventName) return;

    // Google Analytics
    const { action, label, value } = metadata;
    if (typeof gtag !== 'undefined' && action !== 'undefined') {
      // OLD: ga('send', 'event', eventName, action, label, value);

      gtag('event', action, {
        event_category: eventName,
        event_label: label,
        value: value,
      });
    }
  },
};

export default { analytics };
export { analytics };
