import React, { useCallback } from 'react';

import { SecondaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import { Form, FormActions, FormControl } from '@dosomegood/platform/dist/components/inputs/Form';
import TextInput from '@dosomegood/platform/dist/components/inputs/TextInput';
import ConnectedRegionSelect from '@dosomegood/platform/dist/components/inputs/connected/RegionSelect';
import { api as API } from 'mixins/api';
import { useTranslation } from 'react-i18next';
import { ModalContainer } from '../ui/ModalComponents';

function CreateCityModal({ onCancel, options }) {
  const { t } = useTranslation(['common', 'commonPlat']);
  const {
    onComplete,
    confirmTitle = t('common:actions.addThisLocation'),
    cancelTitle = t('commonPlat:actions.cancel'),
  } = options;

  const handleCancel = useCallback(() => {
    if (options.onCancel) options.onCancel();
    if (onCancel) onCancel();
  }, [onCancel, options.onCancel]);

  const handleSubmit = useCallback(
    ({ city, region }, _, __, hsr) => {
      API.makeRequest(
        `/cities`,
        'POST',
        { city, region: region.id, regionName: region.name, countryCode: region.countryCode },
        hsr((res) => {
          if (res) {
            onComplete({ ...res, city: res.name, region: region.name, country: res.countryCode });
            onCancel();
          }
        }),
      );
    },
    [onComplete, onCancel],
  );

  return (
    <ModalContainer>
      <SecondaryHeader>{t('common:labels.addMissingCityOrTown')}</SecondaryHeader>
      <Form
        id="create-city"
        name="create-city"
        defaultValue={{ city: options.city || '' }}
        onSubmit={handleSubmit}
      >
        <FormControl label={t('common:labels.nameCityTown')}>
          <TextInput id="city" required />
        </FormControl>
        <FormControl label={t('common:labels.provinceTerrState')}>
          <ConnectedRegionSelect id="region" required />
        </FormControl>
        <FormActions>
          <Buttons.Alternate onClick={handleCancel}>{cancelTitle}</Buttons.Alternate>
          <Buttons.Primary type="submit">{confirmTitle}</Buttons.Primary>
        </FormActions>
      </Form>
    </ModalContainer>
  );
}

export default CreateCityModal;
