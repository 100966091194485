import React, { useCallback } from 'react';
import Link from '@dosomegood/platform/dist/components/buttons/Link';

import { api as API } from '../../mixins/api';
import { performLogin } from '../../mixins/login';

import SoloContent from '@dosomegood/platform/dist/components/content/onboarding/SoloContent';
import Panel from '@dosomegood/platform/dist/components/layout/Panel';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import { Form, FormActions, FormControl } from '@dosomegood/platform/dist/components/inputs/Form';
import Buttons from '@dosomegood/platform/dist/components/buttons/index';
import PasswordInput from '@dosomegood/platform/dist/components/inputs/PasswordInput';
import { usePageTitle } from '../../utils/Hooks';
import { useTranslation } from 'react-i18next';

export default function ResetPassword({ params }) {
  const { t } = useTranslation(['common', 'commonPlat']);
  usePageTitle(t('common:nav.resetPassword'));

  const handleSubmit = useCallback(
    ({ password, confirmPassword }, _, __, hsr) => {
      if (password !== confirmPassword) {
        return hsr(() => true)(null, {
          body: { errors: [{ password: t('common:errors.passwordsDontMatch') }] },
        });
      }
      API.makeRequest(
        '/user/reset-password',
        'POST',
        {
          password,
          token: params.token,
        },
        hsr((res) => {
          if (res) {
            performLogin(res.user.email, password, '/dashboard');
          }
        }),
      );
    },
    [params],
  );

  return (
    <SoloContent>
      <Panel>
        <Paragraph>{t('common:labels.resetPasswordIntro')}</Paragraph>

        <Form name="reset-password" onSubmit={handleSubmit}>
          <FormControl label={t('common:labels.enterNewPassword')}>
            <PasswordInput id="password" required />
          </FormControl>
          <FormControl label={t('common:labels.reTypeNewPassword')}>
            <PasswordInput id="confirmPassword" required />
          </FormControl>

          <FormActions
            alternateActions={
              <>
                {t('common:labels.rememberedQues')}
                <br />
                <Link to="/dashboard">{t('common:actions.signInExcl')}</Link>
              </>
            }
          >
            <Buttons.Primary type="submit">{t('common:actions.setNewPassword')}</Buttons.Primary>
          </FormActions>
        </Form>
      </Panel>
      <p className="text-center text-muted mt-md mb-md">
        © {new Date().getFullYear()} Do Some Good Community Contribution Company Inc. All Rights
        Reserved.
      </p>
    </SoloContent>
  );
}
