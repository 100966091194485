import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class ErrorDisplay extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    errors: PropTypes.array,
  };

  render() {
    if (!this.props.errors) return false;

    const errorList = this.props.errors.filter((value) => value[this.props.name]);
    const error = errorList.length ? errorList[0][this.props.name] : false;

    return error ? (
      <div
        id={`error-${this.props.name}`}
        style={{ color: 'red' }}
        className={classNames('alert alert-danger', this.props.className)}
      >
        <span>{error}</span>
      </div>
    ) : (
      false
    );
  }
}
