import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '@dosomegood/platform/dist/components/buttons/Link';

import NotificationStore from '../../../stores/notification';

import connectToStores from 'alt-utils/lib/connectToStores';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import _UnreadIndicator from '@dosomegood/platform/dist/components/ui/UnreadIndicator';
import styled from 'styled-components';
import { limitCharacters } from '@dosomegood/platform/dist/utils/String';

const UnreadIndicator = styled(_UnreadIndicator)`
  position: absolute;
  top: -5px;
  right: -5px;
`;
const InnerUnreadIndicator = styled(_UnreadIndicator)`
  position: absolute;
  top: -5px;
  right: 5px;
`;

@connectToStores
class Affiliations extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static getStores() {
    return [NotificationStore];
  }

  static getPropsFromStores() {
    return { ns: NotificationStore.getState() };
  }

  state = {
    isOpen: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleDropdown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClickOutside = (event) => {
    const node = this._container;
    if (!node.contains(event.target)) {
      if (this.state.isOpen) this.setState({ isOpen: false });
    }
  };

  render() {
    const notificationStore = this.props.ns;

    const findUnread = (notificationStore.unreadManagement || []).filter(
      (u) => u.type === this.props.value.type && u.slug === this.props.value.slug,
    );
    const unreadCount = findUnread && findUnread.length ? findUnread[0].count : 0;
    // Generate subitems pr affiliation:
    const sublinks = this.props.value.items.map((item, idx) => {
      const indicator = item.type === 'management' && (
        <InnerUnreadIndicator>{unreadCount}</InnerUnreadIndicator>
      );

      const isActive = !!item.route && this.context.router.isActive(item.route);
      const value = (
        <>
          <Icon name={item.icon} inverse={isActive} /> {item.title} {indicator}
        </>
      );

      const action = (e) => {
        this.handleClickOutside(e);
        item.onClick && item.onClick(e);
      };

      return (
        <li key={idx} className={classNames({ active: isActive })} style={{ position: 'relative' }}>
          {item.disabled ? (
            <div className="text-muted">
              {value} <small className="pull-right">(in development)</small>
            </div>
          ) : (
            <Link
              to={item.route}
              onClick={action}
              onKeyPress={(e) =>
                e.key === ' ' || e.key === 'Space' || e.key === 'Enter' ? action(e) : false
              }
            >
              {value}
            </Link>
          )}
        </li>
      );
    });

    const dropdownMenu = (
      <div className="dropdown-menu notification-menu light" id="manage-dropdown">
        <ul className="dropdown-menu-items">{sublinks}</ul>
      </div>
    );

    return (
      <li
        ref={(r) => (this._container = r)}
        id="nav-manage"
        className={classNames({ 'btn-group': true, open: this.state.isOpen, clickable: true })}
        onClick={this.toggleDropdown}
        onKeyPress={(e) =>
          e.key === ' ' || e.key === 'Space' || e.key === 'Enter' ? this.toggleDropdown(e) : false
        }
        role="button"
        tabIndex={0}
        aria-label="Manage"
        aria-controls="manage-dropdown"
        aria-expanded={this.state.isOpen}
      >
        <UnreadIndicator>{unreadCount}</UnreadIndicator>
        <Icon name={this.props.value.icon} /> {limitCharacters(this.props.value.title, 24)}
        <i className="fa fa-caret-down ml-sm" />
        {dropdownMenu}
      </li>
    );
  }
}

export default Affiliations;
