import React, { PureComponent } from 'react';
import ReactUpdate from 'react-addons-update';
import { withTranslation } from 'react-i18next';

import UserManagementActions from '../../actions/userManagement';
import HourActions from '../../actions/hour';

import { api as API } from '../../mixins/api';

import { ModalContainer } from '../ui/ModalComponents';
import Buttons from '@dosomegood/platform/dist/components/buttons/index';
import { Form, FormActions, FormControl } from '@dosomegood/platform/dist/components/inputs/Form';
import { SecondaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import { CollectionViewLoadingIndicator } from '@dosomegood/platform/dist/components/ui/LoadingIndicator';
import TestimonialInput from '@dosomegood/platform/dist/components/inputs/TestimonialInput';
import { CollectionItemDetail } from '@dosomegood/platform/dist/components/typography/Content';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';

class LeaveTestimonialModal extends PureComponent {
  static defaultProps = {
    visible: true,
    options: {
      recipientType: 'user',
      admin: {},
    },
  };

  state = {
    loading: true,
    confirmed: false,
    testimonials: [],
    testimonial: '',
  };

  componentDidMount() {
    const findTestimonials = (t) => {
      return (t || [])
        .filter((t) => t.business.slug === this.props.options.recipientId)
        .map((t) => {
          return {
            testimonial: t.testimonial,
            creator: t.creator,
            created: t.created,
            source: t.organization,
          };
        });
    };

    const endpointUrl =
      this.props.options.recipientType === 'user'
        ? `/user/${this.props.options.recipientId}/testimonials`
        : `/${this.props.options.sourceType}/${this.props.options.sourceId}/relationships`;

    if (this.props.options.testimonials) {
      setTimeout(
        () =>
          this.setState({
            testimonials: findTestimonials(this.props.options.testimonials),
            testimonial: this.props.options.testimonial,
            loading: false,
          }),
        50,
      );
    } else {
      API.makeRequest(endpointUrl, 'GET', {}, (err, res) => {
        if (!err && res && res.ok) {
          switch (this.props.options.recipientType) {
            case 'user':
              this.setState({ testimonials: res.body || [] });
              break;

            case 'business':
            case 'organization':
              const testimonials = findTestimonials(res.body.testimonials);
              this.setState({ testimonials });
              break;

            default:
              console.error('Unexpected recipientType');
          }
        }
        this.setState({ loading: false });
      });
    }
  }

  closeModal = () => {
    this.props.onCancel();
  };

  handleCallback = () => {
    if (typeof this.props.options.onComplete === 'function') {
      const payload = {
        [this.props.options.sourceType]: this.props.options.sourceId,
        testimonial: this.state.testimonial,
      };
      const endpointUrl = `/testimonial/${this.props.options.recipientType}/${this.props.options.recipientId}`;

      if (!payload.testimonial.length)
        return this.props.options.onComplete(this.state, this.onSuccess, this.onError);

      API.makeRequest(endpointUrl, 'PUT', payload, (err, res) => {
        if (err || res.body.errors || res.body.error) return this.onError(err || res.body);
        this.props.options.onComplete(this.state, res?.body, this.onSuccess, this.onError);
        if (this.props.options.recipientType === 'user' && this.props.options.user) {
          UserManagementActions.changeUser(
            ReactUpdate(this.props.options.user, { $merge: { hasMyTestimonial: true } }),
          );
          HourActions.updateHourUser(
            ReactUpdate(this.props.options.user, { $merge: { hasMyTestimonial: true } }),
          );
        }
      });
    } else {
      this.closeModal();
    }
  };

  onSuccess = () => {
    this.closeModal();
  };

  onError = (err) => {
    this.props.options.onError && this.props.options.onError(err);
    this.closeModal();
  };

  handleReplaceTestimonial = () => {
    this.setState({ testimonials: [] });
  };

  handleUpdateTestimonial = ({ testimonial }) => {
    this.setState({ testimonial });
  };

  handleRemoveTestimonial = () => {
    const endpointUrl = `/testimonial/${this.props.options.recipientType}/${this.props.options.recipientId}`;
    const payload = {
      [this.props.options.sourceType]: this.props.options.sourceId,
    };

    API.makeRequest(endpointUrl, 'DELETE', payload, (err, res) => {
      if (err || res.body.errors || res.body.error) return this.onError(err || res.body);
      this.props.options.onRemove && this.props.options.onRemove(this.props.options);
      this.setState({ testimonials: [] });
      this.closeModal();
      if (this.props.options.recipientType === 'user' && this.props.options.user) {
        UserManagementActions.changeUser(
          ReactUpdate(this.props.options.user, { $merge: { hasMyTestimonial: false } }),
        );
        HourActions.updateHourUser(
          ReactUpdate(this.props.options.user, { $merge: { hasMyTestimonial: false } }),
        );
      }
    });
  };

  render() {
    const { t, options } = this.props;
    const recipientName = (options.recipientName || '').trim();

    const currentTestimonial = this.state.testimonials.find((t) => {
      return (
        t.source.slug === options.sourceId &&
        (options.recipientType !== 'user' || t.creator.hash === options.admin.hash)
      );
    });

    const form = (
      <Form
        id="leave-testimonial"
        value={{ testimonial: this.state.testimonial }}
        onChange={this.handleUpdateTestimonial}
      >
        <FormControl label={t('commonPlat:labels.testimonial')}>
          <TestimonialInput
            id="testimonial"
            required
            maxLength={1000}
            placeholder={t('oMgmt:placeholders.testimonialInput', { name: recipientName })}
          />
        </FormControl>
        <CollectionItemDetail>
          <Icon name="info" />{' '}
          {t('oMgmt:labels.yourTestimonialWillAppearOnNamesProfile', { name: recipientName })}
        </CollectionItemDetail>
      </Form>
    );

    const existing = !!currentTestimonial && (
      <div>
        <h6>{t('oMgmt:labels.youveAlreadyWrittenTestimonial')}</h6>
        <blockquote>
          {currentTestimonial.testimonial}
          <small>
            {currentTestimonial.creator.name ||
              currentTestimonial.creator.firstName + ' ' + currentTestimonial.creator.lastName}
          </small>
        </blockquote>
        <Buttons.Alternate icon="trash" onClick={this.handleRemoveTestimonial}>
          {t('commonPlat:actions.delete')}
        </Buttons.Alternate>
        <Buttons.Alternate onClick={this.handleReplaceTestimonial}>
          {t('sMgmt:actions.replaceWithNewTestimonial')}
        </Buttons.Alternate>
      </div>
    );

    return (
      <ModalContainer>
        <SecondaryHeader>{options.title || t('commonPlat:labels.testimonial')}</SecondaryHeader>
        {this.state.loading ? (
          <CollectionViewLoadingIndicator />
        ) : currentTestimonial ? (
          existing
        ) : (
          form
        )}
        <FormActions>
          <Buttons.Alternate onClick={this.closeModal}>
            {options.cancelCaption ||
              (!currentTestimonial
                ? t('commonPlat:actions.cancel')
                : t('commonPlat:actions.close'))}
          </Buttons.Alternate>
          {!currentTestimonial && (
            <Buttons.Primary onClick={this.handleCallback}>
              {options.confirmCaption || t('commonPlat:actions.save')}
            </Buttons.Primary>
          )}
        </FormActions>
      </ModalContainer>
    );
  }
}

export default withTranslation(['sMgmt', 'oMgmt', 'commonPlat'])(LeaveTestimonialModal);
