import react from 'react';
import styled, { css } from 'styled-components';

const HeaderIcon = styled.a`
  display: flex;
  ${(props) =>
    !props.$hasLabel
      ? css`
          width: 28px;
        `
      : null};
  height: 28px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 0 4px 0 auto;
  text-decoration: none !important;
  &:hover > i,
  &:active > i,
  &:focus > i {
    transition: 0.2s all;
    transform: scale(1.4);
  }

  position: relative;

  & > div {
    position: absolute;
    top: -5.5px;
    right: -5.5px;
  }
  & > i {
    transition: 0.2s all;
    transform: scale(1.2);
  }
`;

export default HeaderIcon;
