import React, { PureComponent } from 'react';
import classNames from 'classnames';

export default class Picture extends PureComponent {
  static defaultProps = {
    shape: 'rounded',
    align: null,
    height: null,
    width: null,
    responsive: true,
    inline: true,
    styleEmpty: false,
  };

  render() {
    let imgClass, placeholder;

    let placeholderText = '?';
    if (this.props.name && this.props.name.length) {
      const matches = this.props.name.match(/\b(\w)/g);
      const acronym = (matches || []).join('');
      placeholderText = acronym.toUpperCase().substring(0, 2);
    }

    imgClass = classNames(
      {
        'pull-left': this.props.align === 'left',
        'pull-right': this.props.align === 'right',
        'img-responsive': this.props.responsive,
        inline: this.props.inline,
        [`img-${this.props.shape}`]: this.props.shape,
      },
      this.props.className,
    );

    if (!this.props.height && !this.props.width) {
      imgClass += this.props.size ? ` img-${this.props.size}` : '';
    }

    if (!this.props.placeholder && !this.props.src && this.props.type === 'person') {
      placeholder = '/assets/img/anonymous-user.jpg';
    } else if (!this.props.placeholder) {
      if (this.props.styleEmpty && !this.props.src) {
        return (
          <div
            className={imgClass}
            style={{
              backgroundColor: '#0071bc',
              height: this.props.height || 60,
              textAlign: 'center',
            }}
          >
            <p
              className="text-primary-inverse"
              style={{
                fontSize: (this.props.height || 60) / 2.5,
                lineHeight: (this.props.height || 60) + 'px',
              }}
            >
              {placeholderText}
            </p>
          </div>
        );
      } else {
        placeholder =
          this.props.width <= 240
            ? '/assets/img/empty-image.png'
            : `https://www.placehold.it/${this.props.width}x${this.props.height}/ffffff/999999/?text=?`;
      }
    } else {
      placeholder = this.props.placeholder;
    }

    const src = this.props.src || placeholder;

    return (
      <img
        src={src}
        width={this.props.width}
        style={this.props.style}
        height={this.props.height}
        className={imgClass}
        alt={this.props.alt}
      />
    );
  }
}
