import ClientAPI from '../services/api';
import Utils from '../mixins/utils';
import configAuth from '../../shared/auth';

let api = {};
const auth = configAuth();

if (typeof window === 'object') {
  api = global.api || new ClientAPI(auth);
  global.api = api;
}

const performLogin = (email, password, nextPath, query = {}) => {
  auth.login(email, password, (loggedIn) => {
    if (!loggedIn) return;

    const qs = Utils.makeQueryString(query);

    let location = String(nextPath);
    // Prevent redirecting to paths that would be pointless or log us back out
    if (location.length <= 1 || location.startsWith('/logout') || location.startsWith('/signup')) {
      location = '/dashboard';
    }

    window.location = location + qs;
  });
};
export { performLogin };
