import config from '../../config';

function reqHas(req, domain) {
  return req?.headers?.hostname?.includes(domain) || req?.headers?.referer?.includes(domain);
}

export function getAuthHost(req) {
  let host = config.auth.host;
  if (typeof window !== 'undefined') {
    if (location.host.includes('dosomegood.app')) {
      host = config.auth.hostApp;
    }
  } else {
    if (reqHas(req, 'dosomegood.app')) {
      host = config.auth.hostApp;
    }
  }
  return host;
}

export function getWebHost(req) {
  let host = config.web.host;
  if (typeof window !== 'undefined') {
    if (location.host.includes('dosomegood.app')) {
      host = config.web.hostApp;
    }
  } else {
    if (reqHas(req, 'dosomegood.app')) {
      host = config.web.hostApp;
    }
  }
  return host;
}

export function getApiHost(req) {
  let host = config.api.host;
  // Client Side
  if (typeof window !== 'undefined') {
    if (location.host.includes('dosomegood.app')) {
      host = config.api.hostApp;
    }
  } else {
    // Server-side uses an internal host address for lower latency
    host = config.api.internalHost;
    if (reqHas(req, 'dosomegood.app')) {
      host = config.api.internalHostApp;
    }
  }
  return host;
}
