import React, { PureComponent } from 'react';
import styled from 'styled-components';

import AppStore from 'stores/app';
import { api as API } from 'mixins/api';
import connectToStores from 'alt-utils/lib/connectToStores';

import { SecondaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import {
  Form,
  FormActions,
  FormControl,
  FormRow,
  FormDivider,
} from '@dosomegood/platform/dist/components/inputs/Form';
import EmailInput from '@dosomegood/platform/dist/components/inputs/EmailInput';
import PhoneInput from '@dosomegood/platform/dist/components/inputs/PhoneInput';
import {
  Paragraph,
  CollectionItemHeader,
} from '@dosomegood/platform/dist/components/typography/Content';
import Link from '@dosomegood/platform/dist/components/buttons/Link';
import { withTranslation } from 'react-i18next';

const Container = styled.div`
  padding: ${(props) => props.theme.margins.xs};
`;

@connectToStores
class TalkToTeamModal extends PureComponent {
  static getStores() {
    return [AppStore];
  }

  static getPropsFromStores() {
    const { user } = AppStore.getState();
    return { user };
  }

  handleSubmit = ({ email, phone }, _, __, handleServerResponse) => {
    const { context } = this.props.options || {};
    API.makeRequest(
      `/notify/upgrade-package`,
      `POST`,
      { context, email, phone },
      handleServerResponse((res) => {
        if (res) {
          this.props.onCancel();
          alert(`Thanks! We'll reach out soon.`);
        }
      })
    );
  };

  render() {
    const {
      options: { context },
      onCancel,
      t,
    } = this.props;
    const { user } = this.props;

    return (
      <Container>
        <SecondaryHeader>{t('common:labels.lookingForwardToGettingStarted')}</SecondaryHeader>
        {!!user && <Paragraph>{t('common:labels.getStartedContactNow')}</Paragraph>}

        {context?.type === 'business' && (
          <Paragraph>{t('common:labels.callDuringBusinessHours')}</Paragraph>
        )}

        <Paragraph>
          {t('common:labels.sendEmailAnyTime')}{' '}
          <Link href="mailto:hello@dosomegood.ca">hello@dosomegood.ca</Link>
        </Paragraph>

        {!!user ? (
          <>
            <FormDivider />
            <CollectionItemHeader>{t('common:labels.haveUsContactYou')}</CollectionItemHeader>
            <Form value={{ email: user ? user.email : undefined }} onSubmit={this.handleSubmit}>
              <FormRow>
                <FormControl label={t('commonPlat:labels.emailAddress')}>
                  <EmailInput id="email" required />
                </FormControl>
                <FormControl label={t('commonPlat:labels.phoneNumber')}>
                  <PhoneInput id="phone" />
                </FormControl>
              </FormRow>
              <FormActions>
                <Buttons.Alternate onClick={onCancel}>
                  {t('commonPlat:actions.cancel')}
                </Buttons.Alternate>
                <Buttons.Primary type="submit">{t('commonPlat:actions.submit')}</Buttons.Primary>
              </FormActions>
            </Form>
          </>
        ) : (
          <FormActions>
            <Buttons.Primary onClick={onCancel}>{t('commonPlat:actions.close')}</Buttons.Primary>
          </FormActions>
        )}
      </Container>
    );
  }
}

export default withTranslation(['common', 'commonPlat'])(TalkToTeamModal);
