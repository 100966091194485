import React from 'react';
import Utils from '../mixins/utils';
import AgreeToTerms from './AgreeToTerms';
import FocusedExperience from './FocusedExperience';

const NEWEST_AGREEMENT_VERSION = 2;

function CheckAgreements({ children, user, params, ...props }) {
  // Find anything the user is an admin of
  const adminOf = Utils.relationshipsOfAccessLevel('administrator', user);
  // ...and make sure they've agreed to the service agreement for them
  const notAgreed = adminOf.filter((rel) => {
    if (!rel || !rel.target) return false;
    return rel.target.agt < NEWEST_AGREEMENT_VERSION && rel.target.slug === params.slug;
  });

  // If they haven't agreed to terms, present the AgreeToTerms component to take care of it
  if (notAgreed.length) {
    return (
      <FocusedExperience>
        <AgreeToTerms targets={notAgreed} />
      </FocusedExperience>
    );
  }

  // Otherwise proceed as normal
  return React.cloneElement(children, { user, params, ...props });
}

export default CheckAgreements;
