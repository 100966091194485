import config from '../../config';

import AppActions from '../actions/app';

import LocalStorage from '../mixins/LocalStorage';
import { api as API } from '../mixins/api';

import SSOLoginModal from '../components/modals/SSOLogin';
import { buildReturnPath } from '@dosomegood/platform/dist/utils/URLFormatters';

const handleSocialConnect = (method, next) => {
  LocalStorage.setItem('registration.method', method);

  if (typeof next === 'function') {
    LocalStorage.removeItem('registration.email');
    next();
  } else if (method === 'sso' || method === 'sso-connect') {
    const returnPath = buildReturnPath(next.pathname, next.query);
    AppActions.setModal({
      component: SSOLoginModal,
      options: {
        onSubmit: ({ email }) => {
          API.makeRequest(
            `/sso/tenants?email=${encodeURIComponent(email)}`,
            'GET',
            {},
            (err, res) => {
              if (!err && res && res.body) {
                let t = res.body.tenants || [];
                if (!t.length) {
                  return alert(
                    `Your email address is not SSO enabled, please sign up or log in using a password or another social network.`,
                  );
                }
                const sso = t[0];
                window.location = `${config.auth.host}/redirect/${
                  sso.type
                }?email=${encodeURIComponent(email)}&returnPath=${returnPath}`;
                return;
              }
              return alert(
                `Your email address is not SSO enabled, please sign up or log in using a password or another social network.`,
              );
            },
          );
        },
      },
    });
  }
};

function getSocialError(error, t) {
  if (!error) return null;

  if (error === 'expired') return `Your session has expired, please sign back in to continue.`;
  if (error.startsWith('azure_'))
    return `There was a problem communicating with Microsoft Azure, please try again. If this problem persists, please have your IT department reach out to Do Some Good.`;
  return `There was a problem connecting via social network, please try again or use an email address and password instead.`;
}

export { handleSocialConnect, getSocialError };
