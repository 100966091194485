import APIClient from '../app/services/api';
import AppStore from '../app/stores/app';
import configAuth from '../shared/auth';
import { shuffleArray } from '../app/utils/Helpers';

export default (req, res, params) => {
  const auth = configAuth(req, res);
  const api = global.api || new APIClient(auth, req);

  // Do not fill global on server, we want to keep the API client separate for each request for security reasons
  if (typeof window === 'object') {
    global.api = api;
  }

  const pRequest = (url) =>
    new Promise((resolve, reject) => {
      api.makeRequest(url, 'GET', {}, (err, res) => {
        if (err) return reject(err);
        resolve(res);
      });
    });

  const opportunityProfile = function () {
    return function (done) {
      api.makeRequest('/opportunity/' + params.params.slug, 'GET', {}, (err, res) => {
        const opportunity = !err && !!res.ok && res.body;
        done(null, {
          OpportunityStore: { statusCode: res && res.status, opportunity },
        });
      });
    };
  };

  const organizations = function () {
    return function (done) {
      api.makeRequest('/organizations?limit=100&offset=0', 'GET', {}, (err, res) => {
        done(null, {
          OrganizationStore: {
            organizationList: (res.body || {}).results || [],
            totalOrgs: (res.body || {}).totalOrgs,
            limit: 100,
            offset: 0,
          },
        });
      });
    };
  };

  const businesses = function () {
    return function (done) {
      api.makeRequest('/businesses?limit=100&offset=0', 'GET', {}, (err, res) => {
        let store = {
          businessList: (res.body || {}).results || [],
          totalBus: (res.body || {}).totalBus,
          limit: 100,
          offset: 0,
        };

        api.makeRequest('/impact/ticker/platform', 'GET', {}, (err, res) => {
          if (!err && res.body) {
            store.businessRollupDonations = parseInt(res.body.businessRollupDonations || 0);
            store.businessRollupHours = parseInt(res.body.businessRollupHours || 0);
          } else {
            // In case something goes wrong, send zeroes and the frontend will fall back to a generic message.
            store.businessRollupDonations = 0;
            store.businessRollupHours = 0;
          }

          done(null, {
            BusinessStore: store,
          });
        });
      });
    };
  };

  const publicOrUserProfile = function () {
    return function (done, state) {
      // Try grab the current AppStore from up the chain so we can access the logged in user info
      const appStore = state?.AppStore || AppStore.getState();
      const loggedInUser = appStore?.user;
      const isSelf =
        loggedInUser &&
        (loggedInUser.hash === params.params.hash ||
          params.params.hash === ':hash' ||
          params.params.hash === 'me');

      const isPublicPreview = params.location.search && params.location.search.includes('preview');

      // If we're viewing ourselves and not asking for public profile, we can skip loading the profile since we have it already
      if (!isPublicPreview && isSelf) {
        done(null, {
          ...state,
          UserDashboardStore: {
            // Only need profile if viewing someone else's, so empty it just incase it was previously filled
            profile: null,
          },
        });
      } else {
        // Public profiles (including own public profile previews)
        // Grab the user that we're looking at specifically
        api.makeRequest('/user/' + params.params.hash, 'GET', {}, (err, res) => {
          const user = res?.body ? res.body : {};
          done(null, {
            ...state,
            UserDashboardStore: {
              profile: user,
            },
          });
        });
      }
    };
  };

  const userPosts = function () {
    return function (done) {
      api.makeRequest('/posts/user/' + params.params.hash, 'GET', {}, (err, res) => {
        const content = res?.body && Array.isArray(res.body) ? res.body : [];
        done(null, {
          SocialFeedStore: {
            contentFeed: content,
            hasMore: content.length >= 10,
            recommendedPositions: [],
          },
        });
      });
    };
  };

  const mediaSitePosts = function () {
    return function (done) {
      api.makeRequest('/posts/media/' + params.params.key, 'GET', {}, (err, res) => {
        const content = res?.body?.posts && Array.isArray(res.body.posts) ? res.body.posts : [];
        done(null, {
          SocialFeedStore: {
            contentFeed: content,
            hasMore: content.length >= 10,
            recommendedPositions: [],
            focusCity: res?.body?.config?.focusCity || null,
            collapseCities: res?.body?.config?.collapseCities || null,
          },
        });
      });
    };
  };

  const userTestimonials = function () {
    return function (done, state) {
      const UserDashboardStore = state?.UserDashboardStore || {};
      api.makeRequest('/user/' + params.params.hash + '/testimonials', 'GET', {}, (err, res) => {
        const testimonials = res?.body && Array.isArray(res.body) ? res.body : [];
        UserDashboardStore.testimonials = testimonials;
        done(null, { UserDashboardStore });
      });
    };
  };

  const userVolunteerism = function () {
    return function (done, state) {
      const UserDashboardStore = state?.UserDashboardStore || {};

      Promise.all([
        pRequest('/user/' + params.params.hash + '/current'),
        pRequest('/user/' + params.params.hash + '/history'),
      ])
        .then(([currentRes, historyRes]) => {
          const current =
            currentRes?.body && !currentRes.body.error && Array.isArray(currentRes.body)
              ? currentRes.body
              : [];
          const history =
            historyRes?.body && !historyRes.body.error && Array.isArray(historyRes.body)
              ? historyRes.body
              : [];
          UserDashboardStore.volunteerism = history.concat(current);
          done(null, { ...state, UserDashboardStore });
        })
        .catch((err) => {
          done(err);
        });
    };
  };

  const acceptInvite = function () {
    return function (done) {
      if (!api.token()[0]) {
        console.warn('Cannot accept an invite yet, not logged in');
        return done(null);
      }
      // Accept invite & reload user details:
      api.makeRequest(
        '/user/accept-invite',
        'POST',
        { token: params.params.token },
        (errInvite, resInvite) => {
          api.makeRequest('/user', 'GET', {}, (errUser, resUser) => {
            done(null, {
              InviteStore: {
                inviteAcceptError: resInvite.body ? resInvite.body.error : false,
                inviteActivated: resInvite.body && resInvite.body.result ? true : false,
                invite: resInvite.body ? resInvite.body : false,
              },
              AppStore: {
                user: !errUser && resUser.ok ? resUser.body : false,
              },
            });
          });
        },
      );
    };
  };

  const defaultLocation = function () {
    const nreq = req || { headers: {} };
    const city = (nreq.headers['x-city'] || '').replace('\\', '');
    const region = (nreq.headers['x-region'] || '').replace('\\', '');
    const country = nreq.headers['x-countrycode'];
    return { city, region, country };
  };

  return {
    app(done) {
      const curLocation = !req ? AppStore.getState().defaultLocation : defaultLocation();
      if (!api.token()[0]) {
        // Not logged in, just return the location info
        return done(null, { AppStore: { user: false, defaultLocation: curLocation } });
      }

      // Client-side
      if (!req) {
        api.makeRequest('/user/notifications/unread', 'GET', {}, (err, unread) => {
          const {
            unreadMessages = 0,
            unreadManagementCount = 0,
            unreadManagement = 0,
          } = unread?.body || {};

          done(null, {
            NotificationStore: {
              unreadCount: unreadMessages,
              unreadManagement,
              unreadManagementCount,
            },
          });
        });
      } else {
        // Server-side
        api.makeRequest('/user', 'GET', {}, (err, res) => {
          if (err) {
            return done(null, {
              AppStore: {
                user: false,
                platformStatus: err.message,
              },
            });
          }

          const AppStore = {
            AppStore: {
              user: !err && res.ok ? res.body : false,
              platformStatus: false,
              usingProxyUser: !!res.isProxy,
              defaultLocation: curLocation,
            },
          };

          api.makeRequest('/user/notifications/unread', 'GET', {}, (err, unread) => {
            const {
              unreadMessages = 0,
              unreadManagementCount = 0,
              unreadManagement = 0,
            } = unread && unread.body ? unread.body : {};

            done(null, {
              ...AppStore,
              NotificationStore: {
                unreadCount: unreadMessages,
                unreadManagement,
                unreadManagementCount,
              },
            });
          });
        });
      }
    },

    organizations: organizations(),
    businesses: businesses(),

    managePrintSubmission(done) {
      const { contextType, contextSlug } = params.params;
      api.makeRequest(`/${contextType}/${contextSlug}`, 'GET', {}, (err, res) => {
        if (!res || (res.body && res.body.error))
          return done(new Error(`Error fetching ${contextType} information`));
        done(
          null,
          contextType === 'business'
            ? {
                BusinessStore: {
                  business: res.body,
                },
              }
            : { OrganizationStore: { organization: res.body } },
        );
      });
    },

    formsContext(done) {
      const { contextSlug } = params.params;
      const contextType = params.location.pathname.startsWith(`/forms/organization`)
        ? 'organization'
        : 'business';
      if (!contextSlug) return done(new Error('No referrer found, please use a different link'));

      api.makeRequest(`/${contextType}/${contextSlug}`, 'GET', {}, (err, res) => {
        if (!res || (res.body && res.body.error))
          return done(new Error(`Error fetching ${contextType} information`));
        done(
          null,
          contextType === 'business'
            ? {
                BusinessStore: {
                  business: res.body,
                },
              }
            : { OrganizationStore: { organization: res.body } },
        );
      });
    },

    formView(done) {
      const { contextSlug } = params.params;
      const contextType = params.location.pathname.startsWith(`/forms/organization`)
        ? 'organization'
        : 'business';

      api.makeRequest(
        `/form/${contextType}/${contextSlug}/${params.params.formSlug}`,
        'GET',
        {},
        (err, res) => {
          if (err || !res || !res.body) return done(err || new Error('Could not fetch form'));
          done(null, { FormStore: { form: res.body, statusCode: res.status } });
        },
      );
    },

    signup(done) {
      if (!params.location || !params.location.query.token) return done(null, {});
      api.makeRequest('/invite/' + params.location.query.token, 'GET', {}, (err, res) => {
        done(null, {
          InviteStore: {
            invite: res && res.body ? res.body : false,
            token: params.location.query.token,
          },
        });
      });
    },

    login(done) {
      if (!params.location || !params.location.query.token) return done(null, {});
      api.makeRequest('/invite/' + params.location.query.token, 'GET', {}, (err, res) => {
        done(null, {
          InviteStore: {
            invite: res && res.body ? res.body : false,
            token: params.location.query.token,
          },
        });
      });
    },

    viewInvite(done) {
      if (!params.params || !params.params.token) return done(null, {});
      api.makeRequest('/invite/' + params.params.token, 'GET', {}, (err, res) => {
        done(null, {
          InviteStore: {
            invite: res && res.body ? res.body : false,
            token: params.params.token,
          },
        });
      });
    },

    registerBusiness(done) {
      if (!params.location || !(params.location.query || {}).token) return done(null, {});
      api.makeRequest('/invite/' + params.location.query.token, 'GET', {}, (err, res) => {
        done(null, {
          InviteStore: {
            invite: res && res.body ? res.body : false,
            token: params.location.query.token,
          },
        });
      });
    },

    businessTestimonial(done, state) {
      done(null, {
        BusinessStore: {
          ...state.BusinessStore,
          dedicatedTestimonialView: true,
          dedicatedTestimonialId: params.params.testimonialId,
        },
      });
    },

    businessProfile(done) {
      Promise.all([
        pRequest(`/business/${params.params.slug}`),
        pRequest('/posts/business/' + params.params.slug),
        pRequest('/events/business/' + params.params.slug),
        pRequest(`/opportunities/business/${params.params.slug}?limit=50`),
        pRequest('/business/' + params.params.slug + '/relationships'),
        pRequest('/business/' + params.params.slug + '/stats'),
      ])
        .then(([businessRes, postsRes, eventsRes, oppsRes, relRes, statsRes]) => {
          const business = businessRes.ok && !businessRes.body.error ? businessRes.body : false;
          const statusCode = businessRes && businessRes.status;
          if (!business || !Object.keys(business).length)
            return done(null, { BusinessStore: { business, statusCode } });
          const content = postsRes && postsRes.body ? postsRes.body : [];
          const events = eventsRes && eventsRes.ok && eventsRes.body ? eventsRes.body : [];
          let opportunities = {};
          (oppsRes.ok ? oppsRes.body : []).forEach((opportunity) => {
            opportunities[opportunity.slug] = opportunity;
          });
          const {
            affiliates = [],
            volunteerism = [],
            testimonials = [],
          } = (relRes.ok && relRes.body) || {};
          if (statsRes.ok && statsRes.body && statsRes.body.causes) {
            business.supportedCauses = statsRes.body.causes;
          }
          business.events = events;

          done(null, {
            BusinessStore: {
              business,
              statusCode,
              affiliates: shuffleArray(affiliates),
              testimonials: shuffleArray(testimonials),
              volunteerism,
            },
            OpportunityStore: { opportunities, fromServer: false },
            SocialFeedStore: {
              contentFeed: content,
              hasMore: content.length >= 10,
              recommendedPositions: [],
            },
          });
        })
        .catch((err) => {
          done(err);
        });
    },

    organizationProfile(done) {
      api.makeRequest(`/organization/${params.params.slug}`, 'GET', {}, (err, res) => {
        const organization = !err && res.ok ? res.body : false;
        const statusCode = res && res.status;

        if (!organization || !Object.keys(res.body).length)
          return done(null, { OrganizationStore: { organization, statusCode } });

        api.makeRequest('/posts/organization/' + params.params.slug, 'GET', {}, (err, res) => {
          const content = res && res.body ? res.body : [];

          api.makeRequest('/events/organization/' + params.params.slug, 'GET', {}, (err, res) => {
            const events = !err && res && res.ok && res.body ? res.body : [];

            api.makeRequest(
              `/opportunities/organization/${params.params.slug}?limit=50`,
              'GET',
              {},
              (err, res) => {
                api.makeRequest(
                  '/organization/' + params.params.slug + '/relationships',
                  'GET',
                  {},
                  (affErr, affRes) => {
                    let affiliates = [];
                    let volunteerism = [];
                    if (!affErr && affRes && affRes.body) {
                      affiliates = affRes.body.affiliates;
                      volunteerism = affRes.body.volunteerism;
                    }
                    organization.events = events;

                    done(null, {
                      OrganizationStore: {
                        organization,
                        statusCode,
                        affiliates: shuffleArray(affiliates),
                        volunteerism: shuffleArray(volunteerism),
                      },
                      OpportunityStore: {
                        opportunityFeed: !err && res.ok ? res.body : [],
                        fromServer: false,
                      },
                      SocialFeedStore: {
                        contentFeed: content,
                        hasMore: content.length >= 10,
                        recommendedPositions: [],
                      },
                    });
                  },
                );
              },
            );
          });
        });
      });
    },

    organizationDashboardRecognize(done) {
      api.makeRequest(
        '/skills/pending/organization/' + params.params.slug,
        'GET',
        {},
        (err, res) => {
          done(null, {
            UserManagementStore: { endorsements: res && res.body ? res.body : [] },
          });
        },
      );
    },

    opportunityProfile: opportunityProfile(),
    opportunityProfileApply: opportunityProfile(),
    opportunityProfileApplySpecific: opportunityProfile(),

    opportunitiesBrowse(done) {
      // Bail out if we're on the client, as they wont have the querystring at this point.
      if (typeof window === 'object') {
        return done(null, {});
      }

      const rawQuery =
        req && req.url.indexOf('?') > 0 ? req.url.substring(req.url.indexOf('?')) : '';

      // If we haven't asked for a specific city and there's no user logged in then there's no point in looking
      if (rawQuery === '' && !auth.token()) return done(null, {});

      api.makeRequest('/opportunities/' + rawQuery, 'GET', {}, (err, res) => {
        let content = res && res.body ? res.body : [];
        done(null, {
          OpportunityStore: {
            opportunityFeed: content,
            hasMoreOpportunities: content.length >= 8,
            fromServer: true,
          },
        });
      });
    },

    events(done) {
      api.makeRequest('/events', 'GET', {}, (err, res) => {
        const content = res && res.body ? res.body : [];
        done(null, {
          OpportunityStore: {
            events: content,
            hasMoreEvents: content.length >= 50,
            fromServer: true,
          },
        });
      });
    },

    organizationDashboardApproveHours(done) {
      api.makeRequest(
        '/hours/organization/' + params.params.slug + '/unapproved',
        'GET',
        {},
        (err, res) => {
          const unapprovedHours =
            !err && res && res.body && Array.isArray(res.body) ? res.body : [];

          api.makeRequest(
            '/hours/organization/' + params.params.slug + '/review',
            'GET',
            {},
            (err, res) => {
              const hoursInReview =
                !err && res && res.body && Array.isArray(res.body) ? res.body : [];

              done(null, {
                HourStore: {
                  hours: unapprovedHours,
                  parent: 'organization',
                  hoursInReview,
                },
              });
            },
          );
        },
      );
    },

    businessDashboardApproveHours(done) {
      api.makeRequest(`/hours/business/${params.params.slug}/unapproved`, 'GET', {}, (err, res) => {
        done(null, {
          HourStore: {
            statusCode: res && res.status,
            hours: !err && res && res.body && Array.isArray(res.body) ? res.body : [],
            hoursInReview: [],
            parent: 'business',
          },
        });
      });
    },

    eventProfile(done) {
      api.makeRequest('/event/' + params.params.id, 'GET', {}, (err, res) => {
        const event = !err && !!res.ok && res.body;
        done(null, {
          OpportunityStore: { statusCode: res && res.status, event },
        });
      });
    },

    businessDashboardMembersSummary(done, state) {
      if (!auth.token()) return done(null, {});

      const mode = 'members';
      api.makeRequest(
        '/business/' + params.params.slug + '/overview/' + mode,
        'GET',
        {},
        (err, res) => {
          const overview = !err && res.ok ? res.body : {};

          const lastYearToday = new Date();
          lastYearToday.setFullYear(lastYearToday.getFullYear() - 1);

          const startDate = lastYearToday.toISOString();
          const endDate = new Date().toISOString();

          done(null, {
            BusinessDashboardStore: {
              ...state.BusinessDashboardStore,
              audience: 'admins',
              statusCode: res?.status,
              error: res?.body?.error || false,
              overview,
              startDate,
              endDate,
            },
          });
        },
      );
    },

    staffDashboardUsers(done) {
      api.makeRequest('/users?limit=20', 'GET', {}, (err, res) => {
        const body = res && res.body ? res.body : [];
        done(null, {
          UserManagementStore: {
            users: body.users,
            totalUsers: body.totalUsers,
            limit: 20,
            offset: 0,
            mode: 'staff',
            allowProfileLink: true,
          },
        });
      });
    },

    staffDashboardOrgs(done) {
      api.makeRequest('/organizations/recent?limit=50&offset=0', 'GET', {}, (err, res) => {
        const body = res && res.body ? res.body : {};
        done(null, {
          OrganizationStore: {
            organizationList: body.results || [],
            totalOrgs: body.totalOrgs,
            limit: 50,
            offset: 0,
          },
        });
      });
    },

    acceptInvite: acceptInvite(),

    userMessageInbox(done) {
      if (!auth.token()) return done(null, {});

      api.makeRequest('/user/messages', 'GET', {}, (err, res) => {
        const threads = res && res.body ? res.body : [];
        done(null, {
          MessageStore: {
            threads,
          },
        });
      });
    },

    userMessageThread(done) {
      if (!auth.token()) return done(null, {});
      api.makeRequest('/user/message/' + params.params.id, 'GET', {}, (err, res) => {
        const thread = res && res.body ? res.body : [];
        done(null, {
          MessageStore: {
            thread,
            activeThreadId: params.params.id,
          },
        });
      });
    },

    userMessageThreadGroup(done) {
      if (!auth.token()) return done(null, {});
      api.makeRequest(
        '/user/message-group/' + params.params.id + '/' + params.params.signature,
        'GET',
        {},
        (err, res) => {
          const thread = res && res.body ? res.body : [];
          done(null, {
            MessageStore: {
              thread,
              activeThreadId: params.params.id,
            },
          });
        },
      );
    },

    publicProfile: publicOrUserProfile(),
    userVolunteerism: userVolunteerism(),
    userTestimonials: userTestimonials(),
    userPosts: userPosts(),
    mediaSitePosts: mediaSitePosts(),

    businessManagement(done, state) {
      if (!auth.token()) return done(null, {});
      api.makeRequest(
        `/business/${params.params.slug}/management-overview`,
        'GET',
        {},
        (err, res) => {
          const statusCode = err ? 500 : res && res.status;
          const { BusinessDashboardStore = {} } = state || {};
          done(null, {
            BusinessDashboardStore: {
              ...BusinessDashboardStore,
              statusCode,
              error: (res && res.body && res.body.error) || false,
              managementOverview: res?.body || {},
            },
          });
        },
      );
    },

    businessWelcome(done, state) {
      if (!auth.token()) return done(null, {});
      api.makeRequest(
        `/business/${params.params.slug}/management-stats`,
        'GET',
        {},
        (err, stats) => {
          const { BusinessDashboardStore = {} } = state || {};
          done(null, {
            BusinessDashboardStore: {
              ...BusinessDashboardStore,
              managementStats: stats?.body,
            },
          });
        },
      );
    },

    organizationWelcome(done, state) {
      if (!auth.token()) return done(null, {});
      api.makeRequest(
        `/organization/${params.params.slug}/management-stats`,
        'GET',
        {},
        (err, stats) => {
          const { OrganizationDashboardStore = {} } = state || {};
          done(null, {
            OrganizationDashboardStore: {
              ...OrganizationDashboardStore,
              managementStats: stats?.body,
            },
          });
        },
      );
    },

    organizationManagement(done) {
      if (!auth.token()) return done(null, {});
      api.makeRequest(
        `/organization/${params.params.slug}/management-overview`,
        'GET',
        {},
        (err, res) => {
          const statusCode = err ? 500 : res && res.status;
          done(null, {
            OrganizationDashboardStore: {
              statusCode,
              error: (res.body && res.body.error) || false,
              managementOverview: res ? res.body : {},
            },
          });
        },
      );
    },

    businessDashboardApproveContent(done) {
      if (!auth.token()) return done(null, {});
      api.makeRequest(
        '/posts/business/' + params.params.slug + '/pending?limit=10',
        'GET',
        {},
        (err, res) => {
          const content = res && res.body ? res.body : [];
          done(null, {
            SocialFeedStore: {
              contentFeed: content,
              hasMore: content.length >= 10,
              mode: 'business',
            },
          });
        },
      );
    },

    organizationDashboardApproveContent(done) {
      if (!auth.token()) return done(null, {});
      api.makeRequest(
        '/posts/organization/' + params.params.slug + '/pending?limit=20',
        'GET',
        {},
        (err, res) => {
          const content = res && res.body ? res.body : [];
          done(null, {
            SocialFeedStore: {
              contentFeed: content,
              hasMore: content.length >= 20,
              mode: 'organization',
            },
          });
        },
      );
    },

    post(done) {
      api.makeRequest('/post/' + params.params.id, 'GET', {}, (err, res) => {
        if (err || (res.body && (res.body.error || res.body.errors)))
          return done(null, { SocialFeedStore: { contentFeed: [] } });
        done(null, {
          SocialFeedStore: {
            contentFeed: [res.body],
          },
        });
      });
    },
  };
};
