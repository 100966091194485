import InviteStore from '../stores/invite';

const LoggedOutState = {
  // Determine if signup.jsx component should be in login or signup state
  getLoggedOutState(pathname, invite = InviteStore.getState().invite) {
    const isSignupPath = pathname.indexOf('/signup') >= 0;
    const isRegistrationPath = pathname.indexOf('/registration') >= 0;
    const isDashboardPath = pathname.indexOf('/dashboard') >= 0;
    const requestForDonation = pathname.indexOf('request-for-donations') >= 0;
    const acceptInvite = pathname.indexOf('/dashboard/accept-invite/') >= 0;
    const apply = pathname.startsWith('/opportunity/');

    return apply ||
      isRegistrationPath ||
      isSignupPath ||
      (invite && !isDashboardPath) ||
      (requestForDonation && !isDashboardPath) ||
      acceptInvite
      ? 'signup'
      : 'login';
  },
};

export default LoggedOutState;
export { LoggedOutState };
