export function getJoyrideLocale(t) {
  return {
    back: t('common:nav.back'),
    close: t('common:nav.okGotIt'),
    last: t('common:nav.okGotIt'),
    next: t('commonPlat:actions.next'),
    skip: t('commonPlat:actions.skip'),
  };
}

export function resolveFullLocale(i18n) {
  const { languages } = i18n;
  const resolvedLanguage = languages[0];

  return resolvedLanguage === 'fr' ? 'fr-CA' : 'en-CA';
}

export function resolveShortLocale(i18n) {
  return resolveFullLocale(i18n).split('-')[0];
}
