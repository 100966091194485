import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import AppStore from '../stores/app';
import InviteActions from '../actions/invite';
import { api as API } from '../mixins/api';

import FocusedExperience from './FocusedExperience';
import SoloContent from '@dosomegood/platform/dist/components/content/onboarding/SoloContent';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import Panel from '@dosomegood/platform/dist/components/layout/Panel';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import Link from '@dosomegood/platform/dist/components/buttons/Link';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import urls from '@dosomegood/platform/dist/config/theme/urls';
import { useStore } from '../utils/alt';

import configAuth from '../../shared/auth';
const auth = configAuth();

const StyledPanel = styled(Panel)`
  text-align: center;
`;

function PlatformOfflineCheck({ children }) {
  const { t } = useTranslation(['commonPlat', 'common']);
  const [{ platformStatus: message }] = useStore(AppStore);

  const handleLogout = useCallback(() => {
    API.accessToken = false;
    API.activeAccessToken = false;
    auth.logout(() => {
      InviteActions.reset();
      window.location = urls.routes.loginUser;
    });
  }, []);

  if (!message) return children;
  return (
    <FocusedExperience>
      <SoloContent>
        <StyledPanel>
          <Icon name="cog" size={'md'} />
          <br />
          <Paragraph>{message}</Paragraph>
          <Buttons.Primary onClick={() => location.reload(true)}>
            {t('common:actions.reloadPage')}
          </Buttons.Primary>
          <Paragraph>
            <Trans i18nKey="common:errors.keepHappening">
              Does this keep happening? Think it's a bug? Help us out by{' '}
              <Link href="https://support.dosomegood.ca/hc/en-us/requests/new" target="_blank">
                {' '}
                submitting a support ticket{' '}
              </Link>
              .
            </Trans>
          </Paragraph>
        </StyledPanel>
      </SoloContent>
      <br />
      <Link onClick={handleLogout}>{t('commonPlat:actions.logout')}</Link>
    </FocusedExperience>
  );
}

export default PlatformOfflineCheck;
