import React from 'react';
import { browserHistory } from 'react-router';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { api as API } from '../../../mixins/api';

import AppActions from '../../../actions/app';

import Buttons from '@dosomegood/platform/dist/components/buttons';
import { CollectionItemDetail } from '@dosomegood/platform/dist/components/typography/Content';
import { daysBetween, now } from '@dosomegood/platform/dist/utils/Chrono';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;
const Info = styled.div`
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: ${(props) => props.theme.margins.xs};
  flex: 1;
`;
const ExitArea = styled.div`
  padding-top: 1px;
  padding-bottom: 1px;
  width: 200px;
  text-align: center;
  border-left: 1px solid ${(props) => props.theme.brandColors.lighterBackground};
  border-right: 1px solid ${(props) => props.theme.brandColors.lighterBackground};

  & > button {
    line-height: 16px !important;
  }
`;
const TrialNotice = styled.span`
  font-weight: ${(props) => props.theme.fonts.weights.heavy};
  color: ${(props) => props.theme.brandColors.primaryTextHighlight};
`;

const handleExit = (target, location, t) => {
  API.makeRequest(`/user`, 'GET', {}, (err, res) => {
    if (!err) AppActions.login(res?.body);
    /* 
      Disabled Nov 8, 2023 - We want to send people to the news feed instead so they can continue any 
      possible welcome experiences. 
       
      Old Code: pathname: target ? `/${target.type}/${target.target.slug}` : `/dashboard` 
      */
    browserHistory.push({
      pathname: `/dashboard`,
      state: { from: location, fromName: t('common:nav.management') },
      // Retain any welcome state for joyrides in the end-user area/news feed
      query: { welcome: location.query.welcome },
    });
  });
};

export default function ManagementMenu({ user, params, location }) {
  const { t } = useTranslation(['sMgmt', 'common']);
  const targetType = location.pathname.includes(`/business/${params.slug}`)
    ? 'business'
    : 'organization';

  // Find the target we're managing
  const target = user?.relations?.find(
    (r) => r?.target?.slug === params.slug && r.type === targetType,
  );

  if (!target) {
    console.error('Management target not found in user relations.');
  }

  const { features = {}, expires } = (target || {}).target || {};
  const daysToExpiry = daysBetween(expires, now());
  const expiryMessage = !!(features.trial && expires) && (
    <TrialNotice>{t('sMgmt:labels.trialExpires', { count: daysToExpiry })}</TrialNotice>
  );

  return (
    <Container>
      <Info>
        {!!target && (
          <CollectionItemDetail>
            <Trans
              i18nKey="sMgmt:labels.currentlyManagingName"
              tOptions={{ name: target.target.name }}
              t={t}
            />{' '}
            {expiryMessage}
          </CollectionItemDetail>
        )}
      </Info>
      <ExitArea>
        <Buttons.Alternate
          id="exit-management"
          icon="enter"
          onClick={() => handleExit(target, location, t)}
        >
          {t('sMgmt:actions.exitMgmt')}
        </Buttons.Alternate>
      </ExitArea>
    </Container>
  );
}
