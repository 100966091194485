import React from 'react';
import styled from 'styled-components';
import {
  CollectionItemDetail,
  CollectionItemHeader,
} from '@dosomegood/platform/dist/components/typography/Content';
import ProgressRing from './ProgressRing';

const StatWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
    props.$compact
      ? `4px 2px`
      : `${props.theme.margins.sm} ${props.theme.margins.sm} 13px ${props.theme.margins.sm}`};
  text-align: center;
  ${(props) => props.theme.borders.standardInvisible};
  background-color: ${(props) => props.theme.brandColors.lighterBackground};
  ${(props) => (props.onClick ? 'cursor: pointer;' : '')};

  &:not(:last-child) {
    margin-right: ${(props) => props.theme.margins.xs};
  }
`;

const StatsWrapperDiv = ({ fill, ...props }) => <div {...props} />;
const StatsWrapper = styled(StatsWrapperDiv)`
  display: flex;
  flex-direction: row;
  padding: ${(props) => (props.fill ? 0 : props.theme.margins.xs)};
`;

const StatValue = styled.span`
  ${(props) => props.theme.fonts.primary};
  font-weight: ${(props) => props.theme.fonts.weights.heavy};
  line-height: ${(props) => (props.$compact ? '24px' : props.theme.rhythm[3])};
  font-size: ${(props) => (props.$compact ? '20px' : props.theme.fonts.sizes.lg)};
  color: ${(props) => props.theme.brandColors.primaryText};
`;

const StatBlock = ({ name, value, total, loading, delay, compact, onClick }) => (
  <StatWrapper $compact={compact} onClick={onClick}>
    {compact ? (
      <CollectionItemDetail>{name}</CollectionItemDetail>
    ) : (
      <CollectionItemHeader>{name}</CollectionItemHeader>
    )}
    {total || total === null ? (
      <ProgressRing
        radius={34}
        stroke={4}
        delay={delay}
        progress={total === null ? 0 : Math.min((value / total) * 100, 100)}
        label={`${value}/${total || '∞'}`}
        color={value / total >= 1 ? 'crimson' : value / total >= 0.8 ? 'orange' : undefined}
      />
    ) : (
      <StatValue $compact={compact}>{loading ? '...' : value}</StatValue>
    )}
  </StatWrapper>
);

export { StatsWrapper, StatBlock };
