import config from '../../../config';
import React, { PureComponent } from 'react';
import { browserHistory } from 'react-router';
import styled from 'styled-components';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import { LoggedOutState } from '../../mixins/loggedOutState';

import AppActions from '../../actions/app';

import PrimaryMenu from './primary-menu/desktopMenu';
import ManagementMenu from './primary-menu/ManagementMenu';
import ProfilePreviewMenu from './primary-menu/profilePreviewMenu';
import UserDropdown from './secondary-menu/UserDropdown';
import MessagesDropdown from './secondary-menu/messagesDropdown';
import NotificationsDropdown from './secondary-menu/notificationsDropdown';
import HeaderIcon from './secondary-menu/HeaderIcon';
import LocaleDropdown from './secondary-menu/LocaleDropdown';

import GlobalActivitySpinner from './GlobalActivitySpinner';

import _Logo from '@dosomegood/platform/dist/components/ui/Logo';
import Link from '@dosomegood/platform/dist/components/buttons/Link';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import UnsupportedBrowser from './UnsupportedBrowser';
import UniversalSearch from '../ui/UniversalSearch';
import { resolveFullLocale } from '../../utils/Lang';

const Logo = styled(_Logo)`
  width: 71px !important;
  height: auto !important;
  margin: 4px !important;
`;

const MenuOptions = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: ${(props) => (props.$management ? `1493px` : `1170px`)};
  margin: 0 auto;
`;

const MapleIcon = styled(Icon)`
  position: relative;
  top: 4px;
  left: -3px;
  width: 24px;
  height: 24px;
  color: rgb(235, 45, 55);
`;

class HeaderBar extends PureComponent {
  state = {
    showSearch: false,
  };

  handleToggleMobileMenu = () => {
    AppActions.toggleMobileMenu();
  };

  handleToggleSearchBox = () => {
    this.setState({ showSearch: !this.state.showSearch });
  };

  handleSearchClose = () => {
    if (this.state.showSearch) this.setState({ showSearch: false });
  };

  handleCloseMobileMenu = AppActions.closeMobileMenu;

  handleNavigate = (destination, event) => {
    if (event) event.preventDefault();
    browserHistory.push({
      pathname: destination,
      state: {
        from: this.props.location,
      },
    });
  };

  render() {
    const { user, location, params, hideSignup, management, usingProxyUser, t, i18n } = this.props;
    const { showSearch } = this.state;

    const pn = location.pathname;
    const loginOrSignupState = LoggedOutState.getLoggedOutState(pn);
    const entityProfilePreview =
      (pn.includes('/business/') ||
        pn.includes('/organization/') ||
        pn.includes('/user/profile')) &&
      !pn.includes('/manage/') &&
      location.query.preview !== undefined;

    /*
    To be brought back in the future when we can make it not appear during demos (add to the <header /> tag)
    style={
      usingProxyUser
        ? {
            background: `linear-gradient(180deg, rgba(255,106,0,1) 0%, rgba(255,189,150,1) 2%, rgba(255,255,255,1) 54%)`,
          }
        : undefined
    }
    */

    return (
      <>
        <UnsupportedBrowser />
        <header className="header" role="banner">
          <GlobalActivitySpinner />

          <Container $management={management}>
            <Link
              to={user ? '/dashboard' : undefined}
              href={!user ? `https://dosomegood.ca/${resolveFullLocale(i18n)}/home` : undefined}
              aria-label={`${config.company.name} logo`}
            >
              <Logo />
              <MapleIcon name="maple" title={t('common:labels.proudlyCanadian')} />
            </Link>

            {user ? (
              <MenuOptions>
                {!management ? (
                  <>
                    <li style={{ flex: 1 }} className="hidden-xs">
                      <UniversalSearch />
                    </li>
                    <li style={{ flex: 1 }} className="hidden-sm hidden-md hidden-lg">
                      <HeaderIcon tabIndex={0} onClick={this.handleToggleSearchBox}>
                        <Icon name="search" />
                      </HeaderIcon>
                    </li>
                  </>
                ) : (
                  <li style={{ flex: 1 }} aria-hidden />
                )}

                <li onClick={this.handleCloseMobileMenu}>
                  <NotificationsDropdown user={user} />
                </li>

                <li onClick={this.handleCloseMobileMenu}>
                  <MessagesDropdown user={user} />
                </li>

                {config.locale === 'auto' && (
                  <li onClick={this.handleCloseMobileMenu}>
                    <LocaleDropdown user={user} location={location} />
                  </li>
                )}

                <li onClick={this.handleCloseMobileMenu}>
                  <UserDropdown id="user-dropdown" user={user} isProxy={usingProxyUser} />
                </li>
              </MenuOptions>
            ) : (
              !hideSignup && (
                <MenuOptions>
                  <li style={{ flex: 1 }} aria-hidden />
                  {config.locale === 'auto' && (
                    <li onClick={this.handleCloseMobileMenu} style={{ paddingRight: 22 }}>
                      <LocaleDropdown location={location} showLabel />
                    </li>
                  )}
                  <li className="btn-group btn-group-logout pull-right">
                    <a
                      href="/signup"
                      onClick={this.handleNavigate.bind(this, '/signup')}
                      className={classNames(
                        { active: loginOrSignupState === 'signup' },
                        'btn',
                        'btn-primary',
                        'btn-sm',
                      )}
                    >
                      {t('commonPlat:actions.signUp')}
                    </a>
                    <a
                      href="/login"
                      onClick={this.handleNavigate.bind(this, '/login')}
                      className={classNames(
                        { active: loginOrSignupState === 'login' },
                        'btn',
                        'btn-primary',
                        'btn-sm',
                      )}
                    >
                      {t('commonPlat:actions.signIn')}
                    </a>
                  </li>
                </MenuOptions>
              )
            )}
          </Container>

          {!!user && showSearch && (
            <Container $management={management}>
              <UniversalSearch onClose={this.handleSearchClose} />
            </Container>
          )}

          {!!user && (
            <Container $management={management}>
              {entityProfilePreview ? (
                <ProfilePreviewMenu path={location.pathname} />
              ) : management ? (
                <ManagementMenu
                  key="management-menu"
                  user={user}
                  params={params}
                  location={location}
                />
              ) : (
                <PrimaryMenu key="primary-menu" user={user} />
              )}
            </Container>
          )}
        </header>
      </>
    );
  }
}

export default withTranslation(['commonPlat'])(HeaderBar);
