import config from '../../config';
import React, { PureComponent } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Link, browserHistory } from 'react-router';
import { useSSR, useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';

import { analytics } from '../mixins/analytics';

import AppStore from '../stores/app';
import AppStatusStore from '../stores/appStatus';
import AppStatusActions from '../actions/appStatus';
import AppActions from '../actions/app';

import Modal from './ui/Modal';
import ScrollToTopArrow from './ui/ScrollToTopArrow';
import { getTheme } from '@dosomegood/platform/dist/config/theme';
import { ConfigContext } from '@dosomegood/platform/dist/config';

import ContentBlock from '@dosomegood/platform/dist/components/layout/ContentBlock';
import { CollectionItemHeader } from '@dosomegood/platform/dist/components/typography/Content';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CreateCityModal from './modals/CreateCity';
import { getAuthHost, getWebHost, getApiHost } from '../utils/MultiDomain';
import { SuspenseSSR } from './ui/SuspenseSSR';
import { getJoyrideLocale } from '../utils/Lang';
import { useStore } from '../utils/alt';
//import { Elements } from '@stripe/react-stripe-js';
//import { loadStripe } from '@stripe/stripe-js';

//const stripePromise = loadStripe(config.stripe.publishableKey);

function FilteredLink({ inverse, compact, ...props }) {
  return <Link {...props} />;
}

const NoticeContainer = styled.div`
  pointer-events: none;
  position: fixed;
  opacity: ${(props) => (props.$hasNotice ? 1 : 0)};
  top: ${(props) => (props.$hasNotice ? 93 : -200)}px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s all;
  padding-top: 22px;
`;

const ContentWrapper = styled.section`
  ${(props) => props.theme.media.phone`padding-bottom: 50px;`};
`;

class App extends PureComponent {
  componentDidMount() {
    analytics.boot(); // Initiate Analytics
  }

  handleDrag = AppStatusActions.fileDrag;

  handleCreateCity = (text, _, handleSelect, handleCancel) => {
    AppActions.setModal({
      component: CreateCityModal,
      options: {
        city: text,
        onComplete: (res) => {
          handleCancel && handleCancel();
          setTimeout(
            () =>
              handleSelect({
                ...res,
                justCreated: true,
              }),
            10,
          );
        },
        onCancel: () => {
          handleSelect(null);
          handleCancel && handleCancel();
        },
      },
    });
  };

  getFunctions = () => {
    // Centralized functions that any component can use if they want (good for linking platform components with no knowledge of their environment to this project)
    return {
      onCreateCity: this.handleCreateCity,
      getAuthHost,
      getWebHost,
      getApiHost,
    };
  };

  render() {
    const { children } = this.props;
    // was inside ConfigContext <Elements stripe={stripePromise}>
    return (
      <ConfigContext
        environment={config}
        routerLink={FilteredLink}
        browserHistory={browserHistory}
        functions={this.getFunctions()}
      >
        <ThemeProvider theme={getTheme('light')}>
          <DndProvider backend={HTML5Backend}>
            <ContentWrapper
              className="body"
              onDragEnter={this.handleDrag}
              onDragLeave={this.handleDrag}
            >
              {/** This suspense boundary catches any hot-loaded language files not nested in an experience wrapper */}
              <SuspenseSSR>
                {children}

                <ScrollToTopArrow />

                <NoticeHandler />
                <ModalHandler />

                {/* Remove this joyride in the future once we phase-out the AppStore based global joyrides in favour of hook based local-to-component ones */}
                <GlobalJoyride />
              </SuspenseSSR>
            </ContentWrapper>
          </DndProvider>
        </ThemeProvider>
      </ConfigContext>
    );
  }
}

function NoticeHandler() {
  const [{ notices }] = useStore(AppStore);
  return (
    <NoticeContainer $hasNotice={!!notices.length}>
      {!!notices.length && (
        <ContentBlock urgent main>
          <CollectionItemHeader>{notices[0].message}</CollectionItemHeader>
        </ContentBlock>
      )}
    </NoticeContainer>
  );
}

function ModalHandler() {
  const [{ modal }] = useStore(AppStore);
  return !!modal ? <Modal components={modal} /> : null;
}

function GlobalJoyride() {
  const { t } = useTranslation(['common', 'commonPlat']);
  const [{ joyrideSteps: steps }] = useStore(AppStatusStore);
  const runJoyride = !!steps?.length;

  return (
    <Joyride
      locale={getJoyrideLocale(t)}
      showSkipButton
      scrollToFirstStep
      scrollOffset={94}
      steps={steps}
      run={runJoyride}
      continuous
      styles={{
        options: {
          primaryColor: '#2667ac',
          zIndex: 1100,
          width: 460,
        },
      }}
    />
  );
}

function SSRApp(props) {
  // Hydrates any language stores from server so that they don't need to be async fetched by client for current page
  useSSR(
    typeof window !== 'undefined' ? window.initialI18nStore : {},
    typeof window !== 'undefined' ? window.initialLanguage : undefined,
  );
  return <App {...props} />;
}

export default SSRApp;
