import React from 'react';

import { PrimaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';

const DonatePage = () => (
  <div>
    <PrimaryHeader>Donations Offline</PrimaryHeader>
    <Paragraph>
      Do Some Good is currently building new donation integrations, we'll be back as soon as
      possible!
    </Paragraph>
    <Paragraph>
      In the mean time please check out your favourite cause's website to find out how to donate.
    </Paragraph>
  </div>
);

export default DonatePage;
