import { commafy, ensureNumeric } from '@dosomegood/platform/dist/utils/Numbers';

export function shuffleArray(array = []) {
  let newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

/**
 * Takes number-like inputs and formats them in a controlled way for display to the user.
 *
 * @param {Number | String} hours Number-like representation of hours
 * @param {Boolean | 'auto'} decimals Should we include decimals in the output? True/False or "auto"
 * @param {Boolean} groupDigits Should we format the resulting number with commas between digit groups?
 * @returns
 */
export function formatHours(hours, decimals = 'auto', groupDigits = true) {
  let outputHours;
  const fHours = ensureNumeric(hours);
  if (fHours.toString().includes('.') && (decimals === 'auto' || decimals === true)) {
    outputHours = fHours.toFixed(2);
  } else {
    outputHours = fHours.toFixed(0);
  }
  return groupDigits ? commafy(outputHours) : outputHours;
}

/**
 * Returns a boolean indicating if the code is running in a browser environment. False on server.
 * @returns boolean
 */
export function isRunningInBrowser() {
  return typeof window !== 'undefined';
}
