import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '@dosomegood/platform/dist/components/buttons/Link';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';

export default class SubMenu extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    isOpen: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleDropdown = (event) => {
    event.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClickOutside = (event) => {
    const node = this._container;
    if (!node.contains(event.target)) {
      if (this.state.isOpen) this.setState({ isOpen: false });
    }
  };

  render() {
    const items = this.props.items.map((item, idx) => {
      const isActive = !!item.route && this.context.router.isActive(item.route);
      const value = (
        <>
          <Icon name={item.icon} inverse={isActive} /> {item.title}
        </>
      );

      const action = (e) => {
        this.handleClickOutside(e);
        item.onClick && item.onClick(e);
      };

      return (
        <li
          key={idx}
          className={classNames('not-rounded', { active: isActive })}
          style={{ position: 'relative' }}
        >
          <Link
            to={item.route}
            onClick={action}
            onKeyPress={(e) =>
              e.key === ' ' || e.key === 'Space' || e.key === 'Enter' ? action(e) : false
            }
          >
            {value}
          </Link>
        </li>
      );
    });

    const dropdownMenu = (
      <div className="dropdown-menu notification-menu" id="menu-dropdown">
        <ul className="fa-ul dropdown-menu-items">{items}</ul>
      </div>
    );

    return (
      <li
        ref={(r) => (this._container = r)}
        onClick={this.toggleDropdown}
        onKeyPress={(e) =>
          e.key === ' ' || e.key === 'Space' || e.key === 'Enter' ? this.toggleDropdown(e) : false
        }
        className={classNames('btn-group clickable sub-menu', {
          open: this.state.isOpen,
          active: this.props.activeRoutes,
        })}
        role="button"
        tabIndex={0}
        aria-label={this.props.title}
        aria-controls="menu-dropdown"
        aria-expanded={this.state.isOpen}
      >
        <a href="#" tabIndex={-1}>
          <Icon name={this.props.icon} /> {this.props.title}
          <i className="fa fa-caret-down ml-md" />
        </a>
        {dropdownMenu}
      </li>
    );
  }
}
