module.exports = {
  company: {
    name: 'Do Some Good',
    shortName: 'DoSomeGood',
    phraseName: 'Doing Some Good',
    twitterName: 'DoSomeGoodCA',
    instagramName: 'DoSomeGood',
    facebookName: 'dosomegoodca',
    linkedinName: '10216075'
  },
  api: {
    host: "https://api.staging.dosomegood.ca",
    hostApp: "https://api.staging.dosomegood.ca",
    internalHost: "http://api.staging.dosomegood.ca:5000",
    internalHostApp: "http://api.staging.dosomegood.ca:5000"
  },
  auth: {
    cookies: { prefix: 's' },
    host: "https://auth.staging.dosomegood.ca",
    hostApp: "https://auth.staging.dosomegood.ca"
  },
  web: {
    host: "https://staging.dosomegood.ca",
    hostApp: "https://staging.dosomegood.ca"
  },
  realtime: {
    host: "wss://realtime.staging.dosomegood.ca"
  },
  facebook: {
    appId: 824546244300530
  },
  // Use auto to enable multi-lingual features
  locale: 'auto',
  shortEnv: 's',
  mediaUrl: 'files.dosomegood.ca',
  intercom: {
    appId: "t3xh9p7c"
  },
  sentry: {
    dsn: 'https://a60255588b2e448d9eedd03ea09b7eba@o32697.ingest.sentry.io/5371454',
    release: 'staging',
    environment: 'staging'
  },
  productIds: {
    payItForward: 'price_1HF1XvDUFlwNf9UOfCM3HzGN',
  },
  opportunity: {
    types: [
      { id: 1, name: 'Ongoing' },
      { id: 2, name: 'Virtual' },
      { id: 3, name: 'Flexible' },
      { id: 4, name: 'Event' }
    ]
  },
  stripe: {
    publishableKey:
      'pk_test_51HF1QADUFlwNf9UOCXbO8J9zHCZCfC3jkytlQsROqBKfepZ6V4rCs1mvN2kWwl9h2sdiS76JKdIml5O54Amz2xQz00vIttKTBV',
  },
};
