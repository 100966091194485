import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { api as API } from '../../mixins/api';

import Buttons from '@dosomegood/platform/dist/components/buttons';
import EmailInput from '@dosomegood/platform/dist/components/inputs/EmailInput';
import { SecondaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import { Form, FormActions, FormControl } from '@dosomegood/platform/dist/components/inputs/Form';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import { withTranslation } from 'react-i18next';

const Container = styled.div`
  padding: ${(props) => props.theme.margins.xs};
`;

const Footer = styled.footer`
  text-align: right;
  padding-top: ${(props) => props.theme.margins.xs};
  & > * {
    margin-left: ${(props) => props.theme.margins.xs};
  }
`;

class MiniModalVerifyEmail extends PureComponent {
  state = {
    error: null,
    sent: false,
    problem: false,
    isModal: this.props.options?.display === 'modal',
  };

  handleResendVerificationEmail = (newUser) => {
    const { options = {} } = this.props;
    const { onSubmit = () => true } = options;
    API.makeRequest('/user/resend-welcome', 'GET', {}, (err, res) => {
      if (res && res.body && res.body.error) {
        this.setState({ problem: true, error: res.body.error });
      } else {
        this.setState({ sent: true, problem: false });
        onSubmit(newUser);
      }
    });
  };

  handleCloseModal = () => {
    if (this.props.parentClose) this.props.parentClose();
    if (this.props.onCancel) this.props.onCancel();
  };

  handleSubmit = (newUser, _, __, handleServerResponse) => {
    const { email } = newUser;
    const { options = {} } = this.props;
    const { onSubmit = () => true } = options;
    // Email changed? Update user first
    if (email !== options.user.email) {
      API.makeRequest(
        '/user',
        'PUT',
        { email },
        handleServerResponse((res) => {
          if (res) {
            this.setState({ sent: true, problem: false });
            onSubmit(newUser);
          }
        }),
      );
    } else {
      this.handleResendVerificationEmail();
    }
  };

  handleNotify = () => {
    const { t } = this.props;
    API.makeRequest('/user/send-help/verify-email', 'POST', {}, (err, res) => {
      if (res?.body?.error) {
        alert(res.body.error);
      } else {
        alert(t('common:alerts.thanksLettingUsKnowPleaseContinue'));
        this.props.onCancel();
      }
    });
  };

  render() {
    const { isModal, sent, problem } = this.state;
    const { options = {}, t } = this.props;
    const { user = {} } = options;

    // Determine copy based on which social network, if any, is linked:
    const ssoSignup = !user.hasPassword;
    let socialNetworkName = t('common:labels.socialNetwork');
    if (user.hasFacebook && !user.hasApple && !user.hasLinkedin) {
      socialNetworkName = 'facebook';
    } else if (!user.hasFacebook && !user.hasApple && user.hasLinkedin) {
      socialNetworkName = 'linkedin';
    } else if (!user.hasFacebook && !user.hasLinkedin && user.hasApple) {
      socialNetworkName = 'apple';
    }

    const infoMessage =
      options.message ||
      t('common:labels.receivedEmailFromSocialAccountConfirm', { network: socialNetworkName });

    return (
      <Container>
        {isModal && !problem && (
          <SecondaryHeader>{t('common:labels.confirmEmailAddressTitle')}</SecondaryHeader>
        )}
        {!!(ssoSignup || options.message) && !sent && !problem && (
          <Paragraph>{infoMessage}</Paragraph>
        )}
        {sent ? (
          <>
            <Paragraph important>{t('common:alerts.checkInboxConfirmationLink')}</Paragraph>
            <Footer>
              <Buttons.Primary onClick={this.handleCloseModal}>
                {t('commonPlat:actions.okay')}
              </Buttons.Primary>
            </Footer>
          </>
        ) : problem ? (
          <>
            <SecondaryHeader>{t('common:labels.troubleReceivingConfirmLink')}</SecondaryHeader>
            <Paragraph important>{t('common:labels.troubleReceivingConfirmLinkIntro')}</Paragraph>
            <Footer>
              <Buttons.Alternate onClick={this.props.onCancel}>
                {t('common:actions.waitABitLonger')}
              </Buttons.Alternate>
              <Buttons.Primary onClick={this.handleNotify}>
                {t('common:actions.notifyOurTeam')}
              </Buttons.Primary>
            </Footer>
          </>
        ) : (
          <Form value={{ email: user.email }} onSubmit={this.handleSubmit}>
            <FormControl label={this.props.label || t('common:labels.confirmEmailAddress')}>
              <EmailInput id="email" required />
            </FormControl>
            <FormActions>
              {isModal && (
                <Buttons.Alternate onClick={this.props.onCancel}>
                  {t('commonPlat:actions.cancel')}
                </Buttons.Alternate>
              )}
              <Buttons.Primary type="submit">
                {ssoSignup
                  ? t('common:actions.yesThatCorrect')
                  : user.email
                    ? t('common:actions.resendConfirmationEmail')
                    : t('common:actions.sendConfirmationEmail')}
              </Buttons.Primary>
            </FormActions>
          </Form>
        )}
      </Container>
    );
  }
}

export default withTranslation(['common', 'commonPlat'], { withRef: true })(MiniModalVerifyEmail);
