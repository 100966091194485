import { useState, useEffect } from 'react';
import Alt from 'odgn-alt';
const alt = new Alt();
export default alt;

function useStore(store) {
  const [content, setContent] = useState(store.getState());
  useEffect(() => {
    function handleUpdate(data) {
      setContent({ ...data }); // Purposely copy content as alt stores don't actually report as a new shallow entity
    }
    store.listen(handleUpdate);
    return () => {
      store.unlisten(handleUpdate);
    };
  }, []);

  return [content];
}
export { alt, useStore };
