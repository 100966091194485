import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import LocalStorage from '../mixins/LocalStorage';

import AppActions from '../actions/app';
import AppStatusActions from '../actions/appStatus';

import ErrorBoundary from './ErrorBoundary';
import ConfirmModal from './modals/Confirm';
import {
  fetchOverviewAndResume as fetchOverviewAndResumeBusiness,
  getMasterList as getMasterListBusiness,
} from './management/onboarding/business/MasterSteps';
import {
  fetchOverviewAndResume as fetchOverviewAndResumeOrganization,
  getMasterList as getMasterListOrganization,
} from './management/onboarding/organization/MasterSteps';
import VerifyCity from './header/notices/VerifyCity';
import { SuspenseSSR } from './ui/SuspenseSSR';
import HeaderBar from './header/Header';
import MobileMenu from './header/mobile-menu/mobileMenu';
import VerifyEmail from './header/notices/VerifyEmail';
import RequireEmail from './header/notices/RequireEmail';
import { verifyAccountNeeds } from '../utils/UserChecks';

const Container = styled.div`
  padding-top: 95px;
  ${(props) => props.theme.media.phone`padding-top: 50px`};
`;

const ChildWrapper = styled.div`
  max-width: 1170px;
  margin: ${(props) => props.theme.margins.xs} auto;
`;

/**
 * Adapts the navigation structures based on having a logged in or guest user
 */
class FullExperience extends PureComponent {
  constructor(props) {
    super(props);
    const { router, user } = props;
    verifyAccountNeeds(user, router);
  }
  componentDidMount() {
    const { user, location, t } = this.props;

    if (!location.pathname.startsWith('/accept-invite/')) {
      // Onboarding incomplete
      const unfinishedBusinessRelation = user.relations?.find(
        (r) =>
          r?.relation === 'Administrator' &&
          r.target?.type === 'business' &&
          r.target.flags &&
          !r.target.flags.onboarded,
      );
      const unfinishedOrganizationRelation = user.relations?.find(
        (r) =>
          r?.relation === 'Administrator' &&
          r.target?.type === 'organization' &&
          r.target.flags &&
          !r.target.flags.onboarded,
      );

      if (unfinishedBusinessRelation) {
        // If they have branding, they will be going through the onboarding wizard and need to finish setup, otherwise they're exploring management in goal-based guidance mode
        const branding = !!unfinishedBusinessRelation.target.features?.branding;
        // Only bother them once about this
        const hasSeen = LocalStorage.getItem('business.onboard.popup');
        if (!hasSeen || hasSeen !== user.hash) {
          setTimeout(() => {
            AppActions.setModal({
              component: ConfirmModal,
              options: {
                title: branding
                  ? t('common:joyride.resumeOnboard.finishSetupBusiness')
                  : t('common:joyride.resumeOnboard.pickUpWhereLeftOff'),
                message: branding
                  ? t('common:joyride.resumeOnboard.finishSetupBusinessBrandingMessage')
                  : t('common:joyride.resumeOnboard.finishSetupBusinessFreeMessage'),
                confirmCaption: branding
                  ? t('common:actions.yesFinishSetup')
                  : t('common:actions.soundsGoodExcl'),
                cancelCaption: t('common:actions.doItLater'),
                onConfirm: () =>
                  fetchOverviewAndResumeBusiness(
                    {
                      type: 'business',
                      slug: unfinishedBusinessRelation.target.slug,
                    },
                    getMasterListBusiness,
                    t,
                  ),
                onCancel: () => {
                  AppStatusActions.addJoyrideSteps([
                    {
                      title: branding
                        ? t('common:joyride.resumeOnboard.finishAtLeisure')
                        : t('common:joyride.resumeOnboard.returnAtLeisure'),
                      content: branding
                        ? t('common:joyride.resumeOnboard.businessBrandingFindMgmt')
                        : t('common:joyride.resumeOnboard.businessFreeFindMgmt'),
                      target: '#nav-manage',
                      placement: 'bottom',
                      showSkipButton: false,
                      disableBeacon: true,
                    },
                  ]);
                },
              },
            });
          }, 100);
          LocalStorage.setItem('business.onboard.popup', user.hash);
        }
      } else if (unfinishedOrganizationRelation) {
        // Only bother them once about this
        const hasSeen = LocalStorage.getItem('organization.onboard.popup');
        if (!hasSeen || hasSeen !== user.hash) {
          setTimeout(() => {
            AppActions.setModal({
              component: ConfirmModal,
              options: {
                title: t('common:joyride.resumeOnboard.finishSetupOrg'),
                message: t('common:joyride.resumeOnboard.finishSetupOrgMessage'),
                confirmCaption: t('common:actions.yesFinishSetup'),
                cancelCaption: t('common:actions.doItLater'),
                onConfirm: () =>
                  fetchOverviewAndResumeOrganization(
                    {
                      type: 'organization',
                      slug: unfinishedOrganizationRelation.target.slug,
                    },
                    getMasterListOrganization,
                    t,
                  ),
                onCancel: () => {
                  AppStatusActions.addJoyrideSteps([
                    {
                      title: t('common:joyride.resumeOnboard.finishAtLeisure'),
                      content: t('common:joyride.resumeOnboard.orgFindMgmt'),
                      target: '#nav-manage',
                      placement: 'bottom',
                      showSkipButton: false,
                      disableBeacon: true,
                    },
                  ]);
                },
              },
            });
          }, 100);
          LocalStorage.setItem('organization.onboard.popup', user.hash);
        }
      }
    }
  }
  render() {
    const { children, routes, router, location, params, user, usingProxyUser, ...props } =
      this.props;

    if (verifyAccountNeeds(user, router, false)) {
      const verifyEmailBanner = !user.email ? (
        <RequireEmail user={user} />
      ) : (
        !user.verifiedEmail && <VerifyEmail user={user} />
      );

      const verifyCityBanner = !user.verifiedCity && <VerifyCity user={user} />;

      const lowestRoute = routes[routes.length - 1];
      if (lowestRoute.printMode) {
        return <ErrorBoundary>{children}</ErrorBoundary>;
      }

      return (
        <Container>
          <MobileMenu user={user} />
          <HeaderBar
            key="full-header"
            routes={routes}
            location={location}
            params={params}
            user={user}
            usingProxyUser={usingProxyUser}
          />
          {verifyEmailBanner || verifyCityBanner}
          <ChildWrapper>
            <ErrorBoundary>
              <SuspenseSSR>
                {React.cloneElement(children, { user, location, routes, router, params, ...props })}
              </SuspenseSSR>
            </ErrorBoundary>
          </ChildWrapper>
          <audio id="notificationSound" preload="auto">
            <source src="/assets/media/notification.mp3" type="audio/mp3" />
            <source src="/assets/media/notification.ogg" type="audio/ogg" />
          </audio>
        </Container>
      );
    }

    return null;
  }
}

export default withTranslation(['commonPlat', 'common'])(FullExperience);
