import React, { useEffect, useState } from 'react';
import { api as API } from '../../mixins/api';
import { PrimaryHeader } from '@dosomegood/platform/dist/components/typography/Headers';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import { useTranslation } from 'react-i18next';
import { usePageTitle } from '../../utils/Hooks';

export default function Unsubscribe({ params }) {
  const { t } = useTranslation(['common']);
  usePageTitle(t('common:nav.unsubscribe'));
  const [unsubscribed, setUnsubbed] = useState(false);

  useEffect(() => {
    API.makeRequest(`/unsubscribe/${params.token}`, 'POST', {}, () => {
      setUnsubbed(true);
    });
  }, [params.token]);

  return (
    <section>
      {unsubscribed ? (
        <>
          <PrimaryHeader>{t('common:labels.unsubscribed')}</PrimaryHeader>
          <Paragraph>{t('common:labels.unsubscribedIntro')}</Paragraph>
        </>
      ) : (
        <PrimaryHeader>{t('common:labels.pleaseWait')}</PrimaryHeader>
      )}
    </section>
  );
}
