import React from 'react';
import styled from 'styled-components';

import AppActions from '../../../actions/app';

import MiniModalVerifyEmail from '../../minimodals/MiniModalVerifyEmail';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';
import Buttons from '@dosomegood/platform/dist/components/buttons';
import { Trans, useTranslation } from 'react-i18next';

const Container = styled.div`
  padding: ${(props) => props.theme.margins.xs};
  text-align: center;
  background-color: ${(props) => props.theme.brandColors.statusColors.warning};

  & p {
    margin: 0 !important;
  }

  & button {
    padding: 6.6px !important;
  }
`;

const handleVerifyEmail = (user, t) => {
  AppActions.setModal({
    component: MiniModalVerifyEmail,
    options: {
      user,
      display: 'modal',
      message: t('common:alerts.confirmEmailModalIntro'),
    },
    onClose: () => {
      AppActions.setModal(false);
    },
  });
};

function VerifyEmail({ user }) {
  const { t } = useTranslation(['common']);

  return (
    <Container>
      <Paragraph inverse>
        <Trans t={t} i18nKey="common:alerts.headerConfirmEmail">
          <strong>Heads up!</strong> You won't be able to enjoy the full Do Some Good experience
          until you{' '}
          <Buttons.Secondary inverse onClick={() => handleVerifyEmail(user, t)}>
            confirm your email
          </Buttons.Secondary>
        </Trans>
      </Paragraph>
    </Container>
  );
}

export default VerifyEmail;
