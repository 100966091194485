import React from 'react';
import styled from 'styled-components';

import HeaderBar from './header/Header';
import GuestCallToAction from './header/GuestCallToAction';
import ErrorBoundary from './ErrorBoundary';
import { SuspenseSSR } from './ui/SuspenseSSR';

const Container = styled.div`
  padding-top: 50px;
`;

const ChildWrapper = styled.div`
  max-width: 1170px;
  margin: ${(props) => props.theme.margins.xs} auto;
`;

/**
 * Adapts the navigation structures based on having a logged in or guest user
 */
function GuestExperience({ children, routes, location, params, ...props }) {
  const lowestRoute = routes[routes.length - 1];
  if (lowestRoute.printMode) {
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }

  return (
    <Container>
      <HeaderBar key="guest-header" routes={routes} location={location} params={params} guest />
      <GuestCallToAction location={location} />
      <ChildWrapper>
        <ErrorBoundary>
          <SuspenseSSR>
            {React.cloneElement(children, { location, routes, params, ...props })}
          </SuspenseSSR>
        </ErrorBoundary>
      </ChildWrapper>
    </Container>
  );
}

export default GuestExperience;
