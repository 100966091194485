import BusinessDashboardActions from 'actions/businessDashboard';
import { api as API } from 'mixins/api';
import { browserHistory } from 'react-router';
//import { getListingStatus } from 'utils/UserChecks';

export function getMasterList(context, t) {
  const features = context.package?.features || {};
  const { onboardingGoal, usage: { numForms, numStories, numOrganizationRel } = {} } = context;

  const m = context.manualStats;
  const hasManualStats =
    !!m &&
    ((m.donations !== 0 && m.donations !== '' && m.donations !== undefined) ||
      (m.hours !== 0 && m.hours !== '' && m.hours !== undefined));

  const hasAbout = !!context.about && context.about.length > 0;
  const hasCauses = !!context.causes && context.causes.length > 0;
  const hasLogo = !!context.logo;
  const hasPledge = !!context.agreedPledge;

  const hasConnection =
    numOrganizationRel > 0 || context.managementStats?.relationshipsInOrganizations > 0;

  //const hasTestimonial = context.managementStats.businessTestimonials > 0;
  const hasStory = numStories > 0;

  const flags = context.flags || {};

  const lite = features.lite;
  const freemium = features.freemium || !features.branding;

  //const { isListed } = getListingStatus(false, context, features, t);

  const goalMetImpact = !!numOrganizationRel || flags.sConnection;
  const goalMetBrand = !!numStories;
  const goalMetForms = !!numForms || flags.sFormCreate;
  const primaryGoalMetImpact = onboardingGoal === 'impact' && goalMetImpact;
  const primaryGoalMetBrand = onboardingGoal === 'brand' && goalMetBrand;
  const primaryGoalMetForms = onboardingGoal === 'forms' && goalMetForms;

  const metPrimaryGoal = primaryGoalMetImpact || primaryGoalMetBrand || primaryGoalMetForms;
  const metSecondaryGoals = goalMetBrand; // OLD: goalMetImpact && goalMetBrand && goalMetForms && isListed;

  const goals = {
    onboardingGoal,
    goalMetForms,
    goalMetImpact,
    goalMetBrand,
    primaryGoalMetImpact,
    primaryGoalMetBrand,
    primaryGoalMetForms,
    metPrimaryGoal,
    metSecondaryGoals,
    freemium,
    onboarded: flags.onboarded,
  };

  const brandSteps = [
    {
      id: 'profile',
      caption: freemium ? t('bMgmt:labels.onboardBoostBrand') : t('bMgmt:labels.onboardProfile'),
      done:
        hasAbout &&
        hasCauses &&
        hasLogo &&
        (hasConnection || flags.sConnection || lite) &&
        (hasStory || flags.sStory || lite) &&
        (hasPledge || flags.sPledge),
      subSteps: [
        {
          id: 'pledge',
          caption: t('bMgmt:labels.pledge'),
          path: `/welcome/profile/pledge`,
          done: hasPledge || flags.sPledge,
        },
        /* Business who go through a payment & membership selection flow don't upload a logo immediately, let's catch them during onboarding */
        !!context.subscriptions?.length && {
          id: 'logo',
          caption: t('bMgmt:labels.onboardLogoDetails'),
          path: `/welcome/profile/logo`,
          done: hasLogo,
        },
        /* Freemium users get a combined about us */
        !!freemium && {
          id: 'aboutAll',
          caption: t('bMgmt:labels.aboutUs'),
          path: `/welcome/profile/about-all`,
          done: hasCauses && hasAbout,
        },
        /* Paid get a different experience */
        !freemium && {
          id: 'causes',
          caption: t('bMgmt:labels.causesAbout'),
          path: `/welcome/profile/causes`,
          done: hasCauses && hasAbout,
        },
        !lite && {
          id: 'addConnection',
          caption: t('bMgmt:labels.connections'),
          path: `/welcome/profile/connection`,
          done: hasConnection || flags.sConnection,
        },
        !lite && {
          id: 'postStory',
          caption: t('bMgmt:labels.firstStory'),
          path: `/welcome/profile/story`,
          done: hasStory || flags.sStory,
        },
      ].filter((s) => !!s),
    },
  ].filter((s) => !!s);

  const givingSteps = [
    {
      id: 'business-type',
      caption: t('bMgmt:labels.chooseBusinessType'),
      image: `2_connect_cause.jpg`,
      description: t('bMgmt:labels.businessTypeIntro'),
      done: context.subType,
      subSteps: [
        {
          id: 'chooseType',
          caption: t('bMgmt:labels.chooseBusinessType'),
          path: `/welcome/business-type/choose`,
          done: context.subType,
        },
      ],
    },
    {
      id: 'email-signatures',
      caption: t('bMgmt:onboarding.addProfileToSignaturesTitle'),
      image: `invite_business.jpg`,
      description: t('sMgmt:labels.linkProfileFromSigsDescription'),
      done: flags.dSig || flags.sSig,
      subSteps: [
        {
          id: 'addEmailSignatures',
          caption: t('bMgmt:onboarding.addEmailSignatureTitle'),
          path: `/welcome/email-signatures/add`,
          done: flags.dSig || flags.sSig,
        },
      ],
    },
    {
      id: 'career-postings',
      caption: t('bMgmt:labels.linkProfileFromCareerPostings'),
      image: `4_keep_in_touch.jpg`,
      description: t('bMgmt:labels.linkProfileFromCareerPostingsIntro'),
      done: flags.dCPostings || flags.sCPostings,
      subSteps: [
        {
          id: 'careerPostings',
          caption: t('bMgmt:labels.linkProfileFromCareerPostings'),
          path: `/welcome/career-postings/add`,
          done: flags.dCPostings || flags.sCPostings,
        },
      ],
    },
    {
      id: 'share',
      caption: t('bMgmt:labels.shareCompanyProfile'),
      image: `10_post_story_about_org.jpg`,
      description: t('bMgmt:labels.shareCompanyProfileIntro'),
      done: flags.dSProfile || flags.sSProfile,
      subSteps: [
        {
          id: 'shareProfile',
          caption: t('bMgmt:labels.shareCompanyProfile'),
          path: `/welcome/share/profile`,
          done: flags.dSProfile || flags.sSProfile,
        },
      ],
    },
    {
      id: 'collective',
      caption: t('bMgmt:labels.addCollectiveImpact'),
      image: '6_invite.jpg',
      description: t('bMgmt:labels.addCollectiveImpactIntro'),
      done: hasManualStats,
      subSteps: [
        {
          id: 'contributions',
          caption: t('bMgmt:labels.addCollectiveImpact'),
          path: `/welcome/collective/contributions`,
          done: hasManualStats,
        },
      ],
    },
  ];

  return [brandSteps, givingSteps, goals];
}

export function getSubSteps(masterId, context, t) {
  const ms = getMasterList(context, t).find((s) => s.find((ss) => ss.id === masterId));
  const s = ms && ms.find((ss) => ss.id === masterId);
  return s && s.subSteps.filter((ss) => !!ss);
}

export function getSteps(masterId, context, t) {
  const ms = getMasterList(context, t).find((s) => s.find((ss) => ss.id === masterId));
  const s = ms && ms.find((ss) => ss.id === masterId);
  return s;
}

export function fetchOverviewAndResume(context, getMasterList, t) {
  API.makeRequest(`/${context.type}/${context.slug}/management-overview`, 'GET', {}, (err, res) => {
    API.makeRequest(
      `/${context.type}/${context.slug}/management-stats`,
      'GET',
      {},
      (err, stats) => {
        if (!err && res?.body) {
          resumeOnboarding({ ...res.body, managementStats: stats?.body || {} }, getMasterList, t);
        }
      },
    );
  });
}

/**
 * Given the current state of onboarding, determines if and where a user should navigate to and proceeds there automatically
 *
 * @param {*} context
 * @returns
 */
export function resumeOnboarding(context, getMasterList, t) {
  const { flags = {} } = context || {};

  // Stop if we've already onboarded
  if (flags?.onboarded) return false;

  const [primarySteps, __, { onboardingGoal, metPrimaryGoal, metSecondaryGoals }] = getMasterList(
    context,
    t,
  );

  // No goal set, use traditional onboarding funnel with steps
  if (!onboardingGoal) {
    const nextStep = primarySteps.find((s) => !s.done);
    if (nextStep) {
      const nextPath = nextStep.subSteps.find((s) => !s.done)?.path;
      if (nextPath) {
        return browserHistory.push({
          pathname: `/${context.type}/${context.slug}/manage${nextPath}`,
          query: { returning: true },
        });
      } else {
        console.error('No unfinished steps found for wizard-based onboarding');
        markOnboardingComplete(context, () =>
          alert(t('sMgmt:alerts.ourMistakeOnboardingComplete')),
        );
      }
    } else {
      console.error('No unfinished steps found for wizard-based onboarding');
      markOnboardingComplete(context, () => alert(t('sMgmt:alerts.ourMistakeOnboardingComplete')));
    }
  } else {
    // If we haven't met our primary goal, let's find where we're supposed to go based on the selected goal
    if (!metPrimaryGoal) {
      switch (onboardingGoal) {
        case 'impact':
          browserHistory.push({
            pathname: `/${context.type}/${context.slug}/manage/relationships/organizations/list`,
            query: { welcome: true },
          });
          break;
        case 'forms':
          browserHistory.push({
            pathname: `/${context.type}/${context.slug}/manage/forms/list`,
            query: { welcome: true },
          });
          break;
        case 'brand':
          browserHistory.push({
            pathname: `/${context.type}/${context.slug}/manage/social/feed`,
            query: { welcome: true },
          });
          break;
        case 'notsure':
        default:
          browserHistory.push({
            pathname: `/${context.type}/${context.slug}/manage/home/dashboard`,
            query: { welcome: true },
          });
      }
      return;
    }
    if (!metSecondaryGoals) {
      browserHistory.push({
        pathname: `/${context.type}/${context.slug}/manage/social/feed`,
        query: { welcome: true },
      });
      return;
    }
  }

  // If all else fails and we don't have a specific place in mind, take them to the home page that can guide them further
  browserHistory.push(`/${context.type}/${context.slug}/manage/home/dashboard`);
}

export function fetchCheckAndUpdateOnboarding(context, callback = () => {}, getMasterList, t) {
  const flags = context.flags || {};
  // If we've already been marked as onboarded, we can just skip ahead
  if (flags.onboarded) return callback(context, false);

  const [_, __, { onboardingGoal, metPrimaryGoal, metSecondaryGoals }] = getMasterList(context, t);

  // If we met our goals with the information provided, then we can just continue as normal
  if (onboardingGoal && metPrimaryGoal && metSecondaryGoals) {
    console.info('Passed pre-check, no need to fetch overview');
    return checkAndUpdateOnboarding(context, callback, getMasterList, t);
  } else if (onboardingGoal && !(metPrimaryGoal && metSecondaryGoals)) {
    console.info('Pre-fetching new overview to check for onboarding criteria');
    // If we have a goal, and didn't fully meet them - let's refetch the management overview to check on any recent changes and look again
    API.makeRequest(
      `/${context.type}/${context.slug}/management-overview`,
      'GET',
      {},
      (err, res) => {
        if (!err && res && res.body) {
          BusinessDashboardActions.updateManagementOverview(res.body);
          checkAndUpdateOnboarding(res.body, callback, getMasterList, t);
        }
      },
    );
    return;
  }
  console.info(
    "No onboarding goal, we'll just check without fetching (This probably shouldn't happen)",
  );
  return checkAndUpdateOnboarding(context, callback, getMasterList, t);
}

/**
 * Checks the onboarding progress of a business and marks onboarding as finished or not based on the criteria
 *
 * @param {*} context
 * @param {*} callback
 */
export function checkAndUpdateOnboarding(context, callback = () => {}, getMasterList, t) {
  const flags = context.flags || {};
  // If we've already been marked as onboarded, we can just skip ahead
  if (flags.onboarded) return callback(context, false);

  const [_, __, { onboardingGoal, metPrimaryGoal, metSecondaryGoals }] = getMasterList(context, t);

  // If we've met all our goals then we can mark as onboarded and continue
  if (onboardingGoal && metPrimaryGoal && metSecondaryGoals) {
    markOnboardingComplete(context, callback);
    return;
  }

  callback(context, false);
}

export function markOnboardingComplete(context, callback = () => {}) {
  const flags = context.flags || {};
  API.makeRequest(
    `/${context.type}/${context.slug}`,
    'PUT',
    { flags: { ...flags, onboarded: true } },
    (_, __) => {
      callback({ ...context, flags: { ...flags, onboarded: true } }, true);
    },
  );
}
