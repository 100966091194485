import config from '../config';
import cookie from 'cookie';
import request from 'superagent';
import CookieDough from 'cookie-dough';

import AppActions from '../app/actions/app';

import { getAuthHost } from '../app/utils/MultiDomain';

function authViaEmail(email, pass, req, cb) {
  const authReq = request
    .post(getAuthHost(req) + '/auth/email')
    .withCredentials()
    .send({
      username: email,
      password: pass,
    });

  if (req) {
    const ips = req.ips.length ? req.ips : [req.ip];
    authReq.set('X-Forwarded-For', ips.join(', '));
  }

  authReq.then(cb).catch((err) => cb(false, err));
}

function authStaffAsUser(emailOrHash, req, cb) {
  request
    .post(getAuthHost(req) + '/auth/staff-as-user')
    .withCredentials()
    .send({
      username: emailOrHash,
    })
    .then(cb)
    .catch((err) => cb(false, err));
}

function logoutViaServer(req, cb) {
  request
    .post(getAuthHost(req) + '/auth/logout')
    .withCredentials()
    .then(cb)
    .catch((err) => cb(false, err));
}

function repairViaServer(req, cb) {
  request
    .post(getAuthHost(req) + '/auth/repair-cookies')
    .withCredentials()
    .then(cb)
    .catch((err) => cb(false, err));
}

function logoutProxyViaServer(req, cb) {
  request
    .post(getAuthHost(req) + '/auth/logout/proxy')
    .withCredentials()
    .then(cb)
    .catch((err) => cb(false, err));
}

const configAuth = (req, res) => {
  return {
    login: function (email, pass, cb) {
      const cookie = new CookieDough(req ? req : undefined);
      authViaEmail(
        email,
        pass,
        req,
        function (res, _err) {
          if (res && res.ok && res.body.access_token) {
            //AppActions.login();
            if (cb) cb(true, res.body.access_token);
          } else {
            cookie.remove(config.auth.cookies.prefix + 'at');
            cookie.remove(config.auth.cookies.prefix + 'rt');
            cookie.remove(config.auth.cookies.prefix + 'pa');
            cookie.remove(config.auth.cookies.prefix + 'pr');
            cookie.remove(config.auth.cookies.prefix + 'ex');
            cookie.remove(config.auth.cookies.prefix + 'px');
            AppActions.logout();
            if (cb) cb(false, null);
          }
        }.bind(this),
      );
    },

    loginProxy: function (emailOrHash, cb) {
      let cookie = new CookieDough(req ? req : undefined);
      //console.log('loginProxy in auth', emailOrHash);
      authStaffAsUser(
        emailOrHash,
        req,
        function (res, err) {
          //console.log('loginProxy after auth', res.ok, res.body?.access_token, 'err', err);
          if (res && res.ok && res.body.access_token) {
            AppActions.loginProxy(res.body.access_token);
            if (cb) cb(true, res.body.access_token);
          } else {
            cookie.remove(config.auth.cookies.prefix + 'pa');
            cookie.remove(config.auth.cookies.prefix + 'pr');
            cookie.remove(config.auth.cookies.prefix + 'px');
            AppActions.logoutOfProxy();
            if (cb) cb(false, null);
          }
        }.bind(this),
      );
    },

    logout: function (cb) {
      if (req) {
        req.lastToken = undefined;
        req.expires = undefined;
      }
      // Attempt to remove locally (doesn't work cross-domain, but works in dev)
      let cookie = new CookieDough(req ? req : undefined);
      cookie.remove(config.auth.cookies.prefix + 'at');
      cookie.remove(config.auth.cookies.prefix + 'rt');
      cookie.remove(config.auth.cookies.prefix + 'ex');
      cookie.remove(config.auth.cookies.prefix + 'pa');
      cookie.remove(config.auth.cookies.prefix + 'pr');
      cookie.remove(config.auth.cookies.prefix + 'px');

      logoutViaServer(req, function (res) {
        AppActions.logout();
        if (cb) cb();
      });
    },

    repair: function () {
      return new Promise((resolve) => {
        repairViaServer(req, function (res) {
          resolve(res);
        });
      });
    },

    logoutOfProxy: function (cb) {
      if (req) {
        req.lastProxyToken = undefined;
        req.proxyExpires = undefined;
      }
      // Attempt to remove locally (doesn't work cross-domain, but works in dev)
      let cookie = new CookieDough(req ? req : undefined);
      cookie.remove(config.auth.cookies.prefix + 'pa');
      cookie.remove(config.auth.cookies.prefix + 'pr');
      cookie.remove(config.auth.cookies.prefix + 'px');

      logoutProxyViaServer(req, function (res) {
        // AppActions.logout();
        if (cb) cb();
      });
    },

    expires: function () {
      if (req && req.expires) {
        return new Date(req.expires * 1000);
      } else if (!req && global.expires) {
        return new Date(global.expires * 1000);
      }
      let cookie = new CookieDough(req ? req : undefined);
      try {
        const exp = cookie.get(config.auth.cookies.prefix + 'ex');
        if (!exp || exp.length === 0) return false;
        return new Date(parseInt(exp) * 1000);
      } catch (err) {
        return false;
      }
    },

    proxyExpires: function () {
      if (req && req.proxyExpires) {
        return new Date(req.proxyExpires * 1000);
      } else if (!req && global.proxyExpires) {
        console.info('Global Proxy Expiry Set (No Req), Skipping Cookie', global.proxyExpires);
        return new Date(global.proxyExpires * 1000);
      }
      let cookie = new CookieDough(req ? req : undefined);
      try {
        const exp = cookie.get(config.auth.cookies.prefix + 'px');
        //console.info('Proxy Cookie Refresh, Exp from Cookie:', exp);
        if (exp.length === 0) return false;
        return new Date(parseInt(exp) * 1000);
      } catch (err) {
        console.error('Proxy Cookie Refresh', err);
        return false;
      }
    },

    token: function () {
      if (req && req.lastToken) {
        return req.lastToken;
      } else if (!req && global.lastToken) {
        return global.lastToken;
      }
      let cookie = new CookieDough(req ? req : undefined);
      try {
        let at = cookie.get(config.auth.cookies.prefix + 'at');
        if (at.length === 0) at = false;
        return at;
      } catch (err) {
        return false;
      }
    },

    proxyToken: function () {
      if (req && req.lastProxyToken) {
        return req.lastProxyToken;
      } else if (!req && global.lastProxyToken) {
        return global.lastProxyToken;
      }
      let cookie = new CookieDough(req ? req : undefined);
      try {
        // console.log('Token request:', cookie.get('at'));
        let at = cookie.get(config.auth.cookies.prefix + 'pa');
        if (at.length === 0) at = false;
        return at;
      } catch (err) {
        return false;
      }
    },

    setTokenAndExpiry: function (token, expires_in = 3600, localPatch = false) {
      if (req) {
        req.lastToken = token;
        req.expires = Math.floor(Date.now() / 1000) + expires_in;
      } else {
        if (localPatch) {
          global.lastToken = token;
          global.expires = Math.floor(Date.now() / 1000) + expires_in;
        }
      }
    },

    setProxyTokenAndExpiry: function (token, expires_in = 3600, localPatch = false) {
      if (req) {
        req.lastProxyToken = token;
        req.proxyExpires = Math.floor(Date.now() / 1000) + expires_in;
      } else {
        if (localPatch) {
          global.lastProxyToken = token;
          global.proxyExpires = Math.floor(Date.now() / 1000) + expires_in;
        }
      }
    },

    credentials: function () {
      if (!req || !req.headers) return false;
      return req.headers.cookie;
    },

    setCredentials: function (cookies) {
      const today = new Date();

      // Default to 7 days for standard refresh period, this is only used as a fallback if the expires property is missing
      const cookieExpiry = new Date(today.getTime() + 7 * 1000 * 24 * 60 * 60);

      if (req && typeof window !== 'object') {
        (Array.isArray(cookies) ? cookies : [cookies]).forEach((c) => {
          try {
            const processedCookie = cookie.parse(c);
            const options = {
              expires: processedCookie.expires ? new Date(processedCookie.expires) : cookieExpiry,
              domain: processedCookie.domain,
              sameSite: processedCookie.samesite,
              httpOnly: c.includes('httponly'),
              secure: c.includes('secure'),
              path: processedCookie.path,
            };
            const cookieKey = Object.keys(processedCookie)[0];
            const cookieValue = Object.values(processedCookie)[0];

            // Check against permitted cookie names to ensure we're finding something valid,
            // throw an error but still proceed until we're confident in this catching only error cases
            const { prefix = '' } = config.auth.cookies;
            if (
              ![
                prefix + 'at',
                prefix + 'rt',
                prefix + 'ex',
                prefix + 'pa',
                prefix + 'pr',
                prefix + 'px',
                'locale',
              ].includes(cookieKey)
            ) {
              console.error('SSR Cookie Forward invalid key:', cookieKey);
            }

            res.append('Set-Cookie', cookie.serialize(cookieKey, cookieValue, options));
          } catch (err) {
            console.error('Cookie Parse and Refresh Error', err, 'Cookie Data:', c);
          }
        });
      }
    },
  };
};

export default configAuth;
