import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import connectToStores from 'alt-utils/lib/connectToStores';
import { withTranslation } from 'react-i18next';

import { api as API } from '../../../mixins/api';
import { analytics as Analytics } from '../../../mixins/analytics';

import MessageStore from '../../../stores/messages';
import MessageActions from '../../../actions/messages';

import Link from '@dosomegood/platform/dist/components/buttons/Link';
import UnreadIndicator from '@dosomegood/platform/dist/components/ui/UnreadIndicator';
import Icon from '@dosomegood/platform/dist/components/ui/Icon';
import HeaderIcon from './HeaderIcon';
import { CollectionItemDetail } from '@dosomegood/platform/dist/components/typography/Content';
import { friendlyPastDate } from '@dosomegood/platform/dist/utils/Chrono';
import { resolveFullLocale } from '../../../utils/Lang';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => props.theme.margins.xxs};
  border-bottom: 1px solid ${(props) => props.theme.brandColors.lighterBackground};

  ${(props) =>
    props.$unread &&
    css`
      background: #ffffe0;
    `};
`;

const CircleIcon = styled.div`
  background: ${(props) => props.theme.brandColors.statusColors[props.$bg]};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: ${(props) => props.theme.margins.xs};
  & i {
    color: white !important;
  }
`;

@connectToStores
class NotificationsDropdown extends PureComponent {
  state = {
    // Sharing the message store
    isOpen: false,
    loading: false,
    hasOpened: false,
  };

  static getStores() {
    return [MessageStore];
  }

  static getPropsFromStores() {
    return { notices: MessageStore.getState() };
  }

  componentDidMount() {
    this._mounted = true;
    this.fetchNotices();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    this._mounted = false;
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  fetchNotices = (active = false) => {
    const { i18n, notices } = this.props;
    this.setState({ loading: !notices?.length });
    const locale = resolveFullLocale(i18n);
    API.makeRequest(`/user/notifications?locale=${locale}`, 'GET', {}, (err, res) => {
      if (!this._mounted) return;

      const content = !err && res?.body && Array.isArray(res.body) ? res.body : [];
      MessageActions.updateNotices(content || []);

      this.setState({ loading: false });

      // Only mark as read if there are unread messages
      if (active && content && content.filter((n) => !n.seen).length) {
        API.makeRequest('/user/notifications/read', 'PUT', {}, (err, res) => false);
      }
    });
  };

  handleToggleDropdown = (event) => {
    if (event) event.preventDefault();
    const nextState = !this.state.isOpen;

    this.setState({ isOpen: nextState });
    if (nextState) {
      this.fetchNotices(true);

      Analytics.trackEvent('notifications', {
        action: 'opened-dropdown',
      });
    } else {
      MessageActions.resetUnreadNotice();
      this.setState({
        hasOpened: true,
      });
    }
  };

  handleClickOutside = (event) => {
    const node = this._container;
    if (!node.contains(event.target)) {
      if (this.state.isOpen) {
        MessageActions.resetUnreadNotice();
        this.setState({ hasOpened: true, isOpen: false });
      }
    }
  };

  getIcon(notificationType) {
    let icon = 'info',
      background = 'notice';

    switch (notificationType) {
      case 'Information':
        icon = 'info';
        break;
      case 'Communication':
        icon = 'comment';
        background = 'success';
        break;
      case 'Admin':
        icon = 'briefcase';
        background = 'error';
        break;
      case 'Scheduling':
        icon = 'calendar';
        background = 'warning';
        break;
      case 'Other':
        break;
    }

    return (
      <CircleIcon $bg={background}>
        <Icon name={icon} />
      </CircleIcon>
    );
  }

  render() {
    const { t, i18n } = this.props;
    const { notices = [], unreadNoticeCount = 0 } = this.props.notices || {};

    const noticesList = (notices || []).slice(0, 25);

    const noticeRows = noticesList.map((notice, idx) => {
      const iconBadge = this.getIcon(notice.type);

      const subject = notice.localizedSubject || notice.subject;
      const body = notice.localizedBody || notice.body;

      const content = (
        <Container $unread={!notice.seen}>
          {iconBadge}
          <div style={{ flex: 1 }}>
            <CollectionItemDetail style={{ fontWeight: '600' }}>{subject}</CollectionItemDetail>
            {!!body && (
              <div>
                <CollectionItemDetail>{body}</CollectionItemDetail>
              </div>
            )}
            <div>{friendlyPastDate(notice.created, t, i18n)}</div>
          </div>
        </Container>
      );

      return (
        <li key={idx + notice.created}>
          {notice.targetUrl ? (
            <Link to={notice.targetUrl || '/'} onClick={this.handleClickOutside}>
              {content}
            </Link>
          ) : (
            content
          )}
        </li>
      );
    });

    const dropdownMenu = (
      <ul className="dropdown-menu notification-menu large">
        <div className="notification-heading">
          <span className="pull-right label label-default" aria-hidden>
            {noticesList.length}
          </span>
          {t('common:labels.recentNotifications')}
        </div>
        <ul className="inner-scrollable">
          {this.state.loading ? (
            <p className="mt-sm text-center">{t('common:labels.loading')}</p>
          ) : noticeRows.length ? (
            noticeRows
          ) : (
            <p className="mt-sm text-center">{t('common:labels.noNewNotifications')}</p>
          )}
        </ul>
      </ul>
    );

    return (
      <div
        ref={(r) => (this._container = r)}
        style={{ position: 'relative' }}
        className={classNames({ open: this.state.isOpen })}
      >
        <HeaderIcon
          onClick={this.handleToggleDropdown}
          onKeyPress={(e) =>
            e.key === ' ' || e.key === 'Space' || e.key === 'Enter'
              ? this.handleToggleDropdown(e)
              : false
          }
          aria-label={`Notifications, ${unreadNoticeCount} unread`}
          aria-expanded={this.state.isOpen}
          tabIndex={0}
        >
          <Icon name={this.state.isOpen ? 'bell' : 'bell-o'} />
          <UnreadIndicator>{unreadNoticeCount}</UnreadIndicator>
        </HeaderIcon>
        {dropdownMenu}
      </div>
    );
  }
}

export default withTranslation(['common'])(NotificationsDropdown);
