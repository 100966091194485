import React, { PureComponent } from 'react';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';

import InviteActions from '../actions/invite';

import urls from '@dosomegood/platform/dist/config/theme/urls';
import { Paragraph } from '@dosomegood/platform/dist/components/typography/Content';

import configAuth from '../../shared/auth';
const auth = configAuth();

class Logout extends PureComponent {
  componentDidMount() {
    const { state = {}, query = {} } = this.props.location;
    auth.logout(() => {
      InviteActions.reset();
      browserHistory.replace({
        pathname: urls.routes.loginUser,
        query: { ...query, justLoggedOut: true },
        state,
      });
    });
  }
  render() {
    const { t } = this.props;
    return <Paragraph>{t('common:labels.loggedOutRedirect')}</Paragraph>;
  }
}

export default withTranslation(['common'])(Logout);
